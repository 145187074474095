import {
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Form,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Layout.module.scss";
import cart from "./../../assets/Home/cart.svg";
import cartBlack from "./../../assets/Home/cartBlack.svg";
import userGray from "./../../assets/User-gray.svg";
import user from "./../../assets/User-white.svg";
import search from "./../../assets/Search.svg";
import logo from "./../../logo.png";
import LiveSearch from "../Search/LiveSearch";
import { useEffect, useState } from "react";
import { AuthServices } from "../../http/AuthHttp";

export default function Header(props) {
  const { color } = props;
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  const modalShow = () => {
    setShowModal(true);
  };
  const handleLogout = async (e) => {
    // setIsLoading(true);
    e.preventDefault();
    localStorage.removeItem("riyuz-auth");
    window.location.reload();
    navigate("/");
    // setIsLoading(false);

    // try {
    //   const response = await AuthServices.logout();
    //   if (response) {
    //   }
    // } catch (err) {
    //   console.log(err);
    //   setIsLoading(false);
    // }
  };
  return (
    <>
      <Container className={styles.headerWrap}>
        <Row>
          <Col lg={12}>
            <Navbar expand="lg">
              <Container>
                <Navbar.Brand href="/">
                  <div className={styles.logo}>
                    <Image src={logo} alt="" />
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className={`me-auto ${styles.headerMenu} ${
                      color === "dark" && styles.headerMenu__dark
                    }`}
                  >
                    <Nav.Link className="me-2" href="/">
                      Home
                    </Nav.Link>
                    <Nav.Link className="me-2" href="/search">
                      Catalog
                    </Nav.Link>
                    <Nav.Link className="me-2" href="/custom-package">
                      Custom
                    </Nav.Link>
                  </Nav>
                  <Form inline>
                    <div
                      className={`${styles.headerSearch} me-3 ${
                        color === "dark" && styles.headerSearch__dark
                      }`}
                    >
                      <Image src={search} alt="search" />
                      <input
                        className="ps-5 pe-2"
                        placeholder="Cari Paket"
                        onClick={modalShow}
                      />
                    </div>
                  </Form>
                  <div className="d-flex align-items-center">
                    {isLogin && (
                      <Link to="/cart" className="me-3">
                        <Image
                          src={cartBlack}
                          // width={44}
                          // height={44}
                          alt="cart"
                        />
                      </Link>
                    )}
                    {isLogin ? (
                      <div className={styles.wrapProfile}>
                        <Image src={userGray} alt="cart" />
                        <div className={styles.hoverableMenu}>
                          <div>
                            <Link
                              to="/profile"
                              // className={styles.headerUser}
                            >
                              Profil
                            </Link>
                            <Link
                              onClick={handleLogout}
                              as="div"
                              // className={styles.headerUser}
                            >
                              Logout
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to="/login"
                        // className={styles.headerUser}
                      >
                        <Button
                          className={`${styles.btnLogin} ${styles.btnLogin__dark}`}
                        >
                          Login
                        </Button>
                      </Link>
                    )}
                  </div>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row>
        <LiveSearch show={showModal} setHide={() => setShowModal(false)} />
      </Container>
    </>
  );
}
