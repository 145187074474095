import { riyuzAPI } from "../constant/api";

export const FlightServices = {
  postFlightUmroh(payload) {
    return riyuzAPI.post(`umroh-flights`, payload);
  },
  getListFlightUmroh(id, param) {
    return id
      ? riyuzAPI.get(`umroh-flights/${id}`)
      : riyuzAPI.get(`umroh-flights${param ? param : ""}`);
  },
  getDetailFlightUmroh(id) {
    return riyuzAPI.get(`flight/detail/${id}`);
  },
  getDetailKeretaUmroh(id) {
    return riyuzAPI.get(`kereta/detail/${id}`);
  },
  putFlightUmroh(payload) {
    return riyuzAPI.put(`umroh-flights/${payload.id}`, payload.body);
  },
  deleteFlightUmroh(id) {
    return riyuzAPI.delete(`umroh-flights/${id}`);
  },
};
