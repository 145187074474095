import { Container, Row } from "react-bootstrap";
import { isMobileCheck } from "../../utils";
import SlideCardMobile from "../Home/SlideCardMobile";
import SlideCardDesktop from "../Home/SlideCardDesktop";
import styles from "./Recommendations.module.scss";

export default function Recommendation(props) {
  const { related } = props;
  return (
    <Container>
      <div className={styles.recomendation}>
        <div className={styles.heading}>
          <div className={styles.heading}>Rekomendasi Paket Lainnya</div>
          <div className={styles.seeAll}>Lihat Lebih Banyak</div>
        </div>
        <Row>
          {isMobileCheck() ? (
            <SlideCardMobile data={related} />
          ) : (
            <SlideCardDesktop data={related} />
          )}
        </Row>
      </div>
    </Container>
  );
}
