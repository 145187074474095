import { Col, Container, Image, Row } from "react-bootstrap";
import { formatRupiah } from "../../constant/lib";
import styles from "./../../styles/DetailProduct.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import star from "./../../assets/Home/star.svg";
import receipt from "./../../assets/receipt.svg";
import receiptBlack from "./../../assets/receipt-black.svg";
import receiptSquare from "./../../assets/receipt-square.svg";
import share from "./../../assets/share.svg";
import location from "./../../assets/location.svg";
import airplane from "./../../assets/airplane.svg";
import clock from "./../../assets/clock.svg";
import profile from "./../../assets/profile.svg";
import check from "./../../assets/tick-circle.svg";
import wallet from "./../../assets/empty-wallet.svg";
import arrowLeft from "./../../assets/Profile/arrow-left-white.svg";
import { onShareLink } from "../../utils";

export default function HeroShotSearch(props) {
  const currUrl = useParams();

  const { detail, isLogin, checkoutPackages, handleBack, addWishlist } = props;
  return (
    <>
      <div
        style={{ backgroundImage: `url(${detail.image})` }}
        className={styles.heroShot}
      >
        <div className={styles.overlay}></div>
        <div className={styles.backBtn}>
          <Image
            onClick={handleBack}
            style={{ position: "absolute", top: "20px", left: "10px" }}
            className="mb-2"
            src={arrowLeft}
            alt="arrow-left"
          />
        </div>
      </div>
      <Container className="d-flex justify-content-center">
        <Row className="w-100">
          <Col lg={12}>
            <div className={`${styles.title} mt-3`}>{detail?.title}</div>
          </Col>
          <Col xs={6} lg={6} className={styles.detailBox}>
            <div className={styles.detailWrap}>
              {detail.discount_percent != null ? (
                <div className={styles.discount}>{detail.discount_percent}</div>
              ) : null}
              <div className={styles.regulerPrice}>
                {detail.sale_price ? <>{formatRupiah(detail.price)}</> : null}
              </div>
            </div>
            {detail.sale_price ? (
              <div className={styles.salePrice}>
                {formatRupiah(detail.sale_price)}
              </div>
            ) : (
              <div className={styles.salePrice}>
                {formatRupiah(detail.price)}
              </div>
            )}
            <div className={styles.iconWrap}>
              <div className={styles.iconBox}>
                <Image src={star} alt="" />
                <div className={styles.rating}></div>
              </div>
              {detail.star_rate ??
                detail.review_score?.score_total ??
                detail.review_score}{" "}
              Rating
            </div>
          </Col>
          <Col xs={6} lg={6} className={styles.btnWrap}>
            {detail.discount_percent != null ? (
              <div className={styles.discount}>{detail.discount_percent}</div>
            ) : (
              ""
            )}
            {currUrl.package !== "hotel" && (
              <div
                onClick={checkoutPackages}
                className={`btnYellow ${styles.purchase}`}
              >
                Beli Paket
              </div>
            )}
            <div onClick={onShareLink} className={styles.btnShare}>
              <Image src={share} alt="" />
            </div>
            {/* <div
                    
                    className={styles.btnShare}
                  >
                  </div> */}
            {isLogin && (
              <div
                onClick={(e) => addWishlist(e, detail)}
                className={`${styles.btnReceipt} ${styles.btnShare}`}
              >
                <Image src={receiptSquare} alt="" />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
