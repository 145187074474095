import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FormInput from "./ProfileMobile/FormInput";
import email from "./../../assets/Auth/email.svg";
import { useState } from "react";
import { AuthServices } from "../../http/AuthHttp";
import { useNavigate } from "react-router-dom";

export default function ChangePassword(props) {
  const { setIsLoading } = props;
  const [dataInput, setDataInput] = useState();
  const [showResponse, setShowResponse] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const newData = { ...dataInput };
    newData[e.target.id] = e.target.value;
    setDataInput(newData);
  };

  const handleEditInfo = async (e, type) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let response = await AuthServices.changePassword(dataInput);

      if (response) {
        setShowResponse(true);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const actionBtn = () => {
    localStorage.removeItem("riyuz-auth");
    navigate("/");
    setShowResponse(false);
  };

  return (
    <div id="change-password">
      <h1 className="mb-4 mb-md-4">Ganti Password</h1>
      <Form onSubmit={(e) => handleEditInfo(e, "password")}>
        <Row>
          <Col lg={6} className="d-flex flex-column mb-3">
            <FormInput
              label="Password Saat Ini"
              icon={email}
              id="current_password"
              handleChange={handleChange}
            />
          </Col>
          <Col lg={6} className="d-flex flex-column mb-4">
            <FormInput
              label="Password Baru"
              icon={email}
              id="new_password"
              handleChange={handleChange}
            />
          </Col>
          <Col lg={12} className={`d-flex flex-column btnWrapProfile`}>
            <div className="text-end">
              <Button type="submit">Simpan</Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal centered show={showResponse} onHide={() => setShowResponse(false)}>
        <Modal.Body className="p-5">
          <div className="w-100 text-center">
            <h3 className="mb-2">Ganti Password Sukses!</h3>
            <div className="mb-5">
              Silahkan login ulang dengan password baru
            </div>

            <div>
              <Button variant="primary" onClick={actionBtn}>
                Login Ulang
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
