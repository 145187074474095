import { Button, Form, Image } from "react-bootstrap";
import styles from "../../../styles/Search.module.scss";
import vector from "./../../../assets/Vector.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function BusSearch(props) {
  const {
    handleChange,
    handleFilterByPrice,
    filterData,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className={styles.filterBox}>
      <Form>
        {/* <Form.Group className="mb-4">
          <Form.Label>Depart</Form.Label>
          <div className={styles.priceGroup}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        </Form.Group> */}
        <Form.Group className="mb-4">
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label>Harga</Form.Label>
          <div className={styles.priceGroup}>
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="min_price"
              name="min_price"
              type="text"
              className="mb-1"
              // value={minPrice}
              placeholder="MIN"
            />
            -
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="max_price"
              name="max_price"
              className="mb-1"
              type="text"
              placeholder="MAX"
              // value={maxPrice}
            />
          </div>
          <Button
            onClick={handleFilterByPrice}
            className="btnYellow mt-3 mt-md-4 w-100"
          >
            <Image src={vector} />
            Apply Harga
          </Button>
        </Form.Group>
        {/* <Form.Label className="mb-2">Tipe Penerbangan</Form.Label>
        {filterData?.length > 0 &&
          filterData &&
          filterData[1].data &&
          filterData[1]?.data[0]?.terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  value={item.id}
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })}
        <Form.Label className="mb-2">Experience Penerbangan</Form.Label>
        {filterData?.length > 0 &&
          filterData &&
          filterData[1].data &&
          filterData[1]?.data[1]?.terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  value={item.id}
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })} */}
      </Form>
    </div>
  );
}
