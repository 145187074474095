import { Col, Container, Row } from "react-bootstrap";
import styles from "./../../../styles/ProfileMobile.module.scss";

export default function InformationUser(props) {
  const { data, handleLogout } = props;
  console.log(data);

  return (
    <div className={styles.headerProfileMobile}>
      <Container>
        <Row>
          <Col lg={12} className="px-0 d-flex justify-content-between">
            <div>
              <div className={styles.username}>{data?.name}</div>
              <div className={styles.subUsername}>
                {data?.bio ? data?.bio : "-"}
              </div>
            </div>
            <div className={styles.logoutMobile} onClick={handleLogout}>
              Logout
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
