import { Col, Container, Row } from "react-bootstrap";
import WebLayout from "./../components/Layout/WebLayout";
import styles from "./../styles/Cart.module.scss";
import ListCard from "./../components/ListCard";
import SummaryPrice from "../components/Cart/SummaryPrice";
import Recommendation from "../components/Recommendations";

export default function CartPages() {
  return (
    <WebLayout color="dark" type="cart">
      <Container>
        <Row>
          <Col lg={8} md={12} sm={12} className={styles.listCartWrap}>
            <div className={styles.heading}>Keranjang</div>
            <ListCard type="cart" />
            <ListCard type="cart" />
            <ListCard type="cart" />
          </Col>
          <SummaryPrice />
        </Row>
      </Container>
      <Recommendation />
    </WebLayout>
  );
}
