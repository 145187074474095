import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Col, Row } from "react-bootstrap";
import InputCommon from "../../../common/utils";
import { JamaahServices } from "../../../http/JamaahHttp";
import { useState } from "react";
export const DocumentInformation = (props) => {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  const [busUmroh, setBusUmroh] = useState(null);
  const [flightList, setFlightList] = useState(null);
  const [roomList, setRoomList] = useState(null);

  const getBusUmrohList = async (id, param) => {
    try {
      const response = await JamaahServices.getListBusUmroh(id, param);
      if (response) {
        setBusUmroh(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getFlightList = async (id, param) => {
    try {
      const response = await JamaahServices.getListFlightUmroh(id, param);
      if (response) {
        let arr = [];
        for (let i = 0; i < response.data.data.length; i++) {
          arr.push({
            id: response.data.data[i].id,
            name: `${
              response.data.data[i].flight_number
                ? response.data.data[i].flight_number
                : ""
            }-${
              response.data.data[i].flight_booking_code
                ? response.data.data[i].flight_booking_code
                : ""
            }`,
          });
        }
        setFlightList(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRoomList = async (id, param) => {
    try {
      const response = await JamaahServices.getRooms(id, param);
      if (response) {
        let arr = [];
        for (let i = 0; i < response.data.data.length; i++) {
          arr.push({
            // id_room: response.data.data[i].id,
            id: response.data.data[i].id,
            type: response.data.data[i].room_type,
            remain: response.data.data[i].remaining,
            max_guests: response.data.data[i].max_guests,
            name: response.data.data[i].room_code,
          });
        }
        setRoomList(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 4 ? null : 4)}
      >
        <b>Akomodasi</b>
        {formWizardStep == 4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 4 && (
        <div className={styles.collapsibleContent}>
          <Row className="my-4">
            <Col lg={12}>
              <InputCommon
                type="select"
                label="Bus"
                placeholder="Pilih Bus Jamaah"
                id="bus"
                value={payloadDataCreateUser?.bus}
                disabled={
                  (payloadDataCreateUser?.corporate_id?.id ||
                    payloadDataCreateUser?.corporate_id) &&
                  (payloadDataCreateUser?.umroh_batch_id?.id ||
                    payloadDataCreateUser?.umroh_batch_id)
                    ? false
                    : true
                }
                onChange={(e) => handleChangeFamilyData(e)}
                onClick={() =>
                  getBusUmrohList(
                    null,
                    `${
                      payloadDataCreateUser?.corporate_id ||
                      payloadDataCreateUser?.corporate_id?.id
                        ? `?corporate_id=${
                            payloadDataCreateUser?.corporate_id ||
                            payloadDataCreateUser?.corporate_id?.id
                          }`
                        : ""
                    }${
                      payloadDataCreateUser?.umroh_batch_id ||
                      payloadDataCreateUser?.umroh_batch_id?.id
                        ? `&umroh_batch_id=${
                            payloadDataCreateUser?.umroh_batch_id ||
                            payloadDataCreateUser?.umroh_batch_id?.id
                          }`
                        : ""
                    }`
                  )
                }
                data={busUmroh}
                // value={type === "edit" ? editData?.bus : undefined}
              />
            </Col>

            <Col lg={6}>
              <InputCommon
                type="select"
                label="Penerbangan"
                placeholder="Pilih Penerbangan"
                id="flight_id"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.flight_id}
                onClick={() =>
                  getFlightList(
                    null,
                    `${
                      payloadDataCreateUser?.umroh_batch_id ||
                      payloadDataCreateUser?.umroh_batch_id?.id
                        ? `?umroh_batch_id=${
                            payloadDataCreateUser?.umroh_batch_id ||
                            payloadDataCreateUser?.umroh_batch_id?.id
                          }`
                        : ""
                    }`
                  )
                }
                data={flightList}
                // value={type === "edit" ? editData?.flight_id : undefined}
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="common"
                label="No. Bagasi"
                placeholder="Masukkan No. Bagasi"
                id="baggage_number"
                value={payloadDataCreateUser?.baggage_number}
                // value={type === "edit" ? editData?.baggage_number : undefined}
                onChange={(e) => handleChangeFamilyData(e)}
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="select"
                label="Room"
                placeholder="Pilih Room Jamaah"
                id="room_mate"
                value={payloadDataCreateUser?.room_mate}
                disabled={
                  payloadDataCreateUser?.pic_id?.id ||
                  payloadDataCreateUser?.pic_id
                    ? false
                    : true
                }
                // disabled={
                //   type === "edit"
                //     ? editData?.pic_id?.id
                //       ? false
                //       : true
                //     : data?.pic_id
                //     ? false
                //     : true
                // }
                onChange={(e) => handleChangeFamilyData(e)}
                // value={type === "edit" ? editData?.room_mate : undefined}
                onClick={() =>
                  getRoomList(
                    null,
                    `${
                      payloadDataCreateUser?.pic_id?.id ||
                      payloadDataCreateUser?.pic_id
                        ? `?pic_id=${
                            payloadDataCreateUser?.pic_id?.id ||
                            payloadDataCreateUser?.pic_id
                          }`
                        : ""
                    }`
                  )
                }
                data={roomList}
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="select"
                label="Tipe Room"
                placeholder="Tipe Room Jamaah"
                id="room_type"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.room_type}
                onClick={(e) => null}
                data={[
                  { id: "Double", name: "Double" },
                  { id: "Triple", name: "Triple" },
                  { id: "Quad", name: "Quad" },
                ]}
                // value={
                //   type === "edit"
                //     ? selectedRoomMate !== null
                //       ? selectedRoomMate?.type
                //       : null
                //     : undefined
                // }
                disabled
              />
            </Col>

            {/* {showRelation && ( */}
            <Col lg={12}>
              <InputCommon
                type="select"
                label="Hubungan Roommate"
                placeholder="Hubungan Roomate Jamaah"
                id="roommate_relation"
                onChange={(e) => handleChangeFamilyData(e)}
                onClick={(e) => null}
                value={payloadDataCreateUser?.roommate_relation}
                data={[
                  { id: "Father", name: "Ayah" },
                  { id: "Mother", name: "Ibu" },
                  {
                    id: "Husband / Wife",
                    name: "Suami / Istri",
                  },
                  { id: "Son", name: "Anak" },
                  { id: "Friend", name: "Teman" },
                ]}
                // value={
                //   type === "edit" ? editData?.roommate_relation : undefined
                // }
              />
            </Col>
            {/* )} */}
            {/* {editData && ( */}
            <>
              {/* {editData.roommate_relation === "Husband / Wife" &&
                  editData?.roommate_relation !== "null" &&
                  editData?.roommate_relation !== null && ( */}
              <>
                {payloadDataCreateUser.roommate_relation === "Husband / Wife" &&
                  payloadDataCreateUser?.roommate_relation !== "null" &&
                  payloadDataCreateUser?.roommate_relation !== null && (
                    <>
                      <Col lg={6} >
                        <InputCommon
                          type="file"
                          label="Buku Nikah"
                          placeholder="Pilih Buku Nikah"
                          id="buku_nikah"
                          onChange={(e) => handleChangeFamilyData(e)}
                          value={payloadDataCreateUser?.buku_nikah}
                          // value={type === "edit" ? editData?.buku_nikah : undefined}
                        />
                        {/* <div className="default-user">
                    {bukuNikahPreview ? (
                      <CImage src={bukuNikahPreview} />
                    ) : editData?.buku_nikah === undefined ||
                      editData?.buku_nikah === null ? (
                      <div
                        style={{ height: "140px" }}
                        className="p-4 d-flex justify-content-center align-items-center"
                      >
                        <CIcon
                          width={52}
                          height={52}
                          icon={cilImage}
                          customClassName="nav-icon"
                        />
                      </div>
                    ) : (
                      <CImage src={`${STORAGE}${editData?.buku_nikah}`} />
                    )}
                  </div> */}
                      </Col>
                      <Col lg={6} >
                        <InputCommon
                          type="file"
                          label="Kartu Keluarga 1"
                          placeholder="Kartu Keluarga Jamaah"
                          id="kartu_keluarga_1"
                          onChange={(e) => handleChangeFamilyData(e)}
                          value={payloadDataCreateUser?.kartu_keluarga_1}
                          // value={
                          //   type === "edit" ? editData?.kartu_keluarga_1 : undefined
                          // }
                        />
                        {/* <div className="default-user">
                    {kartuKeluarga1 ? (
                      <CImage src={kartuKeluarga1} />
                    ) : editData?.kartu_keluarga_1 === undefined ||
                      editData?.kartu_keluarga_1 === null ? (
                      <div
                        style={{ height: "140px" }}
                        className="p-4 d-flex justify-content-center align-items-center"
                      >
                        <CIcon
                          width={52}
                          height={52}
                          icon={cilImage}
                          customClassName="nav-icon"
                        />
                      </div>
                    ) : (
                      <CImage src={`${STORAGE}${editData?.kartu_keluarga_1}`} />
                    )}
                  </div> */}
                      </Col>
                      <Col lg={6}>
                        <InputCommon
                          type="file"
                          label="Kartu Keluarga 2"
                          placeholder="Kartu Keluarga Jamaah"
                          id="kartu_keluarga_2"
                          onChange={(e) => handleChangeFamilyData(e)}
                          value={payloadDataCreateUser?.kartu_keluarga_2}
                          // value={
                          //   type === "edit" ? editData?.kartu_keluarga_2 : undefined
                          // }
                        />
                        {/* <div className="default-user">
                    {kartuKeluarga2 ? (
                      <CImage src={kartuKeluarga2} />
                    ) : editData?.kartu_keluarga_2 === undefined ||
                      editData?.kartu_keluarga_2 === null ? (
                      <div
                        style={{ height: "140px" }}
                        className="p-4 d-flex justify-content-center align-items-center"
                      >
                        <CIcon
                          width={52}
                          height={52}
                          icon={cilImage}
                          customClassName="nav-icon"
                        />
                      </div>
                    ) : (
                      <CImage src={`${STORAGE}${editData?.kartu_keluarga_2}`} />
                    )}
                  </div> */}
                      </Col>
                    </>
                  )}
              </>
              {/* )}

                {editData.roommate_relation !== "Husband / Wife" &&
                  editData.roommate_relation !== "null" &&
                  editData?.roommate_relation !== null && ( */}
              <></>
              {/* )} */}
            </>
            {/* )} */}
          </Row>
        </div>
      )}
    </div>
  );
};
