import { Button, Form, Image } from "react-bootstrap";
import styles from "../../../styles/Search.module.scss";
import vector from "./../../../assets/Vector.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function FlightSearch(props) {
  const {
    handleChange,
    handleFilterByPrice,
    filterData,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;

  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    // Handle attrs[1][]
    const attrs12 = queryParams.getAll("attrs[12][]");
    attrs12.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[12][]"][value="${attr}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    // Handle attrs[2][]
    const attrs13 = queryParams.getAll("attrs[13][]");
    attrs13.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[13][]"][value="${attr}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });
  }, [location.search]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className={styles.filterBox}>
      <Form>
        {/* <Form.Group className="mb-4">
          <Form.Label>Depart</Form.Label>
          <div className={styles.priceGroup}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        </Form.Group> */}
        <Form.Group className="mb-4">
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label>Harga</Form.Label>
          <div className={styles.priceGroup}>
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="min_price"
              name="min_price"
              type="text"
              // value={minPrice}
              placeholder="MIN"
            />
            -
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="max_price"
              name="max_price"
              type="text"
              placeholder="MAX"
              // value={maxPrice}
            />
          </div>
          <Button
            onClick={handleFilterByPrice}
            className="btnYellow mt-3 mt-md-4 w-100"
          >
            <Image src={vector} />
            Apply Harga
          </Button>
        </Form.Group>
        <Form.Label className="mb-2">Tipe Penerbangan</Form.Label>
        {filterData?.length > 0 &&
          filterData &&
          filterData[1].data &&
          filterData[1]?.data[0]?.terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  value={item.id}
                  name="attrs[12][]"
                  id="attrs[12][]"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })}
        <Form.Label className="mb-2">Experience Penerbangan</Form.Label>
        {filterData?.length > 0 &&
          filterData &&
          filterData[1].data &&
          filterData[1]?.data[1]?.terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  value={item.id}
                  name="attrs[13][]"
                  id="attrs[13][]"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })}
      </Form>
    </div>
  );
}
