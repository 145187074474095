import { setCookie, getCookie, deleteCookie } from "./lib";

export const authService = {
  setLogin,
  isLogin,
  logout,
  redirectToLogin,
  redirectToRegister,
  getLoginAuth,
  logoutNoRedirect,
};

// set credentials
function setLogin(payload) {
  const cookieData = JSON.stringify({ ...payload });
  setCookie("riyuz-auth", cookieData, 99);
}

// redirect to page dashboard if login
function isLogin() {
  const _cookie = getCookie("riyuz-auth");
  return _cookie;
  // _cookie ? router.push(`/dashboard`) : router.push(`/login`)
}

function getLoginAuth() {
  const _cookie = getCookie("riyuz-auth");
  if (_cookie) return JSON.parse(_cookie);
  else return [];
}

function redirectToLogin(router) {
  const _cookie = getCookie("riyuz-auth");
  const { asPath } = router;

  !_cookie && router.push(`/login?targetUrl=${asPath}`);
}

function redirectToRegister(router) {
  const _cookie = getCookie("riyuz-auth");
  !_cookie && router.push(`/register`);
}

// redirect to page home
function logout(router) {
  deleteCookie("riyuz-auth");
  // router.push(`/login`);
}

// logout no redirect
function logoutNoRedirect() {
  deleteCookie("riyuz-auth");
}
