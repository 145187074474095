import { formatRupiah } from "../../constant/lib";
import styles from "./../../styles/DetailProduct.module.scss";

export default function FlightLocation(props) {
  const { detail } = props;
  return (
    <>
      <div className={styles.contentBox}>
        <div className={styles.heading}>Lokasi Keberangkatan</div>
        {detail.airport_from?.name} - {detail.airport_from?.code}
      </div>
      <div className={styles.contentBox}>
        <div className={styles.heading}>Lokasi Tujuan</div>
        {detail.airport_to?.name} - {detail.airport_to?.code}
      </div>
      <div className={styles.contentBox}>
        <div className={styles.heading}>Flight Seat</div>
        {detail.flight_seat?.map((item, idx) => {
          return (
            <div
              className={`d-flex flex-row justify-content-between align-items-center ${styles.flight_seat_item}`}
              key={idx}
            >
              <div>
                <b>Tipe Seat: {item.seat_type?.name}</b>
                <div>Penumpang Maks : {item?.max_passengers}</div>
              </div>
              <div>{formatRupiah(item.price)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}
