import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import email from "./../../assets/Auth/email.svg";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import { AuthServices } from "../../http/AuthHttp";
import FormInput from "./ProfileMobile/FormInput";
import { useEffect, useState } from "react";
import { JamaahServices } from "../../http/JamaahHttp";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ChangePassword from "./ChangePassword";
export default function PrivateInformation(props) {
  const { isMobile, data, setIsLoading } = props;
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [dataInput, setDataInput] = useState({
    email: data.email,
    first_name: data.first_name,
    birthday: data.birthday,
    last_name: data.last_name,
    user_name: data.user_name,
    phone: data.phone,
    address: data.address,
    bio: data.bio,
    city: data.city,
    country: data.country,
  });
  const [dataPilgrims, setDataPilgrims] = useState({
    ...data.booking_umroh_pilgrim,
  });

  useEffect(() => {
    if (dataInput?.birthday) {
      setStartDate(new Date(dataInput?.birthday));
    }
  }, [dataInput]);

  const handleSubmitData = async (e) => {
    // e.preventDefault();
    // setIsLoading(true);
    let userId = localStorage.getItem("riyuz-auth");
    userId = JSON.parse(userId);
    console.log(userId);
    let payloadPut = {};
    if (dataPilgrims?.rt) {
      payloadPut.rt = dataPilgrims?.rt;
    }
    if (dataPilgrims?.rw) {
      payloadPut.rw = dataPilgrims?.rw;
    }
    if (dataPilgrims?.no_unit) {
      payloadPut.no_unit = dataPilgrims?.no_unit;
    }
    if (dataPilgrims?.provinsi) {
      payloadPut.provinsi = dataPilgrims?.provinsi;
    }
    if (dataPilgrims?.kabupaten_kodya) {
      payloadPut.kabupaten_kodya = dataPilgrims?.kabupaten_kodya;
    }
    if (dataPilgrims?.kecamatan) {
      payloadPut.kecamatan = dataPilgrims?.kecamatan;
    }
    if (dataPilgrims?.desa_kelurahan) {
      payloadPut.desa_kelurahan = dataPilgrims?.desa_kelurahan;
    }
    if (dataPilgrims?.kode_pos) {
      payloadPut.rt = dataPilgrims?.kode_pos;
    }
    if (dataPilgrims?.alamat_surat_menyurat) {
      payloadPut.alamat_surat_menyurat = dataPilgrims?.alamat_surat_menyurat;
    }
    try {
      const payload = {
        id: userId?.id,
        body: payloadPut,
      };
      const response = await JamaahServices.putJamaah(payload);
      if (response) {
        setIsLoading(false);
        // navigate("/profile");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const [showResponse, setShowResponse] = useState(false);

  const handleEditInfo = async (e, type) => {
    e.preventDefault();
    handleSubmitData();
    setIsLoading(true);
    dataInput.birthday = moment(startDate).format("YYYY-MM-DD");
    try {
      let response = await AuthServices.postMe(dataInput);
      if (type === "password") {
        response = await AuthServices.changePassword(dataInput);
      }
      if (response) {
        if (type === "password") {
          setShowResponse(true);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const actionBtn = () => {
    localStorage.removeItem("riyuz-auth");
    navigate("/");
    setShowResponse(false);
  };

  const handleChange = (e) => {
    const newData = { ...dataInput };
    newData[e.target.id] = e.target.value;
    setDataInput(newData);
  };

  const handleChangePilgrims = (e) => {
    const newData = { ...dataPilgrims };
    newData[e.target.id] = e.target.value;
    setDataPilgrims(newData);
  };

  return (
    <Col lg={8}>
      <div className={styles.menuShow}>
        <h1 className="mb-4 mb-md-4">Informasi Pribadi</h1>
        <Form
          className="mb-5 mb-md-3"
          onSubmit={(e) => handleEditInfo(e, "personal")}
        >
          <Row>
            <Col xs={6} lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Nama Awal"
                id="first_name"
                handleChange={handleChange}
                value={dataInput.first_name}
                type="text"
              />
            </Col>
            <Col xs={6} lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Nama Akhir"
                id="last_name"
                handleChange={handleChange}
                value={dataInput.last_name}
                type="text"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <label className="mb-2">Tanggal Lahir</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                //           showYearDropdown
                //           showMonthDropdown
                // useShortMonthInDropdown
                value={moment(startDate).format("DD-MM-YYYY")}
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Email"
                id="email"
                handleChange={handleChange}
                value={dataInput?.email}
                type="email"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="No. Telepon"
                id="phone"
                handleChange={handleChange}
                value={
                  dataInput.phone === null ||
                  dataInput.phone === undefined ||
                  dataInput.phone === "null"
                    ? ""
                    : dataInput.phone
                }
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Username"
                id="user_name"
                handleChange={handleChange}
                value={dataInput.user_name}
                type="text"
              />
            </Col>
            <Col xs={6} lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Kota"
                id="city"
                handleChange={handleChange}
                value={
                  dataInput.city === null ||
                  dataInput.city === undefined ||
                  dataInput.city === "null"
                    ? ""
                    : dataInput.city
                }
                type="text"
              />
            </Col>
            <Col xs={6} lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Negara"
                id="country"
                handleChange={handleChange}
                value={
                  dataInput.country === null ||
                  dataInput.country === undefined ||
                  dataInput.country === "null"
                    ? ""
                    : dataInput.country
                }
                type="text"
              />
            </Col>
            <Col lg={12} className="d-flex flex-column mb-3">
              <FormInput
                label="Address"
                id="address"
                handleChange={handleChange}
                value={dataInput.address}
                isTextArea={true}
              />
            </Col>
            <Col lg={12} className="d-flex flex-column mb-3">
              <FormInput
                label="Bio"
                id="bio"
                handleChange={handleChange}
                value={dataInput.bio}
                isTextArea={true}
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="No. KTP"
                id="no_ktp"
                handleChange={handleChangePilgrims}
                value={dataPilgrims.no_ktp}
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Nama Mahram"
                id="nama_mahram"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.nama_mahram == "null" ||
                  dataPilgrims.nama_mahram == null
                    ? undefined
                    : dataPilgrims.nama_mahram
                }
                type="text"
              />
            </Col>
            <Col xs={6} lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="RT"
                id="rt"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.rt == "null" || dataPilgrims.rt == null
                    ? undefined
                    : dataPilgrims.rt
                }
                type="text"
              />
            </Col>{" "}
            <Col xs={6} lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="RW"
                id="rw"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.rw == "null" || dataPilgrims.rw == null
                    ? undefined
                    : dataPilgrims.rw
                }
                type="text"
              />
            </Col>{" "}
            <Col lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="No. Bangunan"
                id="no_unit"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.no_unit == "null" || dataPilgrims.no_unit == null
                    ? undefined
                    : dataPilgrims.no_unit
                }
                type="text"
              />
            </Col>
            <Col lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="Provinsi"
                id="provinsi"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.provinsi == "null" ||
                  dataPilgrims.provinsi == null
                    ? undefined
                    : dataPilgrims.provinsi
                }
                type="text"
              />
            </Col>
            <Col lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="Kabupaten"
                id="kabupaten_kodya"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.kabupaten_kodya == "null" ||
                  dataPilgrims.kabupaten_kodya == null
                    ? undefined
                    : dataPilgrims.kabupaten_kodya
                }
                type="text"
              />
            </Col>{" "}
            <Col lg={4} className="d-flex flex-column mb-3">
              <FormInput
                label="Kecamatan"
                id="kecamatan"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.kecamatan == "null" ||
                  dataPilgrims.kecamatan == null
                    ? undefined
                    : dataPilgrims.kecamatan
                }
                type="text"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Kelurahan"
                id="desa_kelurahan"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.desa_kelurahan == "null" ||
                  dataPilgrims.desa_kelurahan == null
                    ? undefined
                    : dataPilgrims.desa_kelurahan
                }
                type="text"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column mb-3">
              <FormInput
                label="Kode Pos"
                id="kode_pos"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.kode_pos == "null" ||
                  dataPilgrims.kode_pos == null
                    ? undefined
                    : dataPilgrims.kode_pos
                }
                type="text"
              />
            </Col>
            <Col lg={12} className="d-flex flex-column mb-3">
              <FormInput
                label="Alamat Surat Menyurat"
                id="alamat_surat_menyurat"
                handleChange={handleChangePilgrims}
                value={
                  dataPilgrims.alamat_surat_menyurat == "null" ||
                  dataPilgrims.alamat_surat_menyurat == null
                    ? undefined
                    : dataPilgrims.alamat_surat_menyurat
                }
                isTextArea={true}
              />
            </Col>
            <Col lg={12} className={`d-flex flex-column mb-3 btnWrapProfile`}>
              <div className="text-end mb-5 mb-md-0">
                <Button type="submit">Simpan</Button>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="d-none d-md-block">
          <ChangePassword setIsLoading={setIsLoading} />
        </div>
      </div>
      <Modal centered show={showResponse} onHide={() => setShowResponse(false)}>
        <Modal.Body className="p-5">
          <div className="w-100 text-center">
            <h3 className="mb-2">Ganti Password Sukses!</h3>
            <div className="mb-5">
              Silahkan login ulang dengan password baru
            </div>

            <div>
              <Button variant="primary" onClick={actionBtn}>
                Login Ulang
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
}
