import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Col, Row } from "react-bootstrap";
import InputCommon from "../../../common/utils";

export default function VisaInformation(props) {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 10 ? null : 10)}
      >
        <b>Visa</b>
        {formWizardStep == 10 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 10 && (
        <div className={styles.collapsibleContent}>
          <Row className="my-4">
            <Col lg={6}>
              <InputCommon
                type="date"
                // disabled={editData?.id ? false : true}
                label="Date of Expiration"
                placeholder="Pilih Date of Expiration"
                id="date_of_expiration"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.date_of_expiration}
                // value={
                //   type === "edit"
                //     ? moment(visaSelected?.date_of_expiration).format(
                //         "YYYY-MM-DD"
                //       )
                //     : undefined
                // }
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="date"
                // disabled={editData?.id ? false : true}
                label="Date of Issue"
                placeholder="Pilih Date of Issue"
                id="date_of_issue"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.date_of_issue}
                // value={
                //   type === "edit"
                //     ? moment(visaSelected?.date_of_issue).format("YYYY-MM-DD")
                //     : undefined
                // }
              />
            </Col>
            <Col lg={12}>
              <InputCommon
                type="common"
                // disabled={editData?.id ? false : true}
                label="Place of Issue"
                placeholder="Masukkan Place of Issue"
                id="place_of_issue"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.place_of_issue}
                // value={
                //   type === "edit" ? visaSelected?.place_of_issue : undefined
                // }
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
