import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Col, Row } from "react-bootstrap";
import InputCommon from "../../../common/utils";

export default function PassportInformation(props) {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 9 ? null : 9)}
      >
        <b>Passport</b>
        {formWizardStep == 9 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 9 && (
        <div className={styles.collapsibleContent}>
          <Row className="my-4">
            <Col lg={6}>
              <InputCommon
                type="common"
                label="Nama Sesuai Passport"
                placeholder="Masukkan Nama Sesuai Passport"
                id="nama_sesuai_passport"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.nama_sesuai_passport}
                // value={type === "edit" ? editData?.nama_sesuai_passport : undefined}
              />
              <Row>
                <Col lg={6}>
                  <InputCommon
                    type="common"
                    label="No. Passport"
                    placeholder="No. Passport Jamaah"
                    id="no_passport"
                    onChange={(e) => handleChangeFamilyData(e)}
                    // value={
                    //   type === "edit"
                    //     ? editData?.no_passport !== "null"
                    //       ? editData?.no_passport
                    //       : undefined
                    //     : undefined
                    // }
                    value={payloadDataCreateUser?.no_passport}
                  />
                </Col>

                <Col lg={6}>
                  <InputCommon
                    type="date"
                    label="Passport Berlaku"
                    placeholder="Pilih Passport Berlaku"
                    id="passport_berlaku"
                    onChange={(e) => handleChangeFamilyData(e)}
                    // value={
                    //   type === "edit" ? editData?.passport_berlaku : undefined
                    // }
                    value={payloadDataCreateUser?.passport_berlaku}
                  />
                </Col>
                <Col lg={12}>
                  <InputCommon
                    type="select"
                    label="Status Passport Fisik Dikirim"
                    placeholder="Pilih Status Passport Fisik Dikirim"
                    id="is_fisik_passport"
                    onChange={(e) => handleChangeFamilyData(e)}
                    onClick={(e) => null}
                    value={payloadDataCreateUser?.is_fisik_passport}
                    data={[
                      {
                        id: "false",
                        name: "Belum Dikirim",
                      },
                      {
                        id: "true",
                        name: "Sudah Dikirim",
                      },
                    ]}
                    // value={
                    //   type === "edit" ? editData?.is_fisik_passport : undefined
                    // }
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <InputCommon
                type="file"
                label="Foto Passport"
                placeholder="Masukkan Nama Sesuai Passport"
                id="dokumen_passport"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.dokumen_passport}
                // value={type === "edit" ? editData?.dokumen_passport : undefined}
              />
              {/* <div className="default-user">
                {passportPreview ? (
                  <CImage src={passportPreview} />
                ) : editData?.dokumen_passport === undefined ||
                  editData?.dokumen_passport === null ||
                  editData?.dokumen_passport === "null" ? (
                  <div
                    style={{ height: "140px" }}
                    className="p-4 d-flex justify-content-center align-items-center"
                  >
                    <CIcon
                      width={52}
                      height={52}
                      icon={cilImage}
                      customClassName="nav-icon"
                    />
                  </div>
                ) : (
                  <CImage src={`${STORAGE}${editData?.dokumen_passport}`} />
                )} */}
              {/* {passportPreview ? (
        <CImage src={passportPreview} />
      ) : (
        <div
          style={{ height: "140px" }}
          className="p-4 d-flex justify-content-center align-items-center"
        >
          <CIcon
            width={52}
            height={52}
            icon={cilImage}
            customClassName="nav-icon"
          />
        </div>
              </div>
      )} */}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
