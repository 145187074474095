import { Col, Container, Row } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./../styles/Calculator.module.scss";

export default function CalculatorWisata() {
  const [showNav, setShowNav] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [listHotel, setListHotel] = useState(null);
  const [listFlight, setListFlight] = useState(null);
  const [listTrain, setListTrain] = useState(null);
  const [listBus, setListBus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [itineraryType, setItineraryType] = useState([
    {
      accomodation: "",
    },
  ]);
  const [agenda, setAgenda] = useState([
    {
      date: "",
      start: "",
      end: "",
      description: "",
    },
  ]);

  const handleChange = (e, id) => {
    const { value } = e.target;
    const newData = [...itineraryType];
    newData[id].accomodation = value;
    setItineraryType(newData);
    setSelectedData(value);
  };

  const handleChangeCategory = () => {};

  const selectHotel = (e) => {
    const { name, value } = e.target;
    setSelectedHotel(value);
  };

  const addAgenda = (e) => {
    e.preventDefault();
    setAgenda([
      ...agenda,
      {
        date: "",
        start: "",
        end: "",
        description: "",
      },
    ]);
  };

  const addItinerary = (e) => {
    e.preventDefault();
    setItineraryType([
      ...itineraryType,
      {
        accomodation: "",
      },
    ]);
  };

  const handleChangeAgenda = (e, i) => {
    const { name, value } = e.target;
    const newData = [...agenda];
    newData[i][name] = value;
    setAgenda(newData);
  };

  const deleteAgenda = (e, i) => {
    e.preventDefault();
    const newData = [...agenda];
    newData.splice(i, 1);
    setAgenda(newData);
  };

  const deleteAkomodasi = (e, i) => {
    e.preventDefault();
    const newData = [...itineraryType];
    newData.splice(i, 1);
    setItineraryType(newData);
  };

  const getListData = async (endpoint, data) => {
    try {
      const res = "";
      if (res) {
        if (data === "bus") {
          setListBus(res);
        } else if (data === "train") {
          setListTrain(res);
        } else if (data === "hotel") {
          setListHotel(res);
        } else {
          setListFlight(res);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectedStartDate = (date) => {
    setStartDate(date);
  };

  const selectedEndDate = (date) => {
    setEndDate(date);
  };

  return (
    <WebLayout showNav={showNav}>
      <Container>
        <Row className="mb-5">
          <Col lg={12}>
            <div className="mb-4">
              <div
                className={`d-flex justify-content-between align-items-center mb-2`}
              >
                <h1 className="mb-2 mb-md-3">Kalkulator Wisata</h1>
              </div>
              <form>
                {repetitiveInput("input", "text", "Judul Wisata", "")}
                <div className="d-flex gap-2 justify-content-between">
                  <div className="w-50">
                    {repetitiveInput(
                      "select",
                      "",
                      "Kategori",
                      category,
                      handleChangeCategory
                    )}
                  </div>
                  <div className="w-50">
                    {repetitiveInput("input", "text", "Jumlah Peserta", "")}
                  </div>
                </div>
                {repetitiveInput("select", "", "Visa", defaultData)}
                {repetitiveInput("select", "", "Tour Guide", defaultData)}
              </form>
            </div>
            <div>
              <div className="d-flex justify-content-between mb-2">
                <div className={styles.titleCalculator}>Itinerary</div>
                <div>
                  <button className={styles.btnAction} onClick={addItinerary}>
                    Add Itenarary
                  </button>
                </div>
              </div>
              <form className="mb-5">
                {itineraryType.map((e, idx) => (
                  <>
                    {repetitiveInput(
                      "select",
                      "",
                      "Akomodasi",
                      typeData,
                      (e) => handleChange(e, idx),
                      "accomodation",
                      e.accomodation
                    )}
                    {e.accomodation === "flight" && (
                      <div>
                        {repetitiveInput(
                          "date",
                          "",
                          "Tanggal",
                          "",
                          selectedStartDate,
                          "",
                          "",
                          startDate
                        )}
                        {repetitiveInput("select", "", "From", defaultData)}
                        {repetitiveInput("select", "", "To", defaultData)}
                        {repetitiveInput(
                          "select",
                          "",
                          "Pilih Flight",
                          defaultData
                        )}
                      </div>
                    )}
                    {e.accomodation === "bus" && (
                      <div>
                        {repetitiveInput(
                          "date",
                          "",
                          "Tanggal",
                          "",
                          selectedStartDate,
                          "",
                          "",
                          startDate
                        )}
                        {repetitiveInput("select", "", "From", defaultData)}
                        {repetitiveInput("select", "", "To", defaultData)}
                        {repetitiveInput(
                          "select",
                          "",
                          "Pilih Bus",
                          defaultData
                        )}
                      </div>
                    )}
                    {e.accomodation === "train" && (
                      <div>
                        {repetitiveInput(
                          "date",
                          "",
                          "Tanggal",
                          "",
                          selectedStartDate,
                          "",
                          "",
                          startDate
                        )}
                        {repetitiveInput("select", "", "From", defaultData)}
                        {repetitiveInput("select", "", "To", defaultData)}
                        {repetitiveInput(
                          "select",
                          "",
                          "Pilih Train",
                          defaultData
                        )}
                      </div>
                    )}
                    {e.accomodation === "hotel" && (
                      <div>
                        {repetitiveInput(
                          "date",
                          "",
                          "Check In",
                          "",
                          selectedStartDate,
                          "",
                          "",
                          startDate
                        )}
                        {repetitiveInput(
                          "date",
                          "",
                          "Check Out",
                          "",
                          selectedEndDate,
                          "",
                          "",
                          endDate
                        )}
                        {repetitiveInput("select", "", "Location", defaultData)}
                        {repetitiveInput(
                          "select",
                          "",
                          "Pilih Hotel",
                          defaultData,
                          selectHotel
                        )}
                        {selectedHotel && (
                          <>
                            <div className="d-flex justify-content-between">
                              <div>Agenda</div>
                              <div>
                                <button onClick={addAgenda}>Add Agenda</button>
                              </div>
                            </div>
                            {agenda.map((_, i) => {
                              return (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <div>Detail Agenda {i + 1}</div>
                                    {agenda?.length > 1 && (
                                      <div>
                                        <button
                                          onClick={(e) => deleteAgenda(e, i)}
                                        >
                                          Hapus
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  {repetitiveInput(
                                    "input",
                                    "date",
                                    "Tanggal Agenda",
                                    "",
                                    (e) => handleChangeAgenda(e, i),
                                    "date",
                                    _.date
                                  )}
                                  {repetitiveInput(
                                    "input",
                                    "date",
                                    "Start",
                                    "",
                                    (e) => handleChangeAgenda(e, i),
                                    "start",
                                    _.start
                                  )}
                                  {repetitiveInput(
                                    "input",
                                    "date",
                                    "End",
                                    "",
                                    (e) => handleChangeAgenda(e, i),
                                    "end",
                                    _.end
                                  )}
                                  {repetitiveInput(
                                    "textarea",
                                    "",
                                    "Kegiatan",
                                    "",
                                    (e) => handleChangeAgenda(e, i),
                                    "description",
                                    _.description
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    )}
                    {itineraryType?.length > 1 && (
                      <div className={`w-100 text-end`}>
                        <button
                          className={styles.btnAction_reset}
                          onClick={(e) => deleteAkomodasi(e, idx)}
                        >
                          Hapus
                        </button>
                      </div>
                    )}
                  </>
                ))}
                {selectedData && (
                  <button className="mb-3 w-100">Lanjutkan</button>
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </WebLayout>
  );
}

const defaultData = [
  {
    label: "Option 1",
    value: "option_1",
  },
];

const typeData = [
  {
    label: "Flight",
    value: "flight",
  },
  {
    label: "Hotel",
    value: "hotel",
  },
  {
    label: "Bus",
    value: "bus",
  },
  {
    label: "Train",
    value: "train",
  },
];

const category = [
  {
    label: "City Trips",
    value: "city_trips",
  },
  {
    label: "Ecotourism",
    value: "ecotourism",
  },
  {
    label: "Escorted Tour",
    value: "escorted_tour",
  },
  {
    label: "Lingula",
    value: "lingula",
  },
  {
    label: "Haji",
    value: "hajj",
  },
  {
    label: "Tour",
    value: "tour",
  },
];

const repetitiveInput = (
  type,
  inputType,
  label,
  data,
  func,
  name,
  value,
  date
) => {
  switch (type) {
    case "input":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <input type={inputType} name={name} onChange={func} value={value} />
        </div>
      );
    case "select":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <select onChange={func} name={name}>
            <option value="" hidden>
              Pilih {label}
            </option>
            {data?.map((_, i) => (
              <option value={_.value}>{_.label}</option>
            ))}
          </select>
        </div>
      );
    case "textarea":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <textarea rows={3} name={name} onChange={func}>
            {value}
          </textarea>
        </div>
      );
    case "date":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <DatePicker
            selected={date}
            onChange={func}
            timeIntervals={30}
            minDate={new Date()}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd MMMM yyyy, HH:mm"
            placeholderText={`Pilih Waktu ${label}`}
          />
        </div>
      );
    default:
      return null;
  }
};
