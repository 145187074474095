import { Col, Form, Row } from "react-bootstrap";
import styles from "./../../styles/ConfirmationBooking.module.scss";

export default function CommonInformationUser(props) {
  const { data, handleChange } = props;
  const inputCommon = (label, data, id, placeholder, type, lg) => {
    return (
      <Col lg={lg} md={12} sm={12}>
        <div className={styles.inputGroup}>
          <label className={styles.text}>{label}</label>
          <div className={styles.inputWrap}>
            <input
              className={`${styles.inputBox} ${styles.inputBox_confirmation}`}
              value={data}
              type={type}
              id={id}
              onChange={handleChange}
              placeholder={placeholder}
            />
          </div>
        </div>
      </Col>
    );
  };
  return (
    <>
      <div className={styles.heading}>Lengkapi Data Diri</div>
      <div className={styles.inputForm}>
        <form>
          <Row>
            {inputCommon(
              "Nama Depan",
              data?.first_name,
              "first_name",
              "Nama depan",
              "text",
              6
            )}
            {inputCommon(
              "Nama Belakang",
              data?.last_name,
              "last_name",
              "Nama belakang",
              "text",
              6
            )}
            {inputCommon("Email", data?.email, "email", "Email", "email", 6)}
            {inputCommon(
              "No. Telp",
              data?.phone == "null" || data?.phone == null
                ? undefined
                : data?.phone,
              "phone",
              "No. Telp",
              "text",
              6
            )}
            {inputCommon(
              "Alamat 1",
              data?.address ? data?.address : undefined,
              "address",
              "Alamat 1",
              "text",
              6
            )}
            {inputCommon(
              "Alamat 2",
              data?.address2 ? data?.address2 : undefined,
              "address2",
              "Alamat 2",
              "text",
              6
            )}
            {inputCommon(
              "Kota",
              data?.city == null || data?.city == "null"
                ? undefined
                : data?.city,
              "city",
              "Kota",
              "text",
              4
            )}
            {inputCommon(
              "Kode Pos",
              data?.zip_code ? data?.zip_code : undefined,
              "zip_code",
              "Kode Pos",
              "text",
              4
            )}
            {inputCommon(
              "Negara",
              data?.country == "null" || data?.country
                ? undefined
                : data?.country,
              "country",
              "Negara",
              "text",
              4
            )}
            <Col lg={12} md={12} sm={12}>
              <div className={styles.inputGroup}>
                <label className={styles.text}>Spesial Request</label>
                <div className={styles.inputWrap}>
                  <Form.Control
                    className={`${styles.inputBox} ${styles.inputBox_confirmation}`}
                    as="textarea"
                    placeholder="Spesial Request"
                    style={{ height: "200px" }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
}
