import { riyuzAPI } from "../constant/api";

export const VisaServices = {
  postVisa(payload) {
    return riyuzAPI.post(`umroh-visas`, payload);
  },
  getVisas(id, param) {
    return id
      ? riyuzAPI.get(`umroh-visas/${id}`)
      : riyuzAPI.get(`umroh-visas${param ? param : ""}`);
  },
  putVisa(payload) {
    return riyuzAPI.put(`umroh-visas/${payload.id}`, payload.body);
  },
  deleteVisa(id) {
    return riyuzAPI.delete(`umroh-visas/${id}`);
  },
};
