import { riyuzAPI, riyuzAPIV2 } from "../constant/api";

export const AuthServices = {
  login(payload) {
    return riyuzAPIV2.post(`login`, payload);
  },
  register(payload) {
    return riyuzAPIV2.post(`auth/register`, payload);
  },
  logout() {
    return riyuzAPI.post(`auth/logout`);
  },
  refresh() {
    return riyuzAPI.post(`auth/refresh`);
  },
  postMe(payload) {
    return riyuzAPI.post(`auth/me`, payload);
  },
  postVerify(payload) {
    return riyuzAPI.post(`auth/verifyotp`, payload);
  },
  changePassword(payload) {
    return riyuzAPI.post(`auth/change-password`, payload);
  },
  forgotPassword(payload) {
    return riyuzAPI.post(`forgot-password`, payload);
  },
  resetPassword(payload) {
    return riyuzAPI.post(`reset-password`, payload);
  },
  getMe(param) {
    return riyuzAPIV2.get(`auth/me${param ? param : ""}`);
  },
};
