import { Button, Image, Modal } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import emailIcon from "./../assets/Auth/email.svg";
import eye from "./../assets/Auth/eye.svg";
import passwordIcon from "./../assets/Auth/password.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AuthServices } from "../http/AuthHttp";
import AuthLayout from "../components/Layout/AuthLayout";
import ModalCenter from "../components/Layout/Modal";
import { JamaahServices } from "../http/JamaahHttp";
import logo from "./../logo.png";

export default function Login() {
  const navigate = useNavigate();
  const passwordRef = useRef();
  const typeLoginRef = useRef();
  const valueRef = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeLogin, setTypeLogin] = useState();
  const [dataLogin, setDataLogin] = useState();
  const [labelSelected, setLabelSelected] = useState();
  const [typeResponse, setTypeResponse] = useState();
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isPhoneNull, setIsPhoneNull] = useState(false);
  const [isNIKNull, setIsNIKNull] = useState(false);
  const [isEmailNull, setIsEmailNull] = useState(false);
  const [completePilgrim, setCompletePilgrim] = useState(false);
  const [completeUser, setCompleteUser] = useState(false);
  const [placeholder, setPlaceholder] = useState();
  const [data, setData] = useState({
    type_login: "",
    password: "",
    value: "",
  });
  const [textResponse, setTextResponse] = useState({
    heading: "",
    text: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const type = searchParams.get("type");
  const email = searchParams.get("email");
  const password = searchParams.get("password");

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      if (completePilgrim && dataLogin.user?.need_update_pw === 1) {
        navigate("/profile#change-password");
      } else {
        navigate("/");
      }
    }
  }, [completePilgrim, dataLogin]);

  useEffect(() => {
    if (type) {
      setTypeLogin(type);
      typeLoginRef.current.value = type;
    }

    // console.log(type);
  }, [type]);

  useEffect(() => {
    if (typeLogin) {
      passwordRef.current.value = password;
      valueRef.current.value = email;
      data.value = email;
      data.password = password;
      // email, password;
    }
  }, [typeLogin]);

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (typeLogin) {
      if (showPassword) {
        passwordRef.current.type = "text";
      } else {
        passwordRef.current.type = "password";
      }
    }
  }, [showPassword, typeLogin]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    data.type_login = typeLogin;
    setIsLoading(true);
    try {
      const response = await AuthServices.login(data);
      if (response) {
        let userInfo = response.user;
        setDataLogin(response);

        setIsLoading(false);
        if (userInfo.email_verified_at === null) {
          setIsLoading(false);
          if (userInfo.phone == null || userInfo.phone == "null") {
            setIsPhoneNull(true);
          }
          if (userInfo.email === null) {
            setIsEmailNull(true);
          }
          if (userInfo.umroh_pilgrim_id) {
            if (
              userInfo.umroh_pilgrim_id.no_ktp == null ||
              userInfo.umroh_pilgrim_id.no_ktp == "null"
            ) {
              setIsNIKNull(true);
            }
          }
          setModalConfirmation(true);
          // let cookie = {
          //   token: response.token,
          //   id: response.user.id,
          //   name: response.user.first_name,
          // };
          // // console.log(response);
          // localStorage.setItem("riyuz-auth", JSON.stringify(cookie));
          // navigate("/");
        } else {
          // setCompletePilgrim(true);
          let cookie = {
            token: response.token,
            id: response.user.id,
            name: response.user.first_name,
          };
          // console.log(response);
          localStorage.setItem("riyuz-auth", JSON.stringify(cookie));
          navigate("/");
        }
      }
    } catch (err) {
      setIsLoading(false);
      setShowModal(true);
      console.log(err);
    }
  };

  const handleChangeMethod = (e) => {
    switch (e.target.value) {
      case "email":
        setPlaceholder("Masukkan Email");
        break;
      case "no_hp":
        setPlaceholder("Masukkan Nomor HP");
        break;
      case "no_ktp":
        setPlaceholder("Masukkan Nomor KTP");
        break;
      case "no_passport":
        setPlaceholder("Masukkan Nomor Passport");
        break;
      default:
        break;
    }
    setTypeLogin(e.target.value);
  };

  const labelRender = (typeLogin) => {
    switch (typeLogin) {
      case "email":
        return "Email";
      case "no_hp":
        return "No. HP";
      case "no_ktp":
        return "No. KTP";
      case "no_passport":
        return "No. Passport";
      default:
        break;
    }
  };

  const completedDataPilgrims = async () => {
    setIsLoading(true);
    let payloadPut = {};

    if (isNIKNull) {
      payloadPut.no_ktp = data.no_ktp;
    }
    if (isEmailNull) {
      payloadPut.email = data.value;
    }
    if (isPhoneNull) {
      payloadPut.phone = data.phone;
    }
    try {
      const payload = {
        id: dataLogin.user.umroh_pilgrim_id?.id,
        body: payloadPut,
      };
      const response = await JamaahServices.putJamaah(payload);
      if (response) {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleVerif = async (e) => {
    e.preventDefault();
    // console.log(data);
    let payload = {
      value: data.value,
      password: data.password,
      type_login: data.type_login,
      otp: data.otp,
    };
    setIsLoading(true);
    setModalConfirmation(false);
    setTypeResponse("verifying");
    try {
      let response = await AuthServices.postVerify(payload);
      if (response) {
        let cookie = {
          token: response.token,
          id: response.data.id,
          name: response.data.name,
        };
        localStorage.setItem("riyuz-auth", JSON.stringify(cookie));
        setIsLoading(false);
        setCompletePilgrim(true);
        if (dataLogin.user.umroh_pilgrim_id !== null) {
          completedDataPilgrims();
        }
      }
    } catch (err) {
      // setShowModal(true);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <AuthLayout isLoading={isLoading}>
      <div className={styles.circle}>
        <Link to="/">
          <Image src={logo} alt="" />
        </Link>
      </div>
      <div className={styles.form}>
        <div className={styles.title}>Login</div>
        <div className={styles.text}>Masukkan Akun Terdaftar Anda</div>
        <form>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Mau Login dengan apa?</label>
            <div className={styles.inputWrap}>
              {/* <Image src={emailIcon} alt="email" /> */}
              <select
                className={styles.inputBox}
                id="type_login"
                onChange={handleChangeMethod}
                ref={typeLoginRef}
                placeholder="Pilih Metode Login"
              >
                <option hidden>--- Pilih Metode Login ---</option>
                <option value="email" id="Email">
                  Email
                </option>
                <option value="no_hp" id="No. HP">
                  No. HP
                </option>
                <option value="no_ktp" id="No. KTP">
                  No. KTP
                </option>
                <option value="no_passport" id="No. Passport">
                  No. Passport
                </option>
              </select>
            </div>
          </div>
          {typeLogin && (
            <>
              <div className={styles.inputGroup}>
                <label className={styles.text}>{labelRender(typeLogin)}</label>
                <div className={styles.inputWrap}>
                  {/* <Image src={emailIcon} alt="email" /> */}
                  <input
                    onChange={handleChange}
                    className={styles.inputBox}
                    id="value"
                    ref={valueRef}
                    placeholder={placeholder}
                  />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.text}>Password</label>
                <div className={styles.inputWrap}>
                  {/* <Image src={passwordIcon} alt="password" /> */}
                  <input
                    className={`${styles.inputBox} ${styles.inputBox__password}`}
                    type="password"
                    id="password"
                    ref={passwordRef}
                    onChange={handleChange}
                    placeholder="Password Kamu"
                  />
                  <Image
                    className={styles.password}
                    onClick={handleShowPassword}
                    src={eye}
                    alt="eye"
                  />
                </div>
                <a href="/forgot-password" className={styles.hook}>
                  Lupa Password?
                </a>
                <Button onClick={handleSubmit} variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </>
          )}
        </form>
        <div className={`${styles.sign} mb-4`}>
          Belum punya akun?{" "}
          <Link to="/register">
            <b>Sign Up</b>
          </Link>
        </div>
        <div className={styles.sign}>
          <Link to="/">
            <b>Kembali ke beranda</b>
          </Link>
        </div>
      </div>
      <Modal
        show={modalConfirmation}
        onHide={() => setModalConfirmation(false)}
        //size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h5 className="mb-4">
            Mohon Verifikasi Akun
            {isEmailNull || isNIKNull || isPhoneNull ? " & Lengkapi Data " : ""}
            Anda
          </h5>
          <form>
            {isEmailNull && (
              <div className="form-group d-flex flex-column mb-3">
                <label className={`${styles.text} mb-2`}>Email</label>
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  id="email"
                  placeholder="Masukkan Email"
                />
              </div>
            )}
            {isNIKNull && (
              <div className="form-group d-flex flex-column mb-3">
                <label className={`${styles.text} mb-2`}>No. KTP</label>
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  id="no_ktp"
                  placeholder="Masukkan No. KTP"
                  required
                />
              </div>
            )}
            {isPhoneNull && (
              <div className="form-group d-flex flex-column mb-3">
                <label className={`${styles.text} mb-2`}>Telepon</label>
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  id="phone"
                  placeholder="Masukkan No. Telepon"
                  required
                />
              </div>
            )}
            <div className="form-group d-flex flex-column mb-5">
              <label className={`${styles.text} mb-2`}>Masukkan OTP</label>
              <div className="alert alert-info">
                Silahkan cek OTP anda di email
              </div>
              <input
                onChange={handleChange}
                className={styles.inputBox}
                id="otp"
                placeholder="Masukkan OTP"
                required
              />
            </div>
            <div className="w-100">
              <Button
                onClick={handleVerif}
                variant="primary"
                className="w-100"
                type="submit"
              >
                Verifikasi
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showModalResponse}
        onHide={() => setShowModalResponse(false)}
      >
        <Modal.Body className="p-5">
          <div className="w-100 text-center">
            <h3 className="mb-2">Tes</h3>
            <div className="mb-5">Tes</div>

            <div>
              {/* <Button variant="primary" onClick={actionBtn}>
                {textResponse.type === "success"
                  ? "Login"
                  : "Ulangi Registrasi"}
              </Button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ModalCenter
        type="response"
        callback=""
        setShow={showModal}
        setHide={() => setShowModal(false)}
        setTitle=""
        setBody={`${"Email atau password yang anda masukan salah."}`}
      />
    </AuthLayout>
  );
}
