import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export default function ModalCenter(props) {
  // type = response, confirmation
  const { type, callback, setShow, setHide, setTitle, setBody, size } = props;

  return (
    <Modal
      show={setShow}
      onHide={setHide}
      size={size ? size : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {setTitle && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {setTitle}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="py-4 text-center">
          <h5>{setBody}</h5>
        </div>
      </Modal.Body>
      {type === "response" ? (
        <></>
      ) : (
        <Modal.Footer>
          <>
            <Button variant="secondary" onClick={props.setHide}>
              Cancel
            </Button>
            <Button variant="success" onClick={callback}>
              Ya
            </Button>
          </>
        </Modal.Footer>
      )}
    </Modal>
  );
}
