import { riyuzAPI } from "../constant/api";

export const RoomServices = {
  postRoom(payload) {
    return riyuzAPI.post(`umroh-room-lists`, payload);
  },
  getRooms(id, param) {
    return id
      ? riyuzAPI.get(`umroh-room-lists/${id}`)
      : riyuzAPI.get(`umroh-room-lists${param ? param : ""}`);
  },
  putRoom(payload) {
    return riyuzAPI.put(`umroh-room-lists/${payload.id}`, payload.body);
  },
  deleteRoom(id) {
    return riyuzAPI.delete(`umroh-room-lists/${id}`);
  },
};
