export const isMobileCheck = () => {
  let dimension = window.innerWidth;
  let isMobile = false;
  if (dimension <= 768) {
    isMobile = true;
  } else {
    isMobile = false;
  }
  return isMobile;
};

export const convertToParams = (queryObject) => {
  return new URLSearchParams(queryObject).toString();
};

export const onShareLink = () => {
  // Mengambil URL dari window.location
  const urlToCopy = window.location.href;

  // Membuat elemen textarea untuk sementara
  const textarea = document.createElement("textarea");
  textarea.value = urlToCopy;

  // Menambahkan elemen textarea ke dokumen
  document.body.appendChild(textarea);

  // Memilih teks dalam elemen textarea
  textarea.select();
  textarea.setSelectionRange(0, 99999); // Untuk mendukung perangkat seluler

  // Menyalin teks yang telah dipilih ke clipboard
  document.execCommand("copy");

  // Menghapus elemen textarea yang tidak diperlukan
  document.body.removeChild(textarea);

  // Menampilkan pesan bahwa URL telah disalin
  alert("URL telah disalin ke clipboard!");
};
