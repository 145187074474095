import { STORAGE } from "../../common/utils";
import BusCard from "./BusCard";
import CommonCard from "./CommonCard";
import FlightCard from "./FlightCard";
import TrainCard from "./TrainCard";

export default function CardProject(props) {
  const { item, type } = props;

  // check image is null
  let imagePath = "";
  if (item.image != null) {
    imagePath = item.image.file_path;
  }

  const handleImageError = (e) => {
    e.target.src = STORAGE(imagePath).on_error;
  };

  return type === "flight" ? (
    <FlightCard item={item} type={type} handleImageError={handleImageError} />
  ) : type === "bus" ? (
    <BusCard item={item} type={type} handleImageError={handleImageError} />
  ) : type === "train" ? (
    <TrainCard item={item} type={type} handleImageError={handleImageError} />
  ) : (
    <CommonCard item={item} type={type} handleImageError={handleImageError} />
  );
}
