import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Search from "./pages/Search";
import RoomHotel from "./pages/RoomHotel";
import DetailProduct from "./pages/DetailProduct";
import Cart from "./pages/Cart";
import ConfirmationBooking from "./pages/ConfirmationBooking";
import CustomPackage from "./pages/CustomPackage";
import ProfileUser from "./pages/Profile";
import Thankyou from "./pages/Thankyou";
import ResetPassword from "./pages/ResetPassword";
import ScrollToTopOnNavigate from "./components/common/ScrollToTopOnNavigate";
import MyTrip from "./pages/MyTrip";
import MyWishlist from "./pages/MyWishlist";
import CalculatorWisata from "./pages/CalculatorWisata";

const App = () => {
  // <ScrollToTop />
  let routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/calculator",
      element : <CalculatorWisata/>
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/my-trip",
      element: <MyTrip />,
    },
    {
      path: "/my-wishlist",
      element: <MyWishlist />,
    },
    {
      path: "/hotel/availability/:id",
      element: <RoomHotel />,
    },
    {
      path: "/:package/:package_name/:id",
      element: <DetailProduct />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
      path: "/confirmation-booking",
      element: <ConfirmationBooking />,
    },
    {
      path: "/custom-package",
      element: <CustomPackage />,
    },
    {
      path: "/profile",
      element: <ProfileUser />,
    },
    {
      path: "/thankyou/:booking_code",
      element: <Thankyou />,
    },
  ]);
  return routes;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.Suspense fallback={<Loading />}>
  <Router>
    <ScrollToTopOnNavigate />
    <App />
  </Router>
  // </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
