import {
  Accordion,
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import styles from "./../styles/DetailProduct.module.scss";
import location from "./../assets/location.svg";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css";
import Recommendation from "../components/Recommendations";
import ModalCenter from "../components/Layout/Modal";
import { useEffect, useRef, useState } from "react";
import { PlaceServices } from "../http/PlaceHttp";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatRupiah, slugify } from "../constant/lib";
import { UserServices } from "../http/UserHttp";
import profile from "./../assets/profile.svg";
import { authService } from "../constant/authServices";
import { BookingServices } from "../http/BookingHttp";
import StickyInformation from "../components/Search/StickyInformation";
import HeroShotSearch from "../components/Search/HeroShotSearch";
import Description from "../components/Search/Description";
import IncludeExclude from "../components/Search/IncludeExclude";
import Itinerary from "../components/Search/Itinerary";
import FAQ from "./FAQ";
import StickyAction from "../components/Search/StickyAction";
import FlightLocation from "../components/Search/FlightLocation";
import DepartureLocation from "../components/Search/DepartureLocation";
import HotelLocation from "../components/Search/HotelLocation";
import defaultPlaceholder from "../assets/default.png";
import moment from "moment/moment";
import CardProject from "../components/Card";
import { type } from "@testing-library/user-event/dist/type";
import Skeleton from "react-loading-skeleton";

export default function DetailProduct(props) {
  const { item, type, handleImageError } = props;
  const [count, setCount] = useState(1);
  const [modalCheck, setModalCheck] = useState(false);

  const [roomAvail, setRoomAvail] = useState(null);
  const [modalRoomAvail, setModalRoomAvail] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [typeUrl, setTypeUrl] = useState("hotel");
  const [bookingCode, setBookingCode] = useState("");
  const [isSuccessAddToCart, setIsSuccessAddToCart] = useState(false);
  const currUrl = useParams();
  const [detail, setDetail] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statModal, setStatModal] = useState("none");
  const [roomList, setRoomList] = useState();

  useEffect(() => {
    // Set endDate to one day after startDate
    if (startDate) {
      const nextDay = new Date(startDate);
      nextDay.setDate(nextDay.getDate() + 1);
      setStartDate(nextDay);
    }
    if (endDate) {
      const nextDay = new Date(endDate);
      nextDay.setDate(nextDay.getDate() + 2);
      setEndDate(nextDay);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  useEffect(() => {
    if (currUrl) {
      getDetailData(currUrl.id, currUrl.package);
      // getRoomList(currUrl.id, currUrl.package)
      // .then((data) => setDetail(data))
      // .catch((error) => console.error('Error fetching room list:', error));
    }
  }, [currUrl]);

  useEffect(() => {
    if (isSuccessAddToCart) {
      navigate(
        `/confirmation-booking${
          currUrl.package == "hotel" ? "" : `?guest=${count}`
        }${currUrl.package == "hotel" ? "?" : "&"}price=${
          (detail.sale_price ? detail.sale_price : detail.price) * count
        }&title=${slugify(
          detail.title
        )}&booking_code=${bookingCode}&type=${typeUrl}${
          startDate
            ? `&start_date=${moment(startDate).format("YYYY-MM-DD")}`
            : ""
        }${endDate ? `&end_date=${moment(endDate).format("YYYY-MM-DD")}` : ""}${
          typeUrl === "hotel"
            ? `&adults=${adultGuest}&children=${childGuest}`
            : ""
        }`
      );
    }
  }, [isSuccessAddToCart]);

  const handleAdd = (type) => {
    if (type === "increment") {
      setCount(count + 1);
    } else {
      if (count === 1) {
        console.log("count already 1");
      } else {
        setCount(count - 1);
      }
    }
  };

  const [adultGuest, setAdultGuest] = useState(1);
  const [childGuest, setChildGuest] = useState(0);
  const [roomQty, setRoomQty] = useState(0);
  const roomQtyRef = useRef();
  const handleAddGuest = (type, guest) => {
    if (guest === "adult") {
      if (type === "increment") {
        setAdultGuest(adultGuest + 1);
      } else {
        if (adultGuest === 1) {
          console.log("count already 1");
        } else {
          setAdultGuest(adultGuest - 1);
        }
      }
    } else {
      if (type === "increment") {
        setChildGuest(childGuest + 1);
      } else {
        if (childGuest === 0) {
          console.log("count already 0");
        } else {
          setChildGuest(childGuest - 1);
        }
      }
    }
  };
  const getDetailData = async (id, packages) => {
    // console.log(JSON.stringify(getDetailData));
    setIsLoading(true);
    try {
      let response;
      switch (packages) {
        case "flight":
          response = await PlaceServices.flightById(id);
          break;
        case "hotel":
          response = await PlaceServices.hotelById(id);
          // Additional logic for getting room list for hotels
          let roomListResponse;
          try {
            const today = moment();
            const startDate = today.clone().add(1, "day").format("YYYY-MM-DD");
            const endDate = today.clone().add(2, "days").format("YYYY-MM-DD");

            const param = `?${
              startDate && endDate
                ? `start_date=${startDate}&end_date=${endDate}`
                : ""
            }${adultGuest ? `&adults=${adultGuest}` : ""}${
              childGuest ? `&children=${childGuest}` : ""
            }`;
            roomListResponse = await PlaceServices.roomHotelList(id, param);
            // console.log(JSON.stringify(roomListResponse, null, 2));
            setRoomList(roomListResponse);
          } catch (error) {
            console.error(error);
            // Handle error as needed
          }
          // console.log(JSON.stringify(setRoomList, null, 2));

          break;
        case "bus":
          response = await PlaceServices.busById(id);
          break;
        case "tour":
          response = await PlaceServices.tourById(id);
          break;
        case "visa":
          response = await PlaceServices.visaById(id);
          break;
        default:
          break;
      }

      if (response) {
        setDetail(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const addToCartHotel = async (e) => {};

  const addToCart = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("riyuz-auth")) {
      setIsLoading(true);
      try {
        let payload = {
          service_id: detail.id,
          service_type: detail.object_model,
          guests: count,
          start_date: moment().format("YYYY-MM-DD"),
        };
        const response = await BookingServices.postAddToCart(payload);
        // console.log(detail);
        if (response) {
          setBookingCode(response.booking_code);
          setTypeUrl("umroh");
          setIsSuccessAddToCart(true);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        setIsSuccessAddToCart(false);
        console.log(err);
      }
    } else {
      navigate("/login");
    }
  };

  const addWishlist = async (e, data) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let payload = {
        object_id: data.id,
        object_model: data.object_model,
      };
      const response = await UserServices.postWishlist(payload);
      if (response) {
        setIsLoading(false);
        setStatModal("success");
        setShowModal(true);
      }
    } catch (err) {
      setIsLoading(false);
      setStatModal("failed");
      console.log(err);
    }
  };

  const checkoutPackages = (e) => {
    if (localStorage.getItem("riyuz-auth")) {
      if (detail) {
        addToCart(e);
      }
    } else {
      navigate("/login");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const checkRoom = async (e, id) => {
    setIsLoading(true);
    e.preventDefault();
    // const formData = new FormData();
    // formData.append("hotel_id", id);
    // formData.append("start_date", moment(startDate).format("YYYY-MM-DD"));
    // formData.append("end_date", moment(endDate).format("YYYY-MM-DD"));
    // formData.append("adults", adultGuest);
    // formData.append("children", childGuest);
    // formData.append("firstLoad", false);
    // let payload = {
    //   hotel_id: id,
    //   start_date: moment(startDate).format("YYYY-MM-DD"),
    //   end_date: moment(endDate).format("YYYY-MM-DD"),
    //   adults: adultGuest,
    //   children: childGuest,
    //   firstLoad: false,
    // };
    try {
      let param = `?${
        startDate && endDate
          ? `start_date=${moment(startDate).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(endDate).format("YYYY-MM-DD")}`
          : ""
      }${adultGuest ? `&adults=${adultGuest}` : ""}${
        childGuest ? `&children=${childGuest}` : ""
      }`;
      console.log("ini param bos", param);
      const response = await PlaceServices.checkAvailability(id, param);
      if (response) {
        // setRoomList(response.data);
        setRoomAvail(response);
        setModalRoomAvail(true);
        setModalCheck(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (roomAvail?.rooms?.length > 0) {
      const newData = [...roomAvail.rooms].map((room) => ({
        qty: 0,
        id: room.id,
        price: room.price,
      }));

      setRoomQuantities(newData);
    }
  }, [roomAvail]);

  const [roomQuantities, setRoomQuantities] = useState([]);
  const [showTotal, setShowTotal] = useState(false);

  const handleAddRoomQty = (type, idx, price, id) => {
    const newQuantities = [...roomQuantities];

    if (type === "increment") {
      newQuantities[idx].qty += 1;
    } else if (type === "decrement" && newQuantities[idx].qty > 0) {
      newQuantities[idx].qty -= 1;
    }
    setShowTotal(true);

    setRoomQuantities(newQuantities);
  };

  const total = roomQuantities.reduce((acc, item) => {
    const subtotal = parseInt(item.qty) * parseInt(item.price);
    return acc + subtotal;
  }, 0);

  const handleBookHotel = async () => {
    const outputObject = {};
    const filteredPayload = roomQuantities.filter((item) => item.qty > 0);
    localStorage.setItem("hotelBooking", JSON.stringify(filteredPayload));
    filteredPayload.forEach((item, index) => {
      outputObject[`rooms[${index}][id]`] = item.id;
      outputObject[`rooms[${index}][number_selected]`] = item.qty;
    });
    let payload = {
      service_id: detail.id,
      service_type: "hotel",
      guests: count,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      adults: adultGuest,
      children: childGuest,
    };
    setIsLoading(true);
    let finalPayload = { ...payload, ...outputObject };
    const formData = new FormData();

    // Append each key-value pair from finalPayload to the FormData object
    for (const key in finalPayload) {
      formData.append(key, finalPayload[key]);
    }
    try {
      const response = await BookingServices.postAddToCart(formData);
      // console.log(detail);
      if (response) {
        // setBookingCode(response.booking_code);
        setBookingCode(response.booking_code);

        setTypeUrl("hotel");
        setIsSuccessAddToCart(true);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setIsSuccessAddToCart(false);
    }
  };

  const checkAvailability = () => {
    setModalCheck(true);
  };

  return (
    <WebLayout type="hide" color="black">
      {detail ? (
        <>
          <HeroShotSearch
            detail={detail}
            isLogin={isLogin}
            checkoutPackages={checkoutPackages}
            handleBack={handleBack}
            addWishlist={addWishlist}
          />
          <Container>
            <Row className={styles.contentWrap}>
              <Col lg={8} className={styles.detail}>
                {(currUrl.package === "tour" ||
                  currUrl.package === "hotel" ||
                  currUrl.package === "visa") && (
                  <Description detail={detail} />
                )}
                {currUrl.package === "visa" && (
                  <>
                    <IncludeExclude detail={detail} type="visa" />
                  </>
                )}
                {currUrl.package === "tour" && (
                  <>
                    <IncludeExclude detail={detail} type="tour" />
                    <DepartureLocation detail={detail} />
                  </>
                )}
                {currUrl.package === "hotel" && (
                  <HotelLocation detail={detail} />
                )}

                {currUrl.package === "tour" && <Itinerary detail={detail} />}

                {/* <div className={styles.contentBox}>
                  <div className={styles.heading}>Review Paket</div>
                  {detail.review_lists.data?.length > 0 &&
                    detail.review_lists.data.map((item, index) => {
                      return <ReviewItem data={item} />;
                    })}
                </div> */}
                {currUrl.package === "flight" && (
                  <FlightLocation detail={detail} />
                )}

                {currUrl?.package === "hotel" ? (
                  <>
                    <div className={styles.contentBox}>
                      <div className={styles.heading}>Room Hotel</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 60 + "px",
                        }}
                      >
                        {roomList?.rooms?.length > 0 ? (
                          roomList?.rooms && (
                            <>
                              <Row className={`${styles.listWrap}`}>
                                {roomList.rooms.map((item, index) => {
                                  return (
                                    <Col
                                      lg={12}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={index}
                                    >
                                      {/* <CardProject item={item} type={type} /> */}
                                      <Link
                                        to={checkoutPackages}
                                        className={`${styles.listItem} ${styles.listItem_available}`}
                                      >
                                        <div className={styles.detailItem}>
                                          <div className="me-3">
                                            <img
                                              src={defaultPlaceholder}
                                              alt="img"
                                              width={75}
                                            />
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className={styles.thumbnail}>
                                              {item.discount_percent && (
                                                <div
                                                  className={styles.discount}
                                                >
                                                  {item.discount_percent}
                                                </div>
                                              )}
                                            </div>
                                            <div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className={styles.wrapTitle}
                                              >
                                                <div
                                                  style={{
                                                    overflow: "hidden",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: 2,
                                                    lineClamp: 2,
                                                    WebkitBoxOrient: "vertical",
                                                    fontSize: 14 + "px",
                                                    fontWeight: 500,
                                                    color: "#000000",
                                                  }}
                                                  className={styles.title}
                                                >
                                                  {item?.title}
                                                </div>
                                              </div>
                                              {
                                                <>
                                                  {item.sale_price ? (
                                                    item?.price ? (
                                                      <div
                                                        style={{
                                                          color: "#00854c",
                                                          fontSize: 16 + "px",
                                                          fontWeight: 800,
                                                        }}
                                                        className={
                                                          styles.originalPrice
                                                        }
                                                      >
                                                        {formatRupiah(
                                                          item?.price
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                  {item.sale_price ? (
                                                    <div
                                                      style={{
                                                        color: "#00854c",
                                                        fontSize: 16 + "px",
                                                        fontWeight: 800,
                                                      }}
                                                      className={
                                                        styles.salePrice
                                                      }
                                                    >
                                                      {formatRupiah(
                                                        item.sale_price
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        color: "#00854c",
                                                        fontSize: 16 + "px",
                                                        fontWeight: 800,
                                                      }}
                                                      className={
                                                        styles.salePrice
                                                      }
                                                    >
                                                      {formatRupiah(
                                                        item?.price
                                                      )}
                                                    </div>
                                                  )}
                                                </>
                                              }
                                              <div className={styles.iconRoom}>
                                                <div>
                                                  <span className="me-2">
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/494/494970.png"
                                                      alt="img"
                                                      width={24}
                                                    />
                                                  </span>
                                                  <span>{item.beds_html}</span>
                                                </div>
                                                <div>
                                                  <span className="me-2">
                                                    <Image
                                                      src={profile}
                                                      alt=""
                                                    />
                                                  </span>
                                                  {item.adults_html}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </>
                          )
                        ) : (
                          <div className="text-center">Tidak Ada Data</div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <FAQ detail={detail} />
              </Col>
              <StickyInformation
                detail={detail}
                isLogin={isLogin}
                checkoutPackages={checkoutPackages}
                handleAdd={handleAdd}
                handleAddGuest={handleAddGuest}
                adultGuest={adultGuest}
                childGuest={childGuest}
                onChange={onChange}
                startDate={startDate}
                checkRoom={checkRoom}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                count={count}
                addToCart={addToCart}
              />
            </Row>
          </Container>

          {detail.related?.length > 0 && (
            <Recommendation related={detail.related} />
          )}
          <StickyAction
            checkoutPackages={checkoutPackages}
            addToCart={addToCart}
            handleAdd={handleAdd}
            count={count}
            currUrl={currUrl}
            checkAvailability={checkAvailability}
          />
          <ModalCenter
            type="response"
            callback=""
            setShow={showModal}
            setHide={() => setShowModal(false)}
            setTitle=""
            setBody={`${
              statModal === "success"
                ? "Berhasil menambahkan wishlist"
                : statModal === "failed"
                ? "Gagal menambahkan wishlist"
                : ""
            }`}
          />
          <Modal
            show={modalCheck}
            onHide={() => setModalCheck(false)}
            size="lg"
            centered
          >
            <Modal.Body>
              <>
                <div className={`${styles.quantityWrap} mt-4 mb-5`}>
                  <div className={`${styles.quantityOrder} mb-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      <Image src={profile} alt="" />
                      Tamu Dewasa
                    </div>
                    <div className={styles.detailQuantityOrder}>
                      <div
                        className={`${styles.btnInput} me-3`}
                        onClick={() => handleAddGuest("decrement", "adult")}
                      >
                        -
                      </div>
                      <input className={styles.qty} value={adultGuest} />
                      <div
                        className={`${styles.btnInput} ms-3`}
                        onClick={() => handleAddGuest("increment", "adult")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.quantityOrder} mb-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      <Image src={profile} alt="" />
                      Tamu Anak-Anak
                    </div>
                    <div className={styles.detailQuantityOrder}>
                      <div
                        className={`${styles.btnInput} me-3`}
                        onClick={() => handleAddGuest("decrement", "child")}
                      >
                        -
                      </div>
                      <input className={styles.qty} value={childGuest} />
                      <div
                        className={`${styles.btnInput} ms-3`}
                        onClick={() => handleAddGuest("increment", "child")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className={` mb-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      Check In & Check Out
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                    />
                  </div>
                </div>
                <div>
                  <Button
                    onClick={(e) => checkRoom(e, detail.id)}
                    className={`btnYellow mb-4 w-100 ${styles.addToCart}`}
                  >
                    Cek Ketersediaan Room
                  </Button>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <Modal
            show={modalRoomAvail}
            onHide={() => setModalRoomAvail(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Kamar Tersedia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {roomAvail?.rooms?.length > 0
                ? roomAvail.rooms.map((item, idx) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div>
                          <div className="mb-2">{item.title}</div>
                          <div>
                            <div
                              className={`d-flex align-items-center justify-content-start ${styles.detailQuantityOrder}`}
                            >
                              <div
                                className={`${styles.btnInput} me-3`}
                                onClick={() =>
                                  handleAddRoomQty(
                                    "decrement",
                                    idx,
                                    item.price,
                                    item.id
                                  )
                                }
                              >
                                -
                              </div>
                              <input
                                className={styles.qty}
                                value={roomQuantities[idx]?.qty || 0}
                              />
                              <div
                                className={`${styles.btnInput} ms-3`}
                                onClick={() =>
                                  handleAddRoomQty(
                                    "increment",
                                    idx,
                                    item.price,
                                    item.id
                                  )
                                }
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>{formatRupiah(item.price)}</div>
                      </div>
                    );
                  })
                : ""}

              <div className="mt-5 d-flex justify-content-end align-items-center">
                <div className="mb-0 me-3">
                  Total : <b>{showTotal ? formatRupiah(total) : "-"}</b>
                </div>
                <div>
                  <Button onClick={handleBookHotel}>Book Hotel</Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Skeleton height={245} />
          <Container>
            <Row className={styles.contentWrap}>
              <Col lg={12} className={styles.detail}>
                <Skeleton
                  height={22}
                  style={{ marginBottom: "8px" }}
                  count={8}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </WebLayout>
  );
}
