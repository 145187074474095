import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  educationLevel,
  golonganDarah,
  hubunganMahram,
  isAlreadyHajj,
  jenisKelamin,
  occupationList,
  sizeBajuKoko,
} from "../../../constant/variable";
import InputCommon from "../../../common/utils";
export const PersonalInformation = (props) => {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 2 ? null : 2)}
      >
        <b>Informasi Personal</b>
        {formWizardStep == 2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 2 && (
        <div className={styles.collapsibleContent}>
          <InputCommon
            type="common"
            label="Nama Lengkap"
            placeholder="Nama Lengkap Jamaah"
            id="nama_lengkap"
            onChange={handleChangeFamilyData}
            value={payloadDataCreateUser?.nama_lengkap}
          />
          <div className="row">
            <div className="col-6">
              <InputCommon
                type="common"
                label="Tempat Lahir"
                placeholder="Tempat Lahir"
                id="tempat_lahir"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.tempat_lahir}
                // value={
                //   type === "edit"
                //     ? editData?.tempat_lahir != "null"
                //       ? editData?.tempat_lahir
                //       : undefined
                //     : undefined
                // }
              />
            </div>
            <div className="col-6">
              <InputCommon
                type="date"
                label="Tanggal Lahir"
                placeholder="Tanggal Lahir"
                id="tanggal_lahir"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.tanggal_lahir}
              />
            </div>
          </div>
          <InputCommon
            type="common"
            label="Email"
            placeholder="Masukkan Email"
            id="email"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.email}
            // value={type === "edit" ? editData?.email : undefined}
          />
          <div className="row">
            <div className="col-6">
              <InputCommon
                type="select"
                label="Jenis Kelamin"
                placeholder="Pilih Jenis Kelamin"
                id="jenis_kelamin"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.jenis_kelamin}
                // value={type === "edit" ? editData?.jenis_kelamin : undefined}
                onClick={() => null}
                data={[
                  { id: "L", name: "Laki-laki" },
                  { id: "P", name: "Perempuan" },
                ]}
              />
            </div>
            <div className="col-6">
              <InputCommon
                type="select"
                label="Status Perkawinan"
                placeholder="Status Perkawinan Jamaah"
                id="status_perkawinan"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.status_perkawinan}
                // value={type === "edit" ? editData?.status_perkawinan : undefined}
                onClick={() => null}
                data={[
                  { id: "Single", name: "Single" },
                  { id: "Married", name: "Married" },
                ]}
              />
            </div>
          </div>
          <InputCommon
            type="select"
            label="Hubungan Keluarga"
            placeholder="Pilih Hubungan Keluarga"
            id="relationship_type"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.relationship_type}
            // value={type === "edit" ? editData?.status_perkawinan : undefined}
            onClick={() => null}
            data={[
              { id: "Father", name: "Ayah" },
              { id: "Mother", name: "Ibu" },
              { id: "Brother", name: "Kakak" },
              { id: "Sister", name: "Adik" },
              { id: "Son", name: "Anak" },
              { id: "Aunt", name: "Tante" },
              { id: "Uncle", name: "Keponakan" },
              { id: "Cousin", name: "Cucu" },
              { id: "Grandfather", name: "Kakek" },
              { id: "Grandmother", name: "Nenek" },
            ]}
          />
          <InputCommon
            type="common"
            label="No. Telp"
            placeholder="No. Telp Jamaah"
            id="telp"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.telp}
            // value={
            //   type === "edit"
            //     ? editData?.telp != "null"
            //       ? editData?.telp
            //       : undefined
            //     : undefined
            // }
          />
          <InputCommon
            type="select"
            label="Pekerjaan"
            placeholder="Pekerjaan Jamaah"
            id="pekerjaan"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.pekerjaan}
            // value={type === "edit" ? editData?.pekerjaan : undefined}
            onClick={() => null}
            data={occupationList}
          />
          <div className="row">
            <div className="col-6">
              <InputCommon
                type="select"
                label="Pendidikan"
                placeholder="Pendidikan Jamaah"
                id="pendidikan"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.pendidikan}
                // value={type === "edit" ? editData?.pendidikan : undefined}
                data={educationLevel}
              />
            </div>
            <div className="col-6">
              <InputCommon
                type="select"
                label="Golongan Darah"
                placeholder="Golongan Darah Jamaah"
                id="golongan_darah"
                onChange={(e) => handleChangeFamilyData(e)}
                // value={
                //   type === "edit"
                //     ? editData?.golongan_darah != "null"
                //       ? editData?.golongan_darah
                //       : undefined
                //     : undefined
                // }
                onClick={() => null}
                data={golonganDarah}
              />
            </div>
          </div>

          <InputCommon
            type="common"
            label="Nama Ayah Kandung"
            placeholder="Nama Ayah Kandung Jamaah"
            id="nama_ayah_kandung"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.nama_ayah_kandung}
            // value={
            //   type === "edit"
            //     ? editData?.nama_ayah_kandung != "null"
            //       ? editData?.nama_ayah_kandung
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="textarea"
            label="Alamat"
            placeholder="Alamat Anda"
            id="alamat"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.alamat}
            //       ? editData?.alamat
            //       : undefined
            //     : undefined
            // }
          />
          <div className="row">
            <div className="col-6">
              <InputCommon
                type="common"
                label="RT"
                placeholder="RT"
                id="rt"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.rt}
                // value={
                //   type === "edit"
                //     ? editData?.rt !== "null"
                //       ? editData?.rt
                //       : undefined
                //     : undefined
                // }
              />
            </div>
            <div className="col-6">
              <InputCommon
                type="common"
                label="RW"
                placeholder="RW"
                id="rw"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.rw}
                // value={
                //   type === "edit"
                //     ? editData?.rw !== "null"
                //       ? editData?.rw
                //       : undefined
                //     : undefined
                // }
              />
            </div>
          </div>

          <InputCommon
            type="common"
            label="No. Bangunan"
            placeholder="No. Bangunan"
            id="no_unit"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.no_unit}
            // value={
            //   type === "edit"
            //     ? editData?.no_unit !== "null"
            //       ? editData?.no_unit
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Provinsi"
            placeholder="Provinsi Jamaah"
            id="provinsi"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.provinsi}
            // value={
            //   type === "edit"
            //     ? editData?.provinsi != "null"
            //       ? editData?.provinsi
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Kabupaten"
            placeholder="Kabupaten Jamaah"
            id="kabupaten_kodya"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.kabupaten_kodya}
            // value={
            //   type === "edit"
            //     ? editData?.kabupaten_kodya != "null"
            //       ? editData?.kabupaten_kodya
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Kecamatan"
            placeholder="Kecamatan Jamaah"
            id="kecamatan"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.kecamatan}
            // value={
            //   type === "edit"
            //     ? editData?.kecamatan != "null"
            //       ? editData?.kecamatan
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Desa Kelurahan"
            placeholder="Desa Kelurahan Jamaah"
            id="desa_kelurahan"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.desa_kelurahan}
            // value={
            //   type === "edit"
            //     ? editData?.desa_kelurahan != "null"
            //       ? editData?.desa_kelurahan
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Kode Pos"
            placeholder="Kode Pos Jamaah"
            id="kode_pos"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.kode_pos}
            // value={
            //   type === "edit"
            //     ? editData?.kode_pos != "null"
            //       ? editData?.kode_pos
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="textarea"
            label="Alamat Surat Menyurat"
            placeholder="Alamat Surat Menyurat Jamaah"
            id="alamat_surat_menyurat"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.alamat_surat_menyurat}
            // value={
            //   type === "edit"
            //     ? editData?.alamat_surat_menyurat != "null"
            //       ? editData?.alamat_surat_menyurat
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="common"
            label="Plakat"
            placeholder="Jenis Plakat"
            id="plakat"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.plakat}
            //       ? editData?.plakat
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="select"
            label="Ukuran Baju Koko"
            placeholder="Ukuran Baju Jamaah"
            id="ukuran_baju"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.ukuran_baju}
            // value={type === "edit" ? editData?.ukuran_baju : undefined}
            data={sizeBajuKoko}
          />

          <InputCommon
            type="common"
            label="Nama Mahram"
            placeholder="Nama Mahram Jamaah"
            id="nama_mahram"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.nama_mahram}
            // value={
            //   type === "edit"
            //     ? editData?.nama_mahram !== "null"
            //       ? editData?.nama_mahram
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="select"
            label="Pergi Haji"
            placeholder="Pergi Haji Jamaah"
            id="nama_mahram"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.pergi_haji}
            data={[
              { id: "1", name: "Sudah" },
              { id: "0", name: "Belum" },
            ]}
            onClick={() => null}

            // value={
            //   type === "edit"
            //     ? editData?.nama_mahram !== "null"
            //       ? editData?.nama_mahram
            //       : undefined
            //     : undefined
            // }
          />

          <InputCommon
            type="file"
            label="Pas Foto"
            placeholder="Pas Foto Jamaah"
            id="pasfoto"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.pasfoto}
          />
          {/* <div className="default-user">
              {imagePreview ? (
                <CImage src={imagePreview} />
              ) : editData?.pasfoto === undefined ||
                editData?.pasfoto === null ||
                editData?.pasfoto === "null" ? (
                <div
                  style={{ height: "140px" }}
                  className="p-4 d-flex justify-content-center align-items-center"
                >
                  <CIcon
                    width={52}
                    height={52}
                    icon={cilImage}
                    customClassName="nav-icon"
                  />
                </div>
              ) : (
                <CImage src={`${STORAGE}${editData?.pasfoto}`} />
              )}
            </div> */}

          <InputCommon
            type="file"
            label="TTD Digital"
            placeholder="TTD Digital Jamaah"
            id="ttd_digital"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.ttd_digital}
          />
          {/* <div className="default-user"> */}
          {/* {editData?.ttd_digital ? (
                <CImage src={`${STORAGE}${editData?.ttd_digital}`} />
              ) : (
                <>
                  {signaturePreview ? (
                    <CImage src={signaturePreview} />
                  ) : (
                    <div
                      style={{ height: "140px" }}
                      className="p-4 d-flex justify-content-center align-items-center"
                    >
                      <CIcon
                        width={52}
                        height={52}
                        icon={cilImage}
                        customClassName="nav-icon"
                      />
                    </div>
                  )}
                </>
              )} */}
          {/* {signaturePreview ? (
                <CImage src={signaturePreview} />
              ) : editData?.ttd_digital === undefined ||
                editData?.ttd_digital === null ||
                editData?.ttd_digital === "null" ? (
                <div
                  style={{ height: "140px" }}
                  className="p-4 d-flex justify-content-center align-items-center"
                >
                  <CIcon
                    width={52}
                    height={52}
                    icon={cilImage}
                    customClassName="nav-icon"
                  />
                </div>
              ) : (
                <CImage src={`${STORAGE}${editData?.ttd_digital}`} />
              )}
            </div> */}

          <InputCommon
            type="common"
            label="No. KTP"
            placeholder="No. KTP Jamaah"
            id="no_ktp"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.no_ktp}
            // value={
            //   type === "edit"
            //     ? editData?.no_ktp !== "null"
            //       ? editData?.no_ktp
            //       : undefined
            //     : undefined
            // }
          />
          <InputCommon
            type="file"
            label="Foto KTP"
            placeholder="Foto KTP Jamaah"
            id="foto_ktp"
            onChange={(e) => handleChangeFamilyData(e)}
            value={payloadDataCreateUser?.foto_ktp}
            // value={type === "edit" ? editData?.foto_ktp : undefined}
          />
          {/* <div className="default-user">
              {ktpPreview ? (
                <CImage src={ktpPreview} />
              ) : editData?.foto_ktp === undefined ||
                editData?.foto_ktp === null ||
                editData?.foto_ktp === "null" ? (
                <div
                  style={{ height: "140px" }}
                  className="p-4 d-flex justify-content-center align-items-center"
                >
                  <CIcon
                    width={52}
                    height={52}
                    icon={cilImage}
                    customClassName="nav-icon"
                  />
                </div>
              ) : (
                <CImage src={`${STORAGE}${editData?.foto_ktp}`} />
              )}
            </div> */}
        </div>
      )}
    </div>
  );
};
