import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import WebLayout from "../../Layout/WebLayout";
import { UserServices } from "../../../http/UserHttp";
import { AuthServices } from "../../../http/AuthHttp";
import React, { useEffect, useState } from "react";
import styles from "./../../../styles/Profile.module.scss";
import NavigationProfile from "../NavigationProfile";
import PrivateInformation from "../PrivateInformation";
import HistoryTrx from "../HistoryTrx";
import BankAccountList from "../BankAccountList";
import Wishlist from "../Wishlist";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import InformationUmroh from "../InformationUmroh";
import { JamaahServices } from "../../../http/JamaahHttp";
import { itineraryData } from "../../../common/dataDummy.js";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import FormInput from "../ProfileMobile/FormInput.js";
import ChangePassword from "../ChangePassword.js";

export default function AProfileDesktop(props) {
  const { isMobile } = props;
  const imgDate =
    "https://icons.veryicon.com/png/o/miscellaneous/esgcc-basic-icon-library/date-71.png";
  const departureDate =
    "https://cdn.icon-icons.com/icons2/1369/PNG/512/-flight-takeoff_90329.png";
  const arrivalDate =
    "https://cdn.icon-icons.com/icons2/3260/PNG/512/flight_land_airplane_plane_icon_206577.png";
  const navigate = useNavigate();
  const currUrl = useLocation().search.split("=");
  let dataUser = localStorage.getItem("riyuz-auth");
  dataUser = dataUser && JSON.parse(dataUser);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState();
  const [data, setData] = useState();
  const [currMenu, setCurrMenu] = useState("personal");
  const [batchList, setBatchList] = useState(null);
  const [batchDetail, setBatchDetail] = useState(null);
  const [detailForm, setDetailForm] = useState(false);
  const [umrohBatchId, setUmrohBatchId] = useState();
  console.log(batchDetail, umrohBatchId);
  useEffect(() => {
    if (currMenu !== "my-packages") {
      getData();
    }
    if (currMenu === "history") {
      getHistoryBookData();
    }
    if (currMenu === "wishlist") {
      getWishlistData();
    }
  }, [currMenu]);

  useEffect(() => {
    if (
      currMenu === "my-packages" ||
      currMenu === "my-equipment" ||
      currMenu === "my-retail" ||
      currMenu === "my-trip"
    ) {
      if (data?.data) {
        getPilgrimData("batch", `?user_id=${dataUser.id}`);
      }
    }
  }, [currMenu, data]);

  useEffect(() => {
    if (currMenu !== "my-packages") {
      // setDetailForm(false);
    }
  }, [currMenu]);

  useEffect(() => {
    // setCurrMenu(menuActive);
    if (currUrl[1]) {
      setCurrMenu(currUrl[1]);
    } else {
      setCurrMenu("personal");
    }
  }, [currUrl]);

  const handleNavigateMenu = (menu) => {
    setDetailForm(false);
    setCurrMenu(menu);
    navigate(`?menu=${menu}`);
  };

  const getHistoryBookData = async () => {
    setIsLoading(true);
    try {
      const response = await UserServices.getHistoryBook();
      if (response) {
        setIsLoading(false);
        setDataProfile(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getWishlistData = async () => {
    setIsLoading(true);
    try {
      const response = await UserServices.getWishlist();
      if (response) {
        setIsLoading(false);
        setDataProfile(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getPilgrimData = async (type, param) => {
    setIsLoading(true);
    try {
      const response = await JamaahServices.getListJamaah("", param);
      if (response) {
        setIsLoading(false);
        if (type === "batch") {
          setBatchList(response.data?.data);
        } else {
          if (response.data?.data?.length > 0) {
            console.log(response.data?.data[0], "<===");
            setBatchDetail(response.data?.data[0]);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getData = async () => {
    let data = localStorage.getItem("riyuz-auth");
    data = data && JSON.parse(data);
    setIsLoading(true);
    try {
      const response = await AuthServices.getMe(`?id=${data.id}`);
      if (response) {
        setIsLoading(false);
        setData(response);
        console.log(response);
      }
    } catch (err) {
      localStorage.removeItem("riyuz-auth");
      navigate("/");
      setIsLoading(false);
    }
  };

  return (
    <WebLayout type="hide" color="dark" isLoading={isLoading}>
      <Container className={styles.profileDesktop}>
        {data?.code === "need_update_pw" ? (
          <Row
            className="justify-content-center"
            style={{ paddingTop: "124px" }}
          >
            <Col lg={8}>
              <ChangePassword setIsLoading={setIsLoading} />
            </Col>
          </Row>
        ) : (
          data?.data && (
            <>
              <Row>
                {isMobile ? (
                  ""
                ) : (
                  <NavigationProfile
                    handleNavigateMenu={handleNavigateMenu}
                    data={data?.data}
                    currMenu={currMenu}
                  />
                )}
                {currMenu === "personal" && (
                  <PrivateInformation
                    isMobile={isMobile}
                    data={data?.data}
                    setIsLoading={setIsLoading}
                  />
                )}
                {currMenu === "my-trip" && (
                  <>
                    {detailForm ? (
                      <>
                        {/* {batchDetail && umrohBatchId && (
                        <>
                          <InformationUmroh
                            isMobile={isMobile}
                            data={batchDetail}
                            setIsLoading={setIsLoading}
                            typeMenu="trip"
                            getData={() =>
                              getPilgrimData(
                                "detail",
                                `?user_id=${dataUser.id}&umroh_batch_id=${umrohBatchId}`
                              )
                            }
                          />
                        </>
                      )} */}
                        <Col lg={8}>
                          <div
                            className={`${styles.menuShow} d-flex justify-content-between align-items-center`}
                          >
                            <h1 className="mb-4 mb-md-3">Itinerary</h1>
                            <div>Muthawwif: Rahmad Al Habbib</div>
                          </div>
                          <div className="mt-3">
                            <Row>
                              <Col lg={6} className="mb-3">
                                <Timeline
                                  sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}
                                >
                                  <TimelineItem>
                                    {/* <TimelineOppositeContent
                                    sx={{ m: "auto 0" }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    9:30 am
                                  </TimelineOppositeContent> */}
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="info">
                                        <LocalAirportIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                                      <Typography variant="h6" component="span">
                                        Garuda Indonesia
                                      </Typography>
                                      <Typography>
                                        CGK: 13 November 2023 06:00
                                      </Typography>
                                      <Typography></Typography>
                                    </TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="warning">
                                        <DirectionsBusIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                                      {/* <Typography variant="h6" component="span">
                                      Perjalanan Menuju Madinah
                                    </Typography>
                                    <Typography>
                                      18 November 2023 06:00
                                    </Typography> */}
                                    </TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="success">
                                        <HotelIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                                      <Typography variant="h6" component="span">
                                        Movenpick Makkah
                                      </Typography>
                                      <Typography>
                                        14 November 2023 11:00
                                      </Typography>
                                    </TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="warning">
                                        <DirectionsBusIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent
                                      sx={{ py: "12px", px: 2 }}
                                    ></TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="success">
                                        <HotelIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                                      <Typography variant="h6" component="span">
                                        Movenpick Madinah
                                      </Typography>
                                      <Typography>
                                        18 November 2023 12:00
                                      </Typography>
                                    </TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="warning">
                                        <DirectionsBusIcon />
                                      </TimelineDot>
                                      <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent
                                      sx={{ py: "12px", px: 2 }}
                                    ></TimelineContent>
                                  </TimelineItem>
                                  <TimelineItem>
                                    <TimelineSeparator>
                                      {/* Every icon have their color */}
                                      <TimelineDot color="info">
                                        <LocalAirportIcon />
                                      </TimelineDot>
                                      {/* Remove connector when reaching out itineraryData */}
                                      {/* <TimelineConnector /> */}
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                                      <Typography variant="h6" component="span">
                                        Garuda Indonesia
                                      </Typography>
                                      <Typography>
                                        JED: 22 November 2023 06:00
                                      </Typography>
                                      <Typography>
                                        CGK: 23 November 2023 06:00
                                      </Typography>
                                    </TimelineContent>
                                  </TimelineItem>
                                </Timeline>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Timeline
                                  sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}
                                >
                                  {itineraryData.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <div
                                        style={{
                                          paddingTop: 20 + "px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.tanggal}
                                      </div>
                                      {item.jadwal.map((schedule, subIndex) => (
                                        <TimelineItem>
                                          <TimelineSeparator>
                                            <TimelineDot color="info" />
                                            <TimelineConnector />
                                          </TimelineSeparator>
                                          <TimelineContent
                                            sx={{ py: "12px", px: 2 }}
                                          >
                                            <Typography
                                              key={subIndex}
                                              variant="h6"
                                              fontSize={16}
                                              fontWeight={"bold"}
                                              component="span"
                                            >
                                              {schedule.jam}
                                            </Typography>
                                            <Typography>
                                              {schedule.kegiatan}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </React.Fragment>
                                  ))}
                                </Timeline>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col lg={8}>
                        <div className={styles.menuShow}>
                          <h1 className="mb-4 mb-md-3">
                            List Kloter / Kode Booking
                          </h1>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Kloter</th>
                              <th>Kode Booking</th>
                              <th>Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {batchList?.length > 0 ? (
                              <>
                                {batchList.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.umroh_batch_id.name}</td>
                                      <td>
                                        {item.booking_code == "null" ||
                                        item.booking_code == null
                                          ? "-"
                                          : item.booking_code}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            getPilgrimData(
                                              "detail",
                                              `?user_id=${dataUser.id}&umroh_batch_id=${item.umroh_batch_id?.id}`
                                            );
                                            setUmrohBatchId(
                                              item.umroh_batch_id?.id
                                            );
                                            setDetailForm(true);
                                          }}
                                        >
                                          Detail
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={3}>
                                  <div className="py-2 text-center">
                                    Tidak Ada Kloter
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </>
                )}
                {currMenu === "my-packages" && (
                  <>
                    {detailForm ? (
                      batchDetail &&
                      umrohBatchId && (
                        <>
                          <InformationUmroh
                            isMobile={isMobile}
                            data={batchDetail}
                            setIsLoading={setIsLoading}
                            typeMenu="my-packages"
                            getData={() =>
                              getPilgrimData(
                                "detail",
                                `?user_id=${dataUser.id}&umroh_batch_id=${umrohBatchId}`
                              )
                            }
                          />
                        </>
                      )
                    ) : (
                      <Col lg={8}>
                        <div className={styles.menuShow}>
                          <h1 className="mb-4 mb-md-3">
                            List Kloter / Kode Booking
                          </h1>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Kloter</th>
                              <th>Kode Booking</th>
                              <th>Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {batchList?.length > 0 ? (
                              <>
                                {batchList.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.umroh_batch_id.name}</td>
                                      <td>
                                        {item.booking_code == "null" ||
                                        item.booking_code == null
                                          ? "-"
                                          : item.booking_code}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            getPilgrimData(
                                              "detail",
                                              `?user_id=${dataUser.id}&umroh_batch_id=${item.umroh_batch_id?.id}`
                                            );
                                            setUmrohBatchId(
                                              item.umroh_batch_id?.id
                                            );
                                            setDetailForm(true);
                                          }}
                                        >
                                          Detail
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={3}>
                                  <div className="py-2 text-center">
                                    Tidak Ada Kloter
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </>
                )}
                {currMenu === "my-equipment" && (
                  <>
                    {detailForm ? (
                      batchDetail &&
                      umrohBatchId && (
                        <>
                          <InformationUmroh
                            isMobile={isMobile}
                            data={batchDetail}
                            typeMenu="equipment"
                            setIsLoading={setIsLoading}
                            getData={() =>
                              getPilgrimData(
                                "detail",
                                `?user_id=${dataUser.id}&umroh_batch_id=${umrohBatchId}`
                              )
                            }
                          />
                        </>
                      )
                    ) : (
                      <Col lg={8}>
                        <div className={styles.menuShow}>
                          <h1 className="mb-4 mb-md-3">
                            List Kloter / Kode Booking
                          </h1>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Kloter</th>
                              <th>Kode Booking</th>
                              <th>Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {batchList?.length > 0 ? (
                              <>
                                {batchList.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.umroh_batch_id.name}</td>
                                      <td>
                                        {item.booking_code == "null" ||
                                        item.booking_code == null
                                          ? "-"
                                          : item.booking_code}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            getPilgrimData(
                                              "detail",
                                              `?user_id=${dataUser.id}&umroh_batch_id=${item.umroh_batch_id?.id}`
                                            );
                                            setUmrohBatchId(
                                              item.umroh_batch_id?.id
                                            );
                                            setDetailForm(true);
                                          }}
                                        >
                                          Detail
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={3}>
                                  <div className="py-2 text-center">
                                    Tidak Ada Kloter
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </>
                )}
                {currMenu === "my-retail" && (
                  <>
                    {detailForm ? (
                      batchDetail &&
                      umrohBatchId && (
                        <>
                          <InformationUmroh
                            isMobile={isMobile}
                            data={batchDetail}
                            setIsLoading={setIsLoading}
                            typeMenu="retail"
                            getData={() =>
                              getPilgrimData(
                                "detail",
                                `?user_id=${dataUser.id}&umroh_batch_id=${umrohBatchId}`
                              )
                            }
                          />
                        </>
                      )
                    ) : (
                      <Col lg={8}>
                        <div className={styles.menuShow}>
                          <h1 className="mb-4 mb-md-3">
                            List Kloter / Kode Booking
                          </h1>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Kloter</th>
                              <th>Kode Booking</th>
                              <th>Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {batchList?.length > 0 ? (
                              <>
                                {batchList.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.umroh_batch_id.name}</td>
                                      <td>
                                        {item.booking_code == "null" ||
                                        item.booking_code == null
                                          ? "-"
                                          : item.booking_code}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            getPilgrimData(
                                              "detail",
                                              `?user_id=${dataUser.id}&umroh_batch_id=${item.umroh_batch_id?.id}`
                                            );
                                            setUmrohBatchId(
                                              item.umroh_batch_id?.id
                                            );
                                            setDetailForm(true);
                                          }}
                                        >
                                          Detail
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={3}>
                                  <div className="py-2 text-center">
                                    Tidak Ada Kloter
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </>
                )}
                {currMenu === "wishlist" && dataProfile && (
                  <Wishlist isMobile={isMobile} data={dataProfile} />
                )}
                {currMenu === "history" && dataProfile && (
                  <HistoryTrx isMobile={isMobile} data={dataProfile} />
                )}
                {currMenu === "bank" && (
                  <BankAccountList isMobile={isMobile} data={data?.data} />
                )}
              </Row>
            </>
          )
        )}
      </Container>
    </WebLayout>
  );
}
