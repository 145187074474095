import { Image } from "react-bootstrap";
import styles from "./../../styles/DetailProduct.module.scss";
import check from "./../../assets/tick-circle.svg";
import uncheck from "./../../assets/times.svg";

export default function IncludeExclude(props) {
  const { detail, type } = props;
  return (
    <div className={styles.contentBox}>
      <div className={styles.heading}>Fasilitas</div>
      <div className={styles.content}>
        <div className="row">
          <div className="col-12 col-lg-6">
            {type === "tour"
              ? detail.include?.length > 0 &&
                detail.include?.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.checkList}>
                      <Image src={check} className="me-2" />
                      {item.title}
                    </div>
                  );
                })
              : Object.keys(detail.include)?.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.checkList}>
                      <Image src={check} className="me-2" />
                      {detail.include[item].title}
                    </div>
                  );
                })}
          </div>
          <div className="col-12 col-lg-6">
            {type === "tour"
              ? detail.exclude?.length > 0 &&
                detail.exclude?.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.checkList}>
                      <Image src={uncheck} className="me-2" />
                      {item.title}
                    </div>
                  );
                })
              : Object.keys(detail.exclude)?.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.checkList}>
                      <Image src={uncheck} className="me-2" />
                      {detail.exclude[item].title}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}
