import { Image } from "react-bootstrap";
import { iconArrive, iconBus, iconDeparture } from "../../constant/data";

export default function FlightInformation(props) {
  const { dataBook, airline, arrival, departure } = props;
  return (
    <>
      {dataBook?.length > 0 &&
        dataBook.map((item, idx) => {
          return (
            <div className="d-flex justify-content-between mb-3">
              <div>{item.seat_type.name}</div>
              <div>x {item.qty}</div>
            </div>
          );
        })}
      <div className="mt-4">
        <b>Maskapai</b>
      </div>
      <div className="mt-2">{airline}</div>
      <div className="mt-4">
        <b>Informasi Waktu</b>
      </div>
      <div className="mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Image className="me-2" src={iconDeparture} width={24} height={24} />
          <span>{departure}</span>
        </div>
        <div className="d-flex align-items-center">
          <Image className="me-2" src={iconArrive} width={24} height={24} />
          <span>{arrival}</span>
        </div>
      </div>
    </>
  );
}
