import { riyuzAPIV2 } from "../constant/api";

export const AddonServices = {
  postAddOn(payload) {
    return riyuzAPIV2.post(`addon-categories`, payload);
  },
  getAddOn(id, param) {
    return id
      ? riyuzAPIV2.get(`addon-categories/${id}`)
      : riyuzAPIV2.get(`addon-categories${param ? param : ""}`);
  },
  putAddOn(payload) {
    return riyuzAPIV2.put(`addon-categories/${payload.id}`, payload.body);
  },
  deleteAddOn(id) {
    return riyuzAPIV2.delete(`addon-categories/${id}`);
  },
  // Add On
  postAddOnItem(payload) {
    return riyuzAPIV2.post(`addons`, payload);
  },
  getAddOnsList(id, param) {
    return id
      ? riyuzAPIV2.get(`addons/${id}`)
      : riyuzAPIV2.get(`addons${param ? param : ""}`);
  },
  putAddOnSelected(payload) {
    return riyuzAPIV2.put(`addons/${payload.id}`, payload.body);
  },
  deleteAddOnSelected(id) {
    return riyuzAPIV2.delete(`umroh-pilgrim-addons/${id}`);
  },
};
