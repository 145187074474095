import { riyuzAPI, riyuzAPIV2 } from "../constant/api";

export const JamaahServices = {
  postJamaah(payload) {
    return riyuzAPIV2.post(`booking-umroh-pilgrims`, payload);
  },
  getUmrohBatches(param) {
    return riyuzAPI.get(`umroh-batches${param ? param : ""}`);
  },
  getUmrohGroups(payload) {
    return riyuzAPI.get(`umroh-groups${payload ? payload : ""}`);
  },
  getListJamaah(id, param) {
    return id
      ? riyuzAPI.get(`booking-umroh-pilgrims/${id}`)
      : riyuzAPI.get(`booking-umroh-pilgrims${param ? param : ""}`);
  },
  getListBusUmroh(id, param) {
    return id
      ? riyuzAPI.get(`umroh-buses/${id ? id : ""}`)
      : riyuzAPI.get(`umroh-buses${param ? param : ""}`);
  },
  getListFlightUmroh(id, param) {
    return id
      ? riyuzAPI.get(`umroh-flights/${id}`)
      : riyuzAPI.get(`umroh-flights${param ? param : ""}`);
  },
  getRooms(id, param) {
    return id
      ? riyuzAPI.get(`umroh-room-lists/${id}`)
      : riyuzAPI.get(`umroh-room-lists${param ? param : ""}`);
  },
  putJamaah(payload) {
    return riyuzAPIV2.post(
      `booking-umroh-pilgrims/${payload.id}?_method=PUT`,
      payload.body
    );
  },

  getFamily(param) {
    return riyuzAPIV2.get(`family${param ? param : ""}`);
  },

  putChangeRoom(payload) {
    return riyuzAPI.put(
      `umroh-pilgrim-change-room/${payload.id}`,
      payload.body
    );
  },
  putChangeBus(payload) {
    return riyuzAPI.put(`umroh-pilgrim-change-bus/${payload.id}`, payload.body);
  },
  deleteJamaah(id) {
    return riyuzAPI.delete(`booking-umroh-pilgrims/${id}`);
  },

  // Add On Jamaah
  getListAddonJamaah(id, param) {
    return id
      ? riyuzAPI.get(`umroh_pilgrim_addons/${id}`)
      : riyuzAPI.get(`umroh_pilgrim_addons${param ? param : ""}`);
  },

  putAddOnPilgrimsSelected(payload) {
    return riyuzAPI.put(`umroh-pilgrim-addons/${payload.id}`, payload.body);
  },
  getCorporatePackages(id, param) {
    return id
      ? riyuzAPI.get(`corporate-packages/${id}`)
      : riyuzAPI.get(`corporate-packages${param ? param : ""}`);
  },
};
