import { Col, Container, Image, Row } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import styles from "./../styles/Search.module.scss";
import CardProject from "../components/Card";
import { PlaceServices } from "../http/PlaceHttp";
import { useEffect, useState } from "react";
import Pagination from "../components/common/Pagination";
import { useLocation } from "react-router-dom";
import filter from "./../../src/assets/filter-add.svg";
import RoomSearch from "../components/Search/Hotel/RoomSearch";
import { isMobileCheck } from "../utils";
import moment from "moment";
import { useParams } from 'react-router-dom';

export default function RoomHotel() {
  const size = isMobileCheck() ? 10 : 9;
  const router = useLocation();
  const typeFilter = router.search.split("=") && router.search.split("=")[1];
  const { id } = useParams();
  const initialParams = `${id}`; 
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [starRate, setStarRate] = useState([]);
  const [rating, setRating] = useState([]);
  const [terms, setTerms] = useState([]);
  const [tourType, setTourType] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [priceFilter, setPriceFilter] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [tourParam, setTourParam] = useState();
  const [priceParam, setPriceParam] = useState();
  const [styleParam, setStyleParam] = useState();
  const [ratingParam, setRatingParam] = useState();
  const [multipleParam, setMultipleParam] = useState();
  // const [facilitiesParam, setFacilitiesParam] = useState();

  useEffect(() => {
    getListServices(params, typeFilter);
  }, [typeFilter, params]);

   useEffect(() => {
    // multiple star rate
    if (
      selectedLocation ||
      tourParam ||
      priceParam ||
      styleParam ||
      ratingParam ||
      starRate ||
      startDate ||
      endDate
    ) {
      let datePick = `&start_date=${moment(startDate).format(
        "MM/DD/YYYY"
      )}&end_date=${moment(endDate).format("MM/DD/YYYY")}`;
      let paramMultiple = `${params}${
        selectedLocation ? selectedLocation : ""
      }${tourParam ? tourParam : ""}${priceParam ? priceParam : ""}${
        styleParam ? styleParam : ""
      }${starRate ? starRate : ""}${ratingParam ? ratingParam : ""}${
        startDate && endDate ? datePick : ""
      }`;
      getListServices(paramMultiple, typeFilter);
    }
  }, [
    starRate,
    selectedLocation,
    tourParam,
    priceParam,
    styleParam,
    ratingParam,
    typeFilter,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setFilterParams(terms, setStyleParam);
    setFilterParams(tourType, setTourParam);
    setFilterParams(rating, setRatingParam);
    // setFilterParams(starRate, setStarRate);
  }, [terms, tourType, rating, starRate]);

  const handlePagination = (page) => {
    let param = `?limit=${size}&page=${page}`;
    setParams(param);
    setCurrentPage(page);
    getListServices(param, typeFilter);
  };

  const handleChange = (e, type, id) => {
    if (type === "price") {
      setParamsMultiple(priceFilter, setPriceFilter, e, ``, false);
    } else if (type === "rating") {
      setParamsMultiple(rating, setRating, id, `&review_score%5B%5D`, true);
    } else if (type === "style_travel") {
      setParamsMultiple(terms, setTerms, id, `&terms%5B%5D`, true);
    } else if (type === "star_rate") {
      setParamsMultiple(starRate, setStarRate, id, `&star_rate%5B%5D`, true);
    } else {
      setParamsMultiple(tourType, setTourType, id, `&cat_id`, true);
    }
  };


  const getListServices = async (id) => {
    setIsLoading(true);
    try {
      const param = ''
      let response;
        response = await PlaceServices.roomHotelList(id, param);
      if (response) {
        setIsLoading(false);
        setData(response);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const orderBy = (e) => {
    let param = `?${params}&orderby=${e.target.value}`;
    getListServices(param);
  };

  

  const handleShowFilter = (e) => {
    e.preventDefault();
    setShowFilter(!showFilter);
  };

  return (
    <WebLayout color="dark" isLoading={isLoading}>
      <Container>
        <Row>
          <div className={styles.searchWrap}>
            {!isMobileCheck() && (
              <Col lg={3}>
                  <RoomSearch
                    locationList={locationList}
                    priceFilter={priceFilter}
                    setPriceParam={setPriceParam}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                  />
              </Col>
            )}
            <Col sm={12} lg={8} className="offset-lg-1">
              <div className={styles.sortBox}>
                <div onClick={handleShowFilter} className={styles.addFilter}>
                  <Image src={filter} />
                  Filter
                </div>
                <div className={styles.addSort}>
                  <label>Urutkan</label>
                  <select onChange={orderBy} className="form-select">
                    <option value="" defaultValue>
                      Rekomendasi
                    </option>
                    <option value="price_low_high">Harga Terendah</option>
                    <option value="price_high_low">Harga Tertinggi</option>
                    <option value="rate_high_low">Rating Tertinggi</option>
                  </select>
                </div>
              </div>
              {showFilter && (
                // FILTER MOBILE
                <>
                  {/* ROOM */}
                    <RoomSearch
                      locationList={locationList}
                      priceFilter={priceFilter}
                      setPriceParam={setPriceParam}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      handleChange={handleChange}
                    />
                </>
              )}

              {data?.rooms?.length > 0 ? (
                data?.rooms && (
                  <>
                    <Row className={styles.listWrap}>
                      {data.rooms.map((item, index) => {
                        return (
                          <Col
                            lg={4}
                            md={6}
                            sm={6}
                            xs={6}
                            key={index}
                            className={styles.listItem}
                          >
                            <CardProject item={item} />
                          </Col>
                        );
                      })}
                    </Row>
                    <Pagination
                      totalPages={data.total_pages}
                      handlePagination={handlePagination}
                      currentPage={currentPage}
                    />
                  </>
                )
              ) : (
                <div className="text-center">Tidak Ada Data!!!!</div>
              )}
            </Col>
          </div>
        </Row>
      </Container>
    </WebLayout>
  );
}

function setParamsMultiple(state, setState, id, param, multiple) {
  if (multiple) {
    let newData = [...state];
    if (newData.includes(`${param}=${id}`)) {
      newData = newData.filter((item) => item !== `${param}=${id}`);
    } else {
      newData.push(`${param}=${id}`);
    }
    setState(newData);
  } else {
    const newData = { ...state };
    newData[id.target.id] = id.target.value;
    setState(newData);
  }
}

function setFilterParams(data, setState) {
  let tmp = data.map((item) => `${item}`).join("");
  setState(`${tmp}`);
  // getListServices(send, typeFilter);
}
