import { Image } from "react-bootstrap";
import styles from "./../styles/Cart.module.scss";
import userGray from "./../assets/Cart/user-gray.svg";
import thumbnail from "./../assets/Cart/item.jpg";
import plus from "./../assets/Cart/plus.svg";
import minus from "./../assets/Cart/minus.svg";
import trash from "./../assets/Cart/trash.svg";
export default function ListCard(props) {
  const { type } = props;
  return (
    <div className={styles.listCart}>
      <div className={styles.thumbnailWrap}>
        <Image src={thumbnail} alt="" />
      </div>
      <div className={styles.detailCart}>
        <div className={styles.title}>Paket Umroh</div>
        <div className={styles.much}>
          <Image src={userGray} />
          <div>2 Orang</div>
        </div>
        <div className={styles.priceWrap}>
          <div className={styles.discount}>30%</div>
          <div className={styles.regulerPrice}>Rp 7.800.000</div>
          <div className={styles.salePrice}>Rp 7.000.000</div>
        </div>
        <div className={styles.countWrap}>
          <div className={styles.minus}>
            <Image src={minus} className={styles.icon} />
          </div>
          <div className={styles.count}>1</div>
          <div className={styles.plus}>
            <Image src={plus} className={styles.icon} />
          </div>
        </div>
        <Image src={trash} className={styles.trash} />
      </div>
    </div>
  );
}
