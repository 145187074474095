import { Splide, SplideSlide } from "@splidejs/react-splide";
import CardProject from "../Card";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css";
import Skeleton from "react-loading-skeleton";

export default function SlideCardMobile(props) {
  const { data, type, isLoading } = props;
  return (
    <Splide
      options={{
        type: "loop",
        perPage: 1,
        pagination: false,
        gap: "1rem",
        fixedWidth: "calc(100% - 84px)",
        perMove: 1,
      }}
    >
      {data?.length === 0 &&
        isLoading &&
        new Array(4).fill(0).map((item, index) => {
          return (
            <SplideSlide key={index}>
              {/* <CardProject item={e} type={type} /> */}
              <Skeleton height={230} borderRadius={10} className="mb-2" />
              <Skeleton height={22} className="mb-1" count={3} />
            </SplideSlide>
          );
        })}
      {data &&
        data?.length > 0 &&
        data.map((e, i) => {
          return (
            <SplideSlide key={i}>
              <CardProject item={e} type={type} />
            </SplideSlide>
          );
        })}
    </Splide>
  );
}
