import { riyuzAPI, riyuzAPIV2 } from "../constant/api";

export const UserServices = {
  getHistoryBook() {
    return riyuzAPI.get(`user/booking-history`);
  },
  getUser(param ) {
    return riyuzAPI.get(`users${param ? param : ""}`);
  },
  postWishlist(payload) {
    return riyuzAPI.post(`user/wishlist`, payload);
  },
  getWishlist() {
    return riyuzAPI.get(`user/wishlist`);
  },
  postDeletePermanent() {
    return riyuzAPI.post(`user/permanently-delete`);
  },
  getCorporatePackages(id, param) {
    return id
      ? riyuzAPI.get(`corporate-packages${id ? `/${id}` : ""}`)
      : riyuzAPI.get(`corporate-packages${param ? param : ""}`);
  },
  checkPhoneNumber(payload) {
    return riyuzAPIV2.get(`checknohp`, {
      params: {
        nohp: payload,
      },
    });
  },
};
