import { Col, Image } from "react-bootstrap";
import styles from "./../../styles/DetailProduct.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import star from "./../../assets/Home/star.svg";
import receipt from "./../../assets/receipt.svg";
import receiptBlack from "./../../assets/receipt-black.svg";
import receiptSquare from "./../../assets/receipt-square.svg";
import share from "./../../assets/share.svg";
import location from "./../../assets/location.svg";
import airplane from "./../../assets/airplane.svg";
import clock from "./../../assets/clock.svg";
import profile from "./../../assets/profile.svg";
import check from "./../../assets/tick-circle.svg";
import wallet from "./../../assets/empty-wallet.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrowLeft from "./../../assets/Profile/arrow-left-white.svg";
import { formatRupiah } from "../../constant/lib";

export default function StickyInformation(props) {
  const currUrl = useParams();
  const {
    detail,
    isLogin,
    checkoutPackages,
    handleAdd,
    count,
    addToCart,
    handleAddGuest,
    adultGuest,
    childGuest,
    startDate,
    endDate,
    checkRoom,
    setStartDate,
    setEndDate,
    onChange,
  } = props;
  const navigate = useNavigate();

  return (
    <Col lg={4} className={styles.summary}>
      <div className={styles.summaryWrap}>
        {currUrl.package === "tour" && (
          <div className={`${styles.iconBox} mb-2 mb-md-4`}>
            <div className={styles.titleIconBox}>
              <Image src={clock} alt="" />
              Durasi Keberangkatan
            </div>
            <div className={styles.detailIconBox}>
              {detail.duration ? detail.duration : "-"}
            </div>
          </div>
        )}
        {currUrl.package === "hotel" && (
          <div className={`${styles.iconBox} mb-2 mb-md-4`}>
            <div className={styles.titleIconBox}>
              <Image src={clock} alt="" />
              Check-In / Check-Out
            </div>
            <div className={styles.detailIconBox}>
              {detail.check_in_time} {"/"} {detail.check_out_time}
            </div>
          </div>
        )}
        {currUrl.package !== "flight" && currUrl.package !== "visa" && (
          <div className={`${styles.iconBox} mb-2 mb-md-4`}>
            <div className={styles.titleIconBox}>
              <Image src={location} alt="" />
              Lokasi Keberangkatan
            </div>
            <div className={styles.detailIconBox}>
              <>
                {detail.location?.name
                  ? detail.location?.name
                  : detail?.location}
              </>
            </div>
          </div>
        )}
        {currUrl.package === "flight" && (
          <>
            <div className={`${styles.iconBox} mb-2 mb-md-4`}>
              <div className={styles.titleIconBox}>
                <Image src={airplane} alt="" />
                Maskapai Penerbangan
              </div>
              <div className={styles.detailIconBox}>{detail.airline.name}</div>
            </div>
            <div className={`${styles.iconBox} mb-2 mb-md-4`}>
              <div className={styles.titleIconBox}>
                <Image src={airplane} alt="" />
                Waktu Keberangkatan
              </div>
              <div className={styles.detailIconBox}>
                {detail.departure_date_html} - {detail.departure_time_html}
              </div>
            </div>
            <div className={`${styles.iconBox} mb-2 mb-md-4`}>
              <div className={styles.titleIconBox}>
                <Image src={airplane} alt="" />
                Waktu Tiba
              </div>
              <div className={styles.detailIconBox}>
                {detail.arrival_date_html} - {detail.arrival_time_html}
              </div>
            </div>
          </>
        )}

        {currUrl.package === "visa" && (
          <div className={`w-100 mb-4`}>
            <div className={`${styles.titleQuantityOrder} mb-3`}>
              Pilih Tanggal
            </div>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        )}

        {currUrl.package != "visa" && <hr class="solid"></hr>}
        {isLogin ? (
          <>
            {currUrl.package == "hotel" ? (
              <>
                <div className={`${styles.quantityWrap} mt-4 mb-5`}>
                  <div className={`${styles.quantityOrder} mb-2 mb-md-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      <Image src={profile} alt="" />
                      Tamu Dewasa
                    </div>
                    <div className={styles.detailQuantityOrder}>
                      <div
                        className={`${styles.btnInput} me-3`}
                        onClick={() => handleAddGuest("decrement", "adult")}
                      >
                        -
                      </div>
                      <input className={styles.qty} value={adultGuest} />
                      <div
                        className={`${styles.btnInput} ms-3`}
                        onClick={() => handleAddGuest("increment", "adult")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.quantityOrder} mb-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      <Image src={profile} alt="" />
                      Tamu Anak-Anak
                    </div>
                    <div className={styles.detailQuantityOrder}>
                      <div
                        className={`${styles.btnInput} me-3`}
                        onClick={() => handleAddGuest("decrement", "child")}
                      >
                        -
                      </div>
                      <input className={styles.qty} value={childGuest} />
                      <div
                        className={`${styles.btnInput} ms-3`}
                        onClick={() => handleAddGuest("increment", "child")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className={` mb-4`}>
                    <div className={`${styles.titleQuantityOrder} mb-3`}>
                      Check In & Check Out
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                    />
                  </div>
                </div>
                <div>
                  <div
                    onClick={(e) => checkRoom(e, detail.id)}
                    className={`btnYellow mb-4 w-100 ${styles.addToCart}`}
                  >
                    Cek Ketersediaan Room
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={`${styles.quantityWrap} mt-4 mb-5`}>
                  <div className={styles.quantityOrder}>
                    <div className={styles.titleQuantityOrder}>
                      <Image src={profile} alt="" />
                      Jumlah Guest
                    </div>
                    <div className={styles.detailQuantityOrder}>
                      <div
                        className={`${styles.btnInput} me-3`}
                        onClick={() => handleAdd("decrement")}
                      >
                        -
                      </div>
                      <input className={styles.qty} value={count} />
                      <div
                        className={`${styles.btnInput} ms-3`}
                        onClick={() => handleAdd("increment")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className={styles.totalBox}>
                    <div>
                      <Image src={wallet} alt="" />
                      Subtotal
                    </div>
                    <div className={styles.total}>
                      {currUrl.package !== "flight" ? (
                        <>
                          {formatRupiah(
                            (detail.discount_percent !== null
                              ? detail.discount_percent
                              : detail.price) * count
                          )}
                        </>
                      ) : (
                        <>{formatRupiah(detail.min_price * count)}</>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  onClick={(e) => addToCart(e, detail)}
                  className={`btnYellow mb-4 w-100 ${styles.addToCart}`}
                >
                  + Keranjang
                </div>
                <div
                  onClick={checkoutPackages}
                  className={`btnYellow btnYellow__outline mb-3 w-100 ${styles.addToCart}`}
                >
                  Beli Paket
                </div>
              </>
            )}
          </>
        ) : (
          <div
            onClick={() => navigate("/login")}
            className={`btnYellow btnYellow__outline mt-4 mb-3 w-100 ${styles.addToCart}`}
          >
            Login untuk Memesan
          </div>
        )}
      </div>
    </Col>
  );
}
