import { useEffect, useState } from "react";
import styles from "./FilterSection.module.scss";
import { typeService } from "../../constant/variable";
import hotel from "./../../assets/icon/icon-hotel.svg";
import help from "./../../assets/icon/help.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
export default function FilterSection(props) {
  const { type, isMobile, resetField, isListData } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [typeRoute, setTypeRoute] = useState("One Way");
  const [typeFilter, setTypeFilter] = useState("Hotel");
  const [iconFilter, setIconFilter] = useState("tour");
  const [searchResult, setSearchResult] = useState(false);
  const [adult, setAdult] = useState(0);
  const [children, setChildren] = useState(0);
  const [room, setRoom] = useState(1);
  const [params, setParams] = useState({});
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [start, setStart] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (type.value === "hajj" || type.value === "umrah") {
      setData({
        ...data,
        "cat_id[]": type.value,
      });
    }
  }, [type]);

  useEffect(() => {
    if (data) {
      if (!data.start && !data.end) {
        setData({
          ...data,
          start: moment(new Date()).format("YYYY-MM-DD"),
          end: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
        });
      }
      console.log(data, "<===");
    }

    // if (type.value === "hajj" || type.value === "umrah") {
    //   setData({
    //     ...data,
    //     "cat_id[]": type.value,
    //   });
    // }
  }, [data]);

  useEffect(() => {
    if (type.value === "hotel") {
      setData({
        ...data,
        room: room,
        adults: adult,
        children: children,
      });
    }
  }, [type, room, adult, children]);

  useEffect(() => {
    if (data) {
      let convertParams = buildQueryString(data);
      setParams(convertParams);
    }
  }, [data]);

  const handlePlus = (type) => {
    if (type === "adult") {
      setAdult(adult + 1);
    } else if (type === "children") {
      setChildren(children + 1);
    } else if (type === "room") {
      setRoom(room + 1);
    }
  };

  const handleMinus = (type) => {
    if (type === "adult") {
      if (adult === 0) {
        setAdult(0);
      } else {
        setAdult(adult - 1);
      }
    } else if (type === "children") {
      if (children === 0) {
        setChildren(0);
      } else {
        setChildren(children - 1);
      }
    } else if (type === "room") {
      if (room === 0) {
        setRoom(0);
      } else {
        setRoom(room - 1);
      }
    }
  };

  const handleChange = (e) => {
    const newData = { ...data };
    if (e.target.name === "start" || e.target.name === "end") {
      if (e.target.name === "start") {
        setStart(moment(e.target.value).format("YYYY-MM-DD"));
      } else {
        setEnd(moment(e.target.value).format("YYYY-MM-DD"));
      }
      newData[e.target.name] = moment(e.target.value).format("MM/DD/YYYY");
    } else {
      newData[e.target.name] = e.target.value;
    }
    setData(newData);
  };
  const handleSelect = (type) => {
    setTypeFilter(type.title);
    setIconFilter(type.icon);
    setSearchResult(false);
  };
  const selectSearch = () => {
    setSearchResult(!searchResult);
  };
  const handleSelectTypeTour = (type) => {
    setTypeRoute(type);
  };

  const buildQueryString = (params) => {
    return Object.keys(params)
      .map((key) => {
        if (key === "cat_id[]") {
          return `${key}=${encodeURIComponent(params[key])}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
  };

  const typeForm = (type) => {
    switch (type) {
      case "hajj":
        return (
          <div className={styles.formSearch}>
            <div className={`${styles.fieldMain} mb-2`}>
              <div className="mb-2">
                <label>Embarkasi</label>
              </div>
              <div>
                <select
                  onChange={handleChange}
                  className={`${styles.select} w-100`}
                  name="location_id"
                  id="location_id"
                >
                  <option value="" hidden>
                    Pilih Lokasi Embarkasi
                  </option>
                </select>
              </div>
            </div>
            <div className={`${styles.fieldMain} d-flex`}>
              <div className={styles.fieldLeft}>
                <div className="mb-2">
                  <label>From</label>
                </div>
                <input
                  type="date"
                  name="start"
                  id="start"
                  value={start}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.fieldRight}>
                <div className="mb-2">
                  <label>To</label>
                </div>
                <input
                  type="date"
                  name="end"
                  id="end"
                  value={end}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case "umrah":
        return (
          <div className={styles.formSearch}>
            <div className={`${styles.fieldMain} mb-2`}>
              <div className="mb-2">
                <label>Embarkasi</label>
              </div>
              <div>
                <select
                  className={`${styles.select} w-100`}
                  name="location_id"
                  id="location_id"
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    Pilih Lokasi Embarkasi
                  </option>
                </select>
              </div>
            </div>
            <div className={`${styles.fieldMain} d-flex`}>
              <div className={styles.fieldLeft}>
                <div className="mb-2">
                  <label>From</label>
                </div>
                <input
                  type="date"
                  name="start"
                  value={start}
                  id="start"
                  onChange={handleChange}
                />
              </div>
              <div className={styles.fieldRight}>
                <div className="mb-2">
                  <label>To</label>
                </div>
                <input
                  type="date"
                  name="end"
                  value={end}
                  id="end"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case "tour":
        return (
          <div className={styles.formSearch}>
            <div className={`${styles.fieldMain} mb-2`}>
              <div className="mb-2">
                <label>Embarkasi</label>
              </div>
              <div>
                <select
                  className={`${styles.select} w-100`}
                  name="location_id"
                  id="location_id"
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    Pilih Lokasi Embarkasi
                  </option>
                </select>
              </div>
            </div>
            <div className={`${styles.fieldMain} d-flex`}>
              <div className={styles.fieldLeft}>
                <div className="mb-2">
                  <label>From</label>
                </div>
                <input
                  type="date"
                  name="start"
                  value={start}
                  id="start"
                  onChange={handleChange}
                />
              </div>
              <div className={styles.fieldRight}>
                <div className="mb-2">
                  <label>To</label>
                </div>
                <input
                  type="date"
                  name="end"
                  value={end}
                  id="end"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case "hotel":
        return (
          <div className={styles.formSearch}>
            <div className={`${styles.fieldMain} mb-2`}>
              <div className="mb-2">
                <label>Lokasi Keberangkatan</label>
              </div>
              <select
                className={styles.select}
                name="location_id"
                id="location_id"
                onChange={handleChange}
              >
                <option value="" hidden>
                  Pilih Lokasi Keberangkatan
                </option>
              </select>
            </div>
            <div className={`${styles.fieldMain} mb-2`}>
              <div className="mb-2">
                <label>Guest</label>
              </div>
              <div className="tamu-wrap">
                <div className="d-flex justify-content-between align-items-center mb-1 ">
                  <div className="tamu-title">Room</div>
                  <div className="tamu-counter d-flex justify-content-between align-items-center gap-2">
                    <div onClick={() => handleMinus("room")}>-</div>
                    <input
                      value={room}
                      name="room"
                      onChange={handleChange}
                      id="room"
                    />
                    <div onClick={() => handlePlus("room")}>+</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-1 ">
                  <div className="tamu-title">Dewasa</div>
                  <div className="tamu-counter d-flex justify-content-between align-items-center gap-2">
                    <div onClick={() => handleMinus("adult")}>-</div>
                    <input
                      value={adult}
                      name="adults"
                      onChange={handleChange}
                      id="adults"
                    />
                    <div onClick={() => handlePlus("adult")}>+</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="tamu-title">Anak-Anak</div>
                  <div className="tamu-counter  d-flex justify-content-between align-items-center gap-2">
                    <div onClick={() => handleMinus("children")}>-</div>
                    <input
                      value={children}
                      name="children"
                      onChange={handleChange}
                      id="children"
                    />
                    <div onClick={() => handlePlus("children")}>+</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.fieldMain} d-flex`}>
              <div className={styles.fieldLeft}>
                <div className="mb-2">
                  <label>From</label>
                </div>
                <input
                  type="date"
                  name="start"
                  value={start}
                  id="start"
                  onChange={handleChange}
                />
              </div>
              <div className={styles.fieldRight}>
                <div className="mb-2">
                  <label>To</label>
                </div>
                <input
                  type="date"
                  name="end"
                  value={end}
                  id="end"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case "flight":
        return (
          <div className={styles.formSearch}>
            <div className={styles.fieldMain}>
              <select
                className={styles.select}
                name="from_where"
                id="from_where"
              >
                <option>Pilih Lokasi Keberangkatan</option>
              </select>
              <select className={styles.select} name="to_where" id="to_where">
                <option>Pilih Lokasi Tiba</option>
              </select>
            </div>
            <div className={styles.fieldLeft}>
              <input type="date" name="start" id="start" />
              <input type="date" name="end" id="end" />
            </div>
            <div className={styles.fieldLeft}>
              <input type="date" name="depart" id="depart" />
            </div>
          </div>
        );
      case "train":
        return (
          <div className={styles.formSearch}>
            <div className={styles.fieldMain}>
              <input placeholder="Masukkan Nama Kereta Kamu" type="text" />
            </div>
            <div className={styles.fieldLeft}>
              <input type="date" />
              <input type="date" />
            </div>
          </div>
        );
      case "visa":
        return (
          <div className={styles.formSearch}>
            <div className={styles.fieldMain}>
              <input placeholder="Masukkan Nama Kereta Kamu" type="text" />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const redirect = (e) => {
    e.preventDefault();
    let tmp = `/search?type=${type.value}&${params}&view=mobile`;
    navigate(tmp);
  };

  return (
    <div
      className={`${
        type !== "full" ? styles.searchContainerFull : styles.searchContainer
      }`}
    >
      {type === "full" && (
        <div className={styles.dropdownSearch}>
          <div
            className={`position-relative ${styles.wrapFilter}`}
            onClick={selectSearch}
          >
            <img src={hotel} width={28} height={28} />
            <input type="text" value={typeFilter} />
          </div>
          {searchResult && (
            <div className={styles.resultSearch}>
              {typeService.map((item, index) => (
                <div
                  className={styles.itemSearch}
                  key={index}
                  onClick={() => handleSelect(item)}
                >
                  <div className={styles.iconSearch}>
                    <img src={hotel} width={28} height={28} />
                  </div>
                  <div className={styles.textSearch}>{item.title}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={styles.renderForm}>
        {isMobile && (
          <div>
            <div className="mb-1 fw-bold mt-1">Kategori: {type.label}</div>
          </div>
        )}
        <form className={styles.formWrap}>
          {isMobile ? (
            // <div className={styles.travelWrap}>
            //   <div></div>
            // </div>
            <></>
          ) : (
            <div className={styles.travelWrap}>
              <div className={styles.typeTravel}>
                <div
                  onClick={() => handleSelectTypeTour("One Way")}
                  className={`${styles.tabTravel} ${
                    typeRoute === "One Way" ? styles.active : ""
                  }`}
                >
                  One Way
                </div>
                <div
                  onClick={() => handleSelectTypeTour("Round Trip")}
                  className={`${styles.tabTravel} ${
                    typeRoute === "Round Trip" ? styles.active : ""
                  }`}
                >
                  Round Trip
                </div>
              </div>
              <div className={styles.wrapCalculator}>
                <span>
                  <img src={help} />
                </span>
                Butuh Kalkulator Wisata?
              </div>
            </div>
          )}
          <>{typeForm(type.value || type)}</>
          <div>
            <div className={styles.buttonWrap}>
              <button onClick={redirect} className={styles.buttonSearch}>
                Submit
              </button>
            </div>
            {isMobile && (
              <div
                className={styles.buttonSearchSecondary}
                onClick={resetField}
              >
                Kembali ke Halaman Awal
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
