import { Col, Container, Image, Row } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import styles from "./../styles/Search.module.scss";
import CardProject from "../components/Card";
import { PlaceServices } from "../http/PlaceHttp";
import { useEffect, useState } from "react";
import Pagination from "../components/common/Pagination";
import { LocationServices } from "../http/LocationHttp";
import { useLocation } from "react-router-dom";
import filter from "./../../src/assets/filter-add.svg";
import HotelSearch from "../components/Search/Hotel/HotelSearch";
import { convertToParams, isMobileCheck } from "../utils";
import moment from "moment";
import TourSearch from "../components/Search/Tour/TourSearch";
import FlightSearch from "../components/Search/Flight/FlightSearch";
import VisaSearch from "../components/Search/Visa/VisaSearch";
import BusSearch from "../components/Search/Bus/BusSearch";
import TrainSearch from "../components/Search/Train/TrainSearch";
import FilterSection from "../components/FilterSection";
import Skeleton from "react-loading-skeleton";
import { set } from "react-hook-form";
import { debounce } from "lodash";
export default function Search() {
  const size = isMobileCheck() ? 10 : 9;
  const [currentPage, setCurrentPage] = useState(1);
  const defaultParams = {
    limit: size,
    page: currentPage,
  };
  const router = useLocation();
  const typeFilter = router.search;
  let currType = typeFilter.split("=");
  currType = currType[1].split("&");
  const [queryObject, setQueryObject] = useState(typeFilter);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [starRate, setStarRate] = useState([]);
  const [rating, setRating] = useState([]);
  const [terms, setTerms] = useState([]);
  const [tourType, setTourType] = useState([]);
  const [params, setParams] = useState(null);
  const [filterData, setFilterData] = useState();
  const [formSearchData, setFormSearchData] = useState();
  const [priceFilter, setPriceFilter] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [tourParam, setTourParam] = useState();
  const [priceParam, setPriceParam] = useState();
  const [styleParam, setStyleParam] = useState();
  const [ratingParam, setRatingParam] = useState();
  const [multipleParam, setMultipleParam] = useState();
  const [queryObj, setQueryObj] = useState([]);
  const [typeMobile, setTypeMobile] = useState(null);
  // const [facilitiesParam, setFacilitiesParam] = useState();

  // useEffect(() => {
  //   console.log(queryObject);
  // }, [queryObject]);

  useEffect(() => {
    const params = new URLSearchParams(typeFilter);
    const tmp = {};
    params.forEach((value, key) => {
      if (key.includes("[]")) {
        tmp[key] = value;
      } else {
        tmp[key] = value;
      }
    });
    setParams({ ...defaultParams, ...tmp });
  }, [typeFilter]);

  useEffect(() => {
    console.log(params);
  }, [params]);

  useEffect(() => {
    // Set endDate to one day after startDate
    if (startDate) {
      const nextDay = new Date(startDate);
      nextDay.setDate(nextDay.getDate() + 1);
      setStartDate(nextDay);
    }
    if (endDate) {
      const nextDay = new Date(endDate);
      nextDay.setDate(nextDay.getDate() + 2);
      setEndDate(nextDay);
    }
  }, []);

  useEffect(() => {
    if (params) {
      let tmp = { ...params };
      delete tmp.view;
      delete tmp.type;
      getListServices(convertToParams(tmp), params?.type);
    }
  }, [queryObject, params]);

  useEffect(() => {
    getFilterParam(currType[0]);
    getFormSearch(currType[0]);
  }, []);

  // useEffect(() => {
  //   // multiple star rate
  //   if (
  //     selectedLocation ||
  //     tourParam ||
  //     priceParam ||
  //     styleParam ||
  //     ratingParam ||
  //     starRate ||
  //     startDate ||
  //     endDate
  //   ) {
  //     // console.log(queryObject);
  //     let datePick = `&start_date=${
  //       queryObject?.view === "mobile"
  //         ? queryObject?.start
  //           ? moment(queryObject?.start).format("MM/DD/YYYY")
  //           : moment(startDate).format("MM/DD/YYYY")
  //         : moment(startDate).format("MM/DD/YYYY")
  //     }&end_date=${
  //       queryObject?.view === "mobile"
  //         ? queryObject?.end
  //           ? moment(queryObject?.end).format("MM/DD/YYYY")
  //           : moment(endDate).format("MM/DD/YYYY")
  //         : moment(endDate).format("MM/DD/YYYY")
  //     }${
  //       queryObject?.view === "mobile"
  //         ? queryObject["cat_id[]"]
  //           ? `&cat_id[]=${queryObject["cat_id[]"]}`
  //           : ""
  //         : ""
  //     }`;
  //     let paramMultiple = `${params}${
  //       selectedLocation ? selectedLocation : ""
  //     }${tourParam ? tourParam : ""}${priceParam ? priceParam : ""}${
  //       styleParam ? styleParam : ""
  //     }${starRate ? starRate : ""}${ratingParam ? ratingParam : ""}${
  //       startDate && endDate ? datePick : ""
  //     }`;
  //     console.log(queryObject, paramMultiple);
  //     // getListServices(paramMultiple, queryObject?.type);
  //   }
  // }, [
  //   starRate,
  //   selectedLocation,
  //   tourParam,
  //   priceParam,
  //   styleParam,
  //   ratingParam,
  //   typeFilter,
  //   startDate,
  //   endDate,
  // ]);

  useEffect(() => {
    if (
      currType[0] == "hajj" ||
      currType[0] == "umrah" ||
      currType[0] == "hotel" ||
      currType[0] == "tour"
    ) {
      setTypeMobile(currType[0]);
    }
  }, [currType]);

  useEffect(() => {
    setFilterParams(terms, setStyleParam);
    setFilterParams(tourType, setTourParam);
    setFilterParams(rating, setRatingParam);
    // setFilterParams(starRate, setStarRate);
  }, [terms, tourType, rating, starRate]);

  useEffect(() => {
    if (queryObj.length > 0) {
      let newUrl = getUpdatedParams(window.location.href, queryObj);
      newUrl = `${window.location.pathname}?${newUrl}`;
      console.log(newUrl);
      // debouncedGetListServices(newUrl, params?.type);
    }
  }, [queryObj, params]);

  const handlePagination = (page) => {
    let param = `?limit=${size}&page=${page}`;
    setParams(param);
    setCurrentPage(page);
    getListServices(param, params?.type);
  };

  const handleChange = (e) => {
    let payload = [...queryObj];
    let key = e.target.name;
    let value = e.target.value;

    if (key === "max_price" || key === "min_price") {
      const minPrice =
        key === "min_price"
          ? value
          : payload
              .find((item) => item.price_range)
              ?.price_range.split(";")[0] || "";
      const maxPrice =
        key === "max_price"
          ? value
          : payload
              .find((item) => item.price_range)
              ?.price_range.split(";")[1] || "";
      payload = payload.filter((item) => !item.price_range);
      payload.push({
        price_range: `${minPrice};${maxPrice}`,
      });
    } else {
      // Check if the key-value pair already exists in the payload
      const existingItemIndex = payload.findIndex(
        (item) => item[key] === value
      );
      if (existingItemIndex !== -1) {
        // If it exists, remove it
        payload.splice(existingItemIndex, 1);
      } else {
        // Otherwise, add it
        payload.push({
          [key]: value,
        });
      }
      console.log(
        // existingItemIndex,
        payload.find((item) => item[key] == value),
        value,
        queryObj,
        payload
      );
    }

    setQueryObj(payload);
  };

  function convertArrayToParams(array) {
    const params = new URLSearchParams();

    array.forEach((obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      params.append(key, value);
    });

    return params.toString();
  }

  function getUpdatedParams(url, paramsArray) {
    const urlObj = new URL(url);
    const existingParams = new URLSearchParams(urlObj.search);
    const newParams = new URLSearchParams(convertArrayToParams(paramsArray));

    // Remove specific parameters
    existingParams.delete("view");

    // Append new parameters to existing ones, avoiding duplicates
    newParams.forEach((value, key) => {
      if (key === "price_range") {
        existingParams.delete("price_range");
        existingParams.append(key, value);
      } else if (key === "start" || key === "end") {
        existingParams.set(key, value); // Always set the latest start and end dates
      } else if (
        !existingParams.has(key) ||
        !existingParams.getAll(key).includes(value)
      ) {
        existingParams.append(key, value);
      }
    });

    return existingParams.toString();
  }

  const debouncedGetListServices = debounce((newUrl, type) => {
    let tmp = newUrl.split("?");
    window.history.pushState(null, "", newUrl);
    getListServices(tmp[1], type);
  }, 600);

  // const handleChange = (e, type, id) => {
  //   console.log(type, id);

  //   if (type === "price") {
  //     setParamsMultiple(priceFilter, setPriceFilter, e, ``, false);
  //   } else if (type === "rating") {
  //     setParamsMultiple(rating, setRating, id, `&review_score%5B%5D`, true);
  //   } else if (type === "style_travel") {
  //     setParamsMultiple(terms, setTerms, id, `&terms%5B%5D`, true);
  //   } else if (type === "star_rate") {
  //     setParamsMultiple(starRate, setStarRate, id, `&star_rate%5B%5D`, true);
  //   } else {
  //     setParamsMultiple(tourType, setTourType, id, `&cat_id[]`, true);
  //   }
  // };

  const getFormSearch = async (type) => {
    setIsLoading(true);
    try {
      let response = await PlaceServices.formSearchFilter();
      if (response) {
        setFormSearchData(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getFilterParam = async (type) => {
    setIsLoading(true);
    try {
      let response;
      if (type === "flight") {
        response = await PlaceServices.flightFilter();
      } else if (type === "hotel") {
        response = await PlaceServices.hotelFilter();
      } else if (type === "visa") {
        response = await PlaceServices.visaFilter();
      } else if (type === "bus") {
        response = await PlaceServices.busFilter();
      } else if (type === "train") {
        response = await PlaceServices.trainFilter();
      } else {
        response = await PlaceServices.tourFilter();
      }

      if (response) {
        setIsLoading(false);
        setFilterData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLocation = async () => {
    setIsLoading(true);
    try {
      const response = await LocationServices.locationList();
      if (response) {
        setIsLoading(false);
        setLocationList(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getListServices = async (param, type) => {
    setIsLoading(true);
    try {
      let response;
      if (type === "flight") {
        response = await PlaceServices.flightList(`?${param}`);
      } else if (type === "hotel") {
        response = await PlaceServices.hotelList(`?${param}`);
      } else if (type === "visa") {
        response = await PlaceServices.visaList(`?${param}`);
      } else if (type === "bus") {
        response = await PlaceServices.busList(`?${param}`);
      } else if (type === "train") {
        response = await PlaceServices.trainList(`?${param}`);
      } else if (type === "catering") {
        response = await PlaceServices.cateringList(`?${param}`);
      } else {
        response = await PlaceServices.tourList(`?${param}`);
      }
      if (response) {
        setIsLoading(false);
        setData(response.data);
        setPagination(response);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSelectLocation = () => {
    if (!locationList?.length > 0) getLocation();
  };

  const handleSelectedLocation = (e) => {
    let tmp = `&location_id=${e.target.value}`;
    setSelectedLocation(tmp);
  };

  const orderBy = (e) => {
    let param = `?${params}&orderby=${e.target.value}`;
    getListServices(param, params?.type);
  };

  const handleFilterByPrice = () => {
    setShowFilter(!showFilter);
  };

  const handleShowFilter = (e) => {
    e.preventDefault();
    setShowFilter(!showFilter);
  };

  const resetField = () => {
    setTypeMobile(null);
  };

  return (
    <WebLayout type="search" showNav={false} color="dark">
      <Container>
        <Row>
          {/* <FilterSection type="half" /> */}
          {/* <div className={styles.searchWrap}> */}
          {!isMobileCheck() && (
            <Col lg={3}>
              {/* FLIGHT */}
              {currType[0] === "flight" && (
                <FlightSearch
                  filterData={filterData}
                  handleChange={handleChange}
                  handleFilterByPrice={handleFilterByPrice}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {/* BUS */}
              {currType[0] === "bus" && (
                <BusSearch
                  filterData={filterData}
                  handleChange={handleChange}
                  handleFilterByPrice={handleFilterByPrice}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {/* TRAIN */}
              {currType[0] === "train" && (
                <TrainSearch
                  filterData={filterData}
                  handleChange={handleChange}
                  handleFilterByPrice={handleFilterByPrice}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {/* TOUR */}
              {(currType[0] === "hajj" ||
                currType[0] === "umrah" ||
                currType[0] === "tour") && (
                <TourSearch
                  filterData={filterData}
                  handleSelectLocation={handleSelectLocation}
                  handleSelectedLocation={handleSelectedLocation}
                  handleChange={handleChange}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  handleFilterByPrice={handleFilterByPrice}
                />
              )}
              {/* HOTEL */}
              {currType[0] === "hotel" && (
                <HotelSearch
                  filterData={filterData}
                  locationList={locationList}
                  priceFilter={priceFilter}
                  setPriceParam={setPriceParam}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  handleChange={handleChange}
                />
              )}
              {/* Visa */}
              {currType[0] === "visa" && (
                <VisaSearch
                  filterData={filterData}
                  locationList={locationList}
                  priceFilter={priceFilter}
                  setPriceParam={setPriceParam}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  handleChange={handleChange}
                />
              )}
            </Col>
          )}
          <Col sm={12} lg={8} className="offset-lg-1 mt-3 mt-md-4">
            <div className={styles.sortBox}>
              <div onClick={handleShowFilter} className={styles.addFilter}>
                <Image src={filter} width={16} height={16} />
                Filter
              </div>
              <div className={styles.addSort}>
                <label>Urutkan</label>
                <select onChange={orderBy} className="form-select">
                  <option value="" defaultValue>
                    Rekomendasi
                  </option>
                  <option value="price_low_high">Harga Terendah</option>
                  <option value="price_high_low">Harga Tertinggi</option>
                  <option value="rate_high_low">Rating Tertinggi</option>
                </select>
              </div>
            </div>
            {showFilter && (
              // FILTER MOBILE
              <>
                {/* FLIGHT */}
                {currType[0] === "flight" && (
                  <FlightSearch
                    filterData={filterData}
                    handleChange={handleChange}
                    handleFilterByPrice={handleFilterByPrice}
                  />
                )}
                {/* BUS */}
                {currType[0] === "bus" && (
                  <BusSearch
                    filterData={filterData}
                    handleChange={handleChange}
                    handleFilterByPrice={handleFilterByPrice}
                  />
                )}
                {/* TRAIN */}
                {currType[0] === "train" && (
                  <TrainSearch
                    filterData={filterData}
                    handleChange={handleChange}
                    handleFilterByPrice={handleFilterByPrice}
                  />
                )}
                {/* TOUR */}
                {(currType[0] === "hajj" ||
                  currType[0] === "umrah" ||
                  currType[0] === "tour") && (
                  <TourSearch
                    filterData={filterData}
                    handleSelectLocation={handleSelectLocation}
                    handleSelectedLocation={handleSelectedLocation}
                    locationList={locationList}
                    handleChange={handleChange}
                    handleFilterByPrice={handleFilterByPrice}
                  />
                )}
                {/* HOTEL */}
                {currType[0] === "hotel" && (
                  <HotelSearch
                    filterData={filterData}
                    locationList={locationList}
                    priceFilter={priceFilter}
                    setPriceParam={setPriceParam}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                  />
                )}
                {/* Visa */}
                {currType[0] === "visa" && (
                  <VisaSearch
                    filterData={filterData}
                    locationList={locationList}
                    priceFilter={priceFilter}
                    setPriceParam={setPriceParam}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                  />
                )}
              </>
            )}

            {data ? (
              data?.length > 0 ? (
                <>
                  <Row className={styles.listWrap}>
                    {data.map((item, index) => {
                      return (
                        <Col
                          lg={4}
                          md={6}
                          sm={12}
                          xs={12}
                          key={index}
                          className={`${styles.listItem} mb-4`}
                        >
                          <CardProject item={item} type={params?.type} />
                        </Col>
                      );
                    })}
                  </Row>
                  <Pagination
                    totalPages={pagination.total_pages}
                    handlePagination={handlePagination}
                    currentPage={currentPage}
                  />
                </>
              ) : (
                <div className="text-center">Tidak Ada Data</div>
              )
            ) : (
              <>
                {Array.from({ length: 5 }).map((_, index) => {
                  return (
                    <div key={index} className="mb-2">
                      <Skeleton
                        height={230}
                        borderRadius={10}
                        className="mb-2"
                      />
                      <Skeleton height={22} className="mb-1" />
                      <Skeleton height={22} className="mb-1" />
                      <Skeleton height={22} className="mb-3" />
                    </div>
                  );
                })}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </WebLayout>
  );
}

function setParamsMultiple(state, setState, id, param, multiple) {
  if (multiple) {
    let newData = [...state];
    if (newData.includes(`${param}=${id}`)) {
      newData = newData.filter((item) => item !== `${param}=${id}`);
    } else {
      newData.push(`${param}=${id}`);
    }
    setState(newData);
  } else {
    const newData = { ...state };
    newData[id.target.id] = id.target.value;
    setState(newData);
  }
}

function setFilterParams(data, setState) {
  let tmp = data.map((item) => `${item}`).join("");
  setState(`${tmp}`);
  // getListServices(send, typeFilter);
}
