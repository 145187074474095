import { Col, Container, Image, Row, Form } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import styles from "./../styles/CustomPackage.module.scss";
import building from "./../assets/CustomPackage/building.svg";
import user from "./../assets/CustomPackage/user-gray.svg";

export default function CustomPackage() {
  return (
    <WebLayout color="dark">
      <Container>
        <Row className={styles.contentWrap}>
          <Col lg={8} className={styles.customPackage}>
            <div className={styles.heading}>Custom Paket Anda Sendiri</div>
            <div className={styles.summary}>
              Pilih sendiri semua akomodasi dan transportasi anda menuju ibadah
              ke tanah suci
            </div>
            <div className={styles.groupWrap}>
              <div className={styles.iconBox}>
                <Image />
                Informasi Anggota
              </div>
              <Row className={styles.options}>
                <Col lg={4}>
                  <Form.Group className="mb-4">
                    <Form.Label>Jumlah Anggota</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option value="1">1 Orang</option>
                      <option value="2">2 Orang</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-4">
                    <Form.Label>Waktu Keberangkatan</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option value="1">23/03/2023</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-4">
                    <Form.Label>Lokasi Keberangkatan</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option value="1">Jakarta</option>
                      <option value="2">Depok</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <hr className="solid"></hr>
            <div className={styles.transport}>
              <div className={styles.iconBox}>
                <Image />
                Akomodasi & Tranportasi
              </div>
              <Row>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada hotel yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Hotel</div>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada penerbangan yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Penerbangan</div>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada hotel yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Hotel</div>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada hotel yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Hotel</div>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada penerbangan yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Penerbangan</div>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} className="mb-4">
                  <div className={styles.boxCard}>
                    <Image src={building} />
                    Belum ada hotel yang dipilih
                  </div>
                  <div className={styles.btn}>Pilih Hotel</div>
                </Col>
              </Row>
            </div>
            <hr className="solid"></hr>
            <div className={styles.groupWrap}>
              <div className={styles.iconBox}>
                <Image />
                Kelengkapan
              </div>
            </div>
          </Col>
          <Col lg={4} className={styles.summaryPrice}>
            <div className={styles.summaryBox}>
              <div className={styles.title}>Detail Pemesanan</div>
              <div className={styles.textWrap}>
                <div className={styles.iconBox}>
                  <Image src={user} alt="" />2 Orang
                </div>
                <div>Rp 3.000.000</div>
              </div>
              <div className={styles.totalWrap}>
                <div className={styles.heading}>Total:</div>
                <div className={styles.total}>Rp 3.000.000</div>
              </div>
              <div className={`btnYellow w-100 mt-5`}>Bayar</div>
            </div>
          </Col>
        </Row>
      </Container>
    </WebLayout>
  );
}
