export const itineraryData = [
  {
    tanggal: "14 November 2023",
    jadwal: [
      { jam: "06:30 - 11:00", kegiatan: "Menuju Hotel" },
      { jam: "15:00 - 17:00", kegiatan: "Gladi resik" },
      { jam: "17:00 - 20:00", kegiatan: "Makan malam" },
      { jam: "20:00 - 23:00", kegiatan: "Tidur" },
    ],
  },
  // Tambahkan data untuk hari lainnya jika diperlukan
  {
    tanggal: "15 November 2023",
    jadwal: [
      { jam: "09:00 - 12:00", kegiatan: "Sarapan" },
      { jam: "13:00 - 15:00", kegiatan: "Berkumpul gladi resik" },
      { jam: "18:00 - 20:00", kegiatan: "Makan Malam" },
      { jam: "20:00 - 23:00", kegiatan: "Tidur" },
    ],
  },
  // Tambahkan data untuk hari lainnya jika diperlukan
  {
    tanggal: "22 November 2023",
    jadwal: [
      { jam: "09:00 - 12:00", kegiatan: "Sarapan" },
      { jam: "13:00 - 13:30", kegiatan: "Berkumpul gladi resik" },
    ],
  },
  // Tambahkan data untuk hari lainnya jika diperlukan
];
