import { Image, Form, Button } from "react-bootstrap";
import styles from "../../../styles/Search.module.scss";
import star from "../../../assets/Home/star.svg";
import vector from "../../../assets/Vector.svg";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function RoomSearch(props) {
  // You can access data from props like this
  const {
    locationList,
    filterData,
    priceFilter,
    setPriceParam,
    handleChange,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;

  // Define the necessary functions and variables here
  const [selectedLocation, setSelectedLocation] = useState(""); // Example state variable

  // Define the event handlers here
  const handleSelectLocation = () => {
    // Implement your logic for selecting location here
  };

  const handleSelectedLocation = (e) => {
    // Implement your logic for handling selected location here
    setSelectedLocation(e.target.value); // Example: Update the selected location state
  };

  const handleFilterByPrice = () => {
    // Implement your logic for filtering by price here
    // tour
    let send = `&price_range=${
      priceFilter.min_price
        ? priceFilter.min_price
        : filterData.length > 0 && filterData[0].min_price
    }%3B${
      priceFilter.max_price
        ? priceFilter.max_price
        : filterData.length > 0 && filterData[0].max_price
    }`;
    setPriceParam(send);
    // console.log(send);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className={styles.filterBox}>
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Harga</Form.Label>
            <div className={styles.priceGroup}>
              <Form.Control
                onChange={(e) => handleChange(e, "price")}
                id="min_price"
                type="text"
                // value={minPrice}
                placeholder="MIN"
              />
              -
              <Form.Control
                onChange={(e) => handleChange(e, "price")}
                id="max_price"
                type="text"
                placeholder="MAX"
                // value={maxPrice}
              />
            </div>
            <Button
              onClick={handleFilterByPrice}
              className="btnYellow mt-3 mt-md-4 w-100"
            >
              <Image src={vector} />
              Apply Harga
            </Button>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Check In & Check Out</Form.Label>
            <div className={styles.priceGroup}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
            </div>
          </Form.Group>
          <Form.Label className="mb-2">Room</Form.Label>
          {[...Array(5)].map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  type="checkbox"
                  value={5 - index}
                  onChange={(e) => handleChange(e, "star_rate", 5 - index)}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>
                  {[...Array(5 - index)].map(() => {
                    return <Image src={star}></Image>;
                  })}
                </div>
              </div>
            );
          })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Review Score</Form.Label>
          {[...Array(5)].map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  type="checkbox"
                  value={5 - index}
                  onChange={(e) => handleChange(e, "rating", 5 - index)}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>
                  {[...Array(5 - index)].map(() => {
                    return <Image src={star}></Image>;
                  })}
                </div>
              </div>
            );
          })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Tipe Property</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[0].terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e, "style_travel", item.id)}
                    value={item.id}
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Fasilitas</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[1].terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e, "style_travel", item.id)}
                    value={item.id}
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Servis Room</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[2].terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e, "style_travel", item.id)}
                    value={item.id}
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          {locationList?.length > 0 && (
            <>
              <Form.Label className="mb-2">Lokasi</Form.Label>
              {locationList &&
                locationList[3].data &&
                locationList[3].data[2].terms?.map((item, index) => {
                  return (
                    <div className={styles.starGroup}>
                      <input
                        onChange={(e) =>
                          handleChange(e, "style_travel", item.id)
                        }
                        value={item.id}
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                      ></input>
                      <div className={styles.star}>{item.name}</div>
                    </div>
                  );
                })}
            </>
          )}
          {/* <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Check In - Out</Form.Label>
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Guests</Form.Label> */}
        </Form>
      </div>
    </>
  );
}
