import { formatRupiah, slugify } from "../../constant/lib";
import styles from "./CardProject.module.scss";
import defaultPlaceholder from "./../../assets/default.png";
import moment from "moment";
import { Button, Image, Modal, Table } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { BookingServices } from "../../http/BookingHttp";
import { useNavigate } from "react-router-dom";
import { FlightServices } from "../../http/FlightHttp";
import { iconArrive, iconBus, iconDeparture } from "../../constant/data";
import { BusesServices } from "../../http/BusesHttp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";

export default function BusCard(props) {
  const { item, handleImageError, type } = props;
  const refCount = useRef(null);
  const [openSeatModal, setOpenSeatModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [currItem, setCurrItem] = useState(null);
  const [isShowTotal, setIsShowTotal] = useState(false);
  const [isSuccessAddToCart, setIsSuccessAddToCart] = useState(false);
  const navigate = useNavigate();
  const [typeUrl, setTypeUrl] = useState("hotel");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [countPrices, setCountPrices] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingCode, setBookingCode] = useState("");

  const [carTotal, setCarTotal] = useState(0);

  const total = countPrices.reduce((acc, item) => {
    const subtotal = parseInt(item.qty) * parseInt(item.price);
    return acc + subtotal;
  }, 0);

  useEffect(() => {
    if (isSuccessAddToCart) {
      navigate(
        `/confirmation-booking?total_rent=${carTotal}&price=${total}&title=${slugify(
          detailData.title
        )}&departure=${moment(detailData.departure_time).format(
          "YYYY-MM-DD"
        )}&arrival=${moment(detailData.arrival_time).format(
          "YYYY-MM-DD"
        )}&booking_code=${bookingCode}${
          startDate
            ? `&start_date=${moment(startDate).format("YYYY-MM-DD")}`
            : ""
        }${
          endDate
            ? `&end_date=${moment(endDate).format("YYYY-MM-DD")}`
            : `&end_date=${moment(startDate).format("YYYY-MM-DD")}`
        }&type=car`
      );
    }
  }, [isSuccessAddToCart, endDate, startDate]);

  useEffect(() => {
    // console.log(detailData, startDate, endDate);
  }, [detailData, startDate, endDate]);

  const getDetailBus = async (id) => {
    try {
      const response = await BusesServices.getDetailBusUmroh(id);
      if (response) {
        setDetailData(response.data);
        // setCountPrices(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenSeat = (item) => {
    setSelectedData(item);
    setOpenSeatModal(!openSeatModal);
    getDetailBus(item.id);
  };

  useEffect(() => {
    // Set endDate to one day after startDate
    if (startDate) {
      const nextDay = new Date(startDate);
      nextDay.setDate(nextDay.getDate() + 1);
      setStartDate(nextDay);
    }
    if (endDate) {
      const nextDay = new Date(endDate);
      nextDay.setDate(nextDay.getDate() + 2);
      setEndDate(nextDay);
    }
  }, []);

  useEffect(() => {
    if (detailData?.bus_seat?.length > 0) {
      const newData = [...detailData?.bus_seat].map((room) => ({
        qty: 0,
        id: room.id,
        price: room.price,
        max_passengers: room.max_passengers,
        // flight_id: room.flight_id,
        seat_type: room.seat_type,
        person: room.person,
        baggage_check_in: room.baggage_check_in,
        baggage_cabin: room.baggage_cabin,
        author_id: "",
        price_html: room.price_html,
      }));
      setCountPrices(newData);
    }
  }, [detailData]);

  const handleBuyNow = async () => {
    let payload = {
      service_id: selectedData.id,
      service_type: "car",
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(startDate).format("YYYY-MM-DD"),
      number: carTotal,
    };
    // countPrices.total = total;
    // localStorage.setItem("bookFlight", JSON.stringify(countPrices));
    setIsLoading(true);
    let finalPayload = { ...payload };
    const formData = new FormData();

    // Append each key-value pair from finalPayload to the FormData object
    for (const key in finalPayload) {
      formData.append(key, finalPayload[key]);
    }
    try {
      const response = await BookingServices.postAddToCart(formData);
      // console.log(detail);
      if (response) {
        // setBookingCode(response.booking_code);
        setBookingCode(response.booking_code);
        setTypeUrl("car");
        setIsSuccessAddToCart(true);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setIsSuccessAddToCart(false);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleAddCar = (type, guest) => {
    if (type === "increment") {
      setCarTotal(carTotal + 1);
    } else {
      if (carTotal === 0) {
        console.log("count already 0");
      } else {
        setCarTotal(carTotal - 1);
      }
    }
  };

  const countingPrice = (e, type, idx, selectedItem) => {
    e.preventDefault();
    setIsShowTotal(true);
    setCurrItem(selectedItem);
    // service_id: 20
    // service_type: flight

    const newCountPrices = [...countPrices];

    if (type === "add") {
      newCountPrices[idx].qty++;
    } else {
      if (newCountPrices[idx].qty > 0) {
        newCountPrices[idx].qty--;
      }
    }
    // if (action === "add") {
    //   newCountPrices[idx]++;
    // } else if (action === "minus" && newCountPrices[idx] > 0) {
    //   newCountPrices[idx]--;
    // }

    setCountPrices(newCountPrices);
  };

  return (
    <>
      <div>
        <div className={styles.thumbnail}>
          {item.image ? (
            <img alt="img" src={item.image} onError={handleImageError} />
          ) : (
            <img src={defaultPlaceholder} alt="default" />
          )}
          {item.discount_percent && (
            <div className={styles.discount}>{item.discount_percent}</div>
          )}
        </div>
        <div className={styles.detail}>
          <div className={styles.wrapTitle}>
            <div className={styles.title}>{item?.title}</div>
          </div>
          {/* <div className="d-flex mb-3">
            <div className={styles.time}>
            
              {moment(item.departure_time).format("DD MMM YYYY HH:mm")} -{" "}
              {moment(item.arrival_time).format("DD MMM YYYY HH:mm")}
            </div>
          </div> */}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className={styles.startFrom}>Mulai Dari</div>
              <div className={styles.priceFrom}>
                {formatRupiah(item.min_price ?? item.price ?? item.sale_price)}
              </div>
            </div>
            <div>
              <Button
                className={styles.selectSeat}
                onClick={() => handleOpenSeat(item)}
              >
                Pilih Seat
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={openSeatModal}
        onHide={() => setOpenSeatModal(false)}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <h5 className="mb-0">
              <b>Pilih Seat</b>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* {detailData ? (
              <div className="mb-3">
                <>
                  <div className={`${styles.quantityOrder} mb-3`}>
                    <div className={`${styles.titleQuantityOrder} mb-2`}>
                      Jumlah Mobil
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "12px" }}
                    >
                      <Button
                        onClick={() => handleAddCar("decrement", "adult")}
                      >
                        -
                      </Button>
                      <div>{carTotal}</div>
                      <Button
                        onClick={() => handleAddCar("increment", "adult")}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="mb-2">Pilih Tanggal</div>
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-end align-items-center mt-5">
                    <div className="me-3">
                      Total:{" "}
                      <b>
                        {isShowTotal
                          ? "-"
                          : formatRupiah(carTotal * detailData?.min_price)}
                      </b>
                    </div>
                    <div>
                      <Button onClick={handleBuyNow}>Beli Sekarang</Button>
                    </div>
                  </div>
                </>
              </div>
            ) : (
              <div className="text-center">Menunggu Data</div>
            )} */}
            {detailData ? (
              <div className="mb-3">
                {detailData?.bus_seat?.length > 0 ? (
                  <>
                    {detailData?.bus_seat?.map((item, idx) => {
                      return (
                        <div key={idx} className={styles.seatItem}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <div className={styles.seatName}>
                                {item.seat_type?.name}
                              </div>
                              <div className={styles.maxPassengers}>
                                Maksimal {item?.max_passengers} Penumpang
                              </div>
                            </div>
                            <div className={styles.badge}>
                              {/* Bagasi */}
                              {item.person}
                            </div>
                          </div>
                          <div className={styles.separator}></div>
                          <div className="d-flex gap-2 justify-content-between align-items-center mb-2">
                            <div className="w-50">
                              <div className={styles.labelModal}>
                                Bagage Cabin
                              </div>
                              <div className={styles.valueModal}>
                                {item.baggage_cabin}
                              </div>
                            </div>
                            <div className="w-50">
                              <div className={styles.labelModal}>
                                Bagage Check In
                              </div>
                              <div className={styles.valueModal}>
                                {item.baggage_check_in}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-2 justify-content-between align-items-center mb-2">
                            <div>
                              <div className={styles.labelModal}>Harga</div>
                              <div className={styles.valueModal_price}>
                                {formatRupiah(item.price)}
                              </div>
                            </div>
                            <div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "12px" }}
                              >
                                <Button
                                  onClick={(e) =>
                                    countingPrice(e, "minus", idx, item)
                                  }
                                >
                                  -
                                </Button>
                                <div className={styles.qty}>
                                  {countPrices[idx]?.qty || 0}
                                </div>
                                <Button
                                  onClick={(e) =>
                                    countingPrice(e, "add", idx, item)
                                  }
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="d-flex flex-column  mt-3">
                      <div className="me-3 mb-4 text-start">
                        Total: <b>{formatRupiah(total)}</b>
                      </div>
                      <div className="d-flex justify-content-between gap-3 w-100">
                        <div className="w-50">
                          <Button
                            onClick={() => setOpenSeatModal(false)}
                            className={styles.btnBuy_outline}
                          >
                            Batal
                          </Button>
                        </div>
                        <div className="w-50">
                          <Button
                            onClick={handleBuyNow}
                            className={styles.btnBuy}
                          >
                            Beli Sekarang
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center">Tidak ada seat tersedia</div>
                )}
              </div>
            ) : (
              <>
                <Skeleton height={180} style={{ marginBottom: "12px" }} />
                <Skeleton height={180} />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
