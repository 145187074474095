import { riyuzAPI } from "../constant/api";

export const BookingServices = {
  postAddToCart(payload) {
    return riyuzAPI.post(`booking/addToCart`, payload);
  },
  postAddEnquiry() {
    return riyuzAPI.post(`booking/addEnquiry`);
  },
  postCheckout(payload) {
    return riyuzAPI.post(`booking/doCheckout`, payload);
  },
  getConfirm(id) {
    return riyuzAPI.get(`booking/confirm/${id}`);
  },
  getCancel(id) {
    return riyuzAPI.get(`booking/cancel/${id}`);
  },
  getCode(id) {
    return riyuzAPI.get(`booking/${id}`);
  },
  getThankyou(id) {
    return riyuzAPI.get(`booking/${id}/thankyou`);
  },
  getCheckout(id) {
    return riyuzAPI.get(`booking/${id}/checkout`);
  },
  getCheckStatus(id) {
    return riyuzAPI.get(`booking/${id}/check-status`);
  },
};
