import { riyuzAPI } from "../constant/api";

export const CorporatePackagesServices = {
  postCorporatePackage(payload) {
    return riyuzAPI.post(`corporate-packages`, payload);
  },
  getCorporatePackages(id, param) {
    return id
      ? riyuzAPI.get(`corporate-packages/${id}`)
      : riyuzAPI.get(`corporate-packages${param ? param : ""}`);
  },
  putCorporatePackage(payload) {
    return riyuzAPI.put(`corporate-packages/${payload.id}`, payload.body);
  },
  deleteCorporatepackage(id) {
    return riyuzAPI.delete(`corporate-packages/${id}`);
  },
};
