import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import styles from "./../styles/ProfileMobile.module.scss";
import personalCard from "./../assets/Profile/personalcard.svg";
import receipt from "./../assets/Profile/receipt.svg";
import card from "./../assets/Profile/card.svg";
import Menu from "../components/Profile/ProfileMobile/Menu";
import InformationUser from "../components/Profile/ProfileMobile/InformationUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrivateInformation from "../components/Profile/PrivateInformation";
import { AuthServices } from "../http/AuthHttp";
import { UserServices } from "../http/UserHttp";
import arrowLeft from "./../assets/Profile/arrow-left.svg";
import { isMobileCheck } from "../utils";
import InformationUmroh from "../components/Profile/InformationUmroh";
import Wishlist from "../components/Profile/Wishlist";
import HistoryTrx from "../components/Profile/HistoryTrx";
import BankAccountList from "../components/Profile/BankAccountList";
import Loading from "../components/common/Loading";
import { itineraryData } from "../common/dataDummy";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import WebLayout from "../components/Layout/WebLayout";
import { authService } from "../constant/authServices";
import { JamaahServices } from "../http/JamaahHttp";
import { TitleMenu } from "../components/Profile/ProfileMobile/TitleMenu";
import { ListKloterMobile } from "../components/Profile/ProfileMobile/ListKloterMobile";
export default function MyTrip() {
  const [detailForm, setDetailForm] = useState(false);
  const [batchList, setBatchList] = useState(null);
  const [showNav, setShowNav] = useState(true);
  const [data, setData] = useState();
  const [checked, setChecked] = useState(false);
  const [umrohBatchId, setUmrohBatchId] = useState(null);

  useEffect(() => {
    let data = localStorage.getItem("riyuz-auth");
    data = data && JSON.parse(data);
    console.log(data);
    if (data) getPilgrimData("batch", `?user_id=${data.id}`);
  }, []);

  const getPilgrimData = async (type, param) => {
    // setIsLoading(true);
    try {
      const response = await JamaahServices.getListJamaah(param);
      if (response) {
        // setIsLoading(false);
        console.log(response);
        setBatchList(response.data?.data);
      }
    } catch (err) {
      //   setIsLoading(false);
      console.log(err);
    }
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const itin = (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {itineraryData.map((item, index) => (
        <React.Fragment key={index}>
          <div style={{ paddingTop: 20 + "px", fontWeight: "bold" }}>
            {item.tanggal}
          </div>
          {item.jadwal.map((schedule, subIndex) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography
                  key={subIndex}
                  variant="h6"
                  fontSize={16}
                  fontWeight={"bold"}
                  component="span"
                >
                  {schedule.jam}
                </Typography>
                <Typography>{schedule.kegiatan}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </React.Fragment>
      ))}
    </Timeline>
  );

  return (
    <WebLayout showNav={showNav}>
      <Container className="d-block d-md-none">
        {detailForm ? (
          <>
            {/* {batchDetail && umrohBatchId && (
                      <>
                        <InformationUmroh
                          isMobile={isMobile}
                          data={batchDetail}
                          setIsLoading={setIsLoading}
                          typeMenu="trip"
                          getData={() =>
                            getPilgrimData(
                              "detail",
                              `?user_id=${data.id}&umroh_batch_id=${umrohBatchId}`
                            )
                          }
                        />
                      </>
                    )} */}
            <Col lg={8}>
              <div
                className={`${styles.menuShow} d-flex justify-content-between align-items-center`}
              >
                <h1 className="mb-2 mb-md-3">Itinerary</h1>
              </div>
              <div>Muthawwif: Rahmad Al Habib</div>
              <div className="mt-3" style={{ marginBottom: "82px" }}>
                <Row>
                  <Col lg={4} className="mb-3">
                    {/* <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b>Garuda Indonesia</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex">
                                        <div className="me-3">
                                          <Image
                                            src={departureDate}
                                            width={24}
                                            height={24}
                                          />
                                        </div>
                                        <div>
                                          <div>
                                            <b>CGK</b>
                                          </div>
                                          <div>14 November 2023 06:00</div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex">
                                        <div className="me-3">
                                          <Image
                                            src={arrivalDate}
                                            width={24}
                                            height={24}
                                          />
                                        </div>
                                        <div>
                                          <div>
                                            <b>JDH</b>
                                          </div>
                                          <div>16 November 2023 06:00</div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table> */}
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <TimelineItem>
                        {/* <TimelineOppositeContent
                                    sx={{ m: "auto 0" }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    9:30 am
                                  </TimelineOppositeContent> */}
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="info">
                            <LocalAirportIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            fontSize={16}
                          >
                            Garuda Indonesia
                          </Typography>
                          <Typography fontSize={12}>
                            CGK: 13 November 2023 06:00
                          </Typography>
                          <Typography fontSize={12}>
                            JED: 14 November 2023 06:00
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="warning">
                            <DirectionsBusIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          {/* <Typography variant="h6" component="span">
                                      Perjalanan Menuju Madinah
                                    </Typography>
                                    <Typography>
                                      18 November 2023 06:00
                                    </Typography> */}
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="success">
                            <HotelIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            fontSize={16}
                          >
                            Movenpick Makkah
                          </Typography>
                          <Typography fontSize={12}>
                            14 November 2023 11:00
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="warning">
                            <DirectionsBusIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{ py: "12px", px: 2 }}
                        ></TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="success">
                            <HotelIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography variant="h6" component="span" fontSize={16}>
                            Movenpick Madinah
                          </Typography>
                          <Typography fontSize={12}>
                            18 November 2023 12:00
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="warning">
                            <DirectionsBusIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{ py: "12px", px: 2 }}
                        ></TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineSeparator>
                          {/* Every icon have their color */}
                          <TimelineDot color="info">
                            <LocalAirportIcon />
                          </TimelineDot>
                          {/* Remove connector when reaching out itineraryData */}
                          {/* <TimelineConnector /> */}
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            fontSize={16}
                          >
                            Garuda Indonesia
                          </Typography>
                          <Typography fontSize={12}>
                            JED: 22 November 2023 16:00
                          </Typography>
                          <Typography fontSize={12}>
                            CGK: 23 November 2023 16:00
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Col>
                  <Col lg={8} className="mb-3">
                    <Box sx={{ height: "auto" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Agenda
                        <div style={{ float: "right" }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                              />
                            }
                            label=""
                          />
                        </div>
                      </div>
                      <Box sx={{ display: "flex" }}>
                        <Fade in={checked}>{itin}</Fade>
                      </Box>
                    </Box>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        ) : (
          <Col lg={8} className="mt-3">
            <ListKloterMobile
              data={batchList}
              getPilgrimData={getPilgrimData}
              setUmrohBatchId={setUmrohBatchId}
              setDetailForm={setDetailForm}
              styles={styles}
            />
          </Col>
        )}
      </Container>
    </WebLayout>
  );
}
