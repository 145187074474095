import { Col, Image } from "react-bootstrap";
import { iconArrive, iconBus, iconDeparture } from "../../constant/data";
import moment from "moment";

export default function HotelInformation(props) {
  const { start_date, end_date, adults, children } = props;
  return (
    <>
      <Col lg={6} className="mb-3">
        <span className="me-2">Check In</span>
      </Col>
      <Col lg={6} className="mb-3 text-end">
        {start_date}
      </Col>
      <Col lg={6} className="mb-4">
        <span className="me-2">Check Out</span>
      </Col>
      <Col lg={6} className="mb-4 text-end">
        {end_date}
      </Col>
      <Col lg={12} className="mb-3">
        <span className="me-2">
          <b>Detail Tamu</b>
        </span>
      </Col>
      <Col lg={6} className="mb-3">
        Dewasa
      </Col>
      <Col lg={6} className="mb-3 text-end">
        {adults}
      </Col>
      <Col lg={6} className="mb-3">
        Anak-Anak
      </Col>
      <Col lg={6} className="mb-3 text-end">
        {children}
      </Col>
    </>
  );
}
