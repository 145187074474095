import { Button, Col, Image } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import calendar from "./../../assets/Profile/calendar.svg";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import { formatRupiah, slugify } from "../../constant/lib";
import moment from "moment";
import { Link } from "react-router-dom";
import { BookingServices } from "../../http/BookingHttp";

export default function Wishlist(props) {
  const { isMobile, data } = props;

  const doCheckout = async (id) => {
    let payload = {
      code: id,
    };
    try {
      const response = await BookingServices.postCheckout(payload);
      if (response) {
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Col lg={8}>
      <div className={styles.menuShow}>
        <div className={styles.historyList}>
          {data?.length > 0 ? (
            data.map((item, idx) => {
              return (
                <div className={styles.historyItem}>
                  <div className={styles.historyDate}>
                    <span>
                      <Image src={calendar} alt="calendar" />
                    </span>
                    {moment(item.created_at).format("DD MMMM YYYY HH:mm")}
                  </div>
                  <div className={styles.historyContainer}>
                    <div className={styles.historyLeft}>
                      <div className={styles.historyImg}>
                        <Image src={item.service.image} alt="img" />
                      </div>
                      <div className={styles.historyWrap}>
                        {/* <div className={styles.historyStatus}>
                          {item.status}
                        </div> */}
                        <div className={styles.historyName}>
                          {item.service.title}
                        </div>
                        {/* <div className={styles.history}></div> */}
                        <div className={styles.historyPrice}>
                          {formatRupiah(item.service.sale_price)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.historyAction}>
                    <div className={styles.historyDetail}>
                      <Link
                        to={`/detail/${slugify(item.service.title)}/${
                          item.service.id
                        }`}
                      >
                        <Button>Detail Paket</Button>
                      </Link>
                    </div>
                    <div className={styles.historySee}>
                      <Button onClick={() => doCheckout(item.id)}>Bayar</Button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center mt-5">Tidak ada wishlist</div>
          )}
        </div>
      </div>
    </Col>
  );
}
