import { Form } from "react-bootstrap";
import styles from "./../../styles/ConfirmationBooking.module.scss";

export default function BankAccountInformationDesktop() {
  return (
    <div className={styles.paymentMethodDekstop}>
      <div className={styles.headingWrap}>
        <div className={styles.heading}>Pilih Metode Pembayaran</div>
        {/* <div className={styles.btnAdd}>Tambah Bank</div> */}
      </div>
      <div className={styles.inputGroupRadio}>
        <div className={styles.inputWrap}>
          {/* <div className={styles.circle}></div> */}
          <div className={styles.bankWrap}>
            <div className={styles.name}>Pembayaran Offline</div>
            {/* <div className={styles.noRek}>
                        009098989811 - A.N. John Doe
                      </div> */}
          </div>
        </div>
        <Form.Check type="radio" className={styles.inputBank} checked="true" />
      </div>
    </div>
  );
}
