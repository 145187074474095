import { Image } from "react-bootstrap";
import styles from "./../../styles/Home.module.scss";
import item from "./../../assets/Home/item.jpg";
import { Link } from "react-router-dom";
import { slugify } from "../../constant/lib";
import { STORAGE } from "../../common/utils";

export default function PopularPackageDesktop(props) {
  const { data } = props;
  // check image is null
  let imagePath = "";
  if (item.image != null) {
    imagePath = item.image.file_path;
  }

  const handleImageError = (e) => {
    e.target.src = STORAGE(imagePath).on_error;
  };

  return (
    <div className={`${styles.gridWrap} ${styles.gridWrap__desktop}`}>
      <Link
        to={`/tour/${slugify(data[0]?.title)}/${data[0]?.id}`}
        className={styles.item1}
      >
        <img
          src={STORAGE(data[0]?.image).src}
          onError={handleImageError}
          alt="img"
        />
        <p dangerouslySetInnerHTML={{ __html: data[0]?.title }} />
        <h3>{data[0]?.title}</h3>
      </Link>
      <Link
        to={`tour/${slugify(data[1]?.title)}/${data[1]?.id}`}
        className={styles.item2}
      >
        <img
          src={STORAGE(data[1]?.image).src}
          onError={handleImageError}
          alt="img"
        />
        <p dangerouslySetInnerHTML={{ __html: data[1]?.title }} />
        <h3>{data[1]?.title}</h3>
      </Link>
      <Link
        to={`tour/${slugify(data[2]?.title)}/${data[2]?.id}`}
        className={styles.item3}
      >
        <img
          src={STORAGE(data[2]?.image).src}
          onError={handleImageError}
          alt="img"
        />
        <p dangerouslySetInnerHTML={{ __html: data[2]?.title }} />
        <h3>{data[2]?.title}</h3>
      </Link>
      <Link
        to={`tour/${slugify(data[3]?.title)}/${data[3]?.id}`}
        className={styles.item4}
      >
        <img
          src={STORAGE(data[3]?.image).src}
          onError={handleImageError}
          alt="img"
        />
        <p dangerouslySetInnerHTML={{ __html: data[3]?.title }} />
        <h3>{data[3]?.title}</h3>
      </Link>
      <Link
        to={`tour/${slugify(data[4]?.title)}/${data[4]?.id}`}
        className={styles.item5}
      >
        <img
          src={STORAGE(data[4]?.image).src}
          onError={handleImageError}
          alt="img"
        />
        <p dangerouslySetInnerHTML={{ __html: data[4]?.title }} />
        <h3>{data[4]?.title}</h3>
      </Link>
    </div>
  );
}
