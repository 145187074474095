import { Form } from "react-bootstrap";

export function STORAGE(slug) {
  const first_url = "https://api.riyuz.com/storage/uploads/";
  const second_url = "https://api.riyuz.com/uploads/";
  let src = "";
  let on_error = "";
  if (slug != "" && slug != undefined && slug != null) {
    if (slug?.match("http") != "http") {
      src = first_url.concat(slug);
      on_error = second_url.concat(slug);
    } else {
      src = slug;
      on_error = slug;
    }
  }
  return { src, on_error };
}

export const iterativeInput = (label, type, payload, handleChange, styles) => {
  return (
    <div className={styles.inputGroup}>
      <label className={styles.text}>{label}</label>
      <div className={styles.inputWrap}>
        <input
          className={`${styles.inputBox} ${styles.inputBox_confirmation}`}
          type={type}
          id={payload}
          onChange={handleChange}
          placeholder={`Masukkan ${label}`}
        />
      </div>
    </div>
  );
};

export const iterativeSelect = (
  label,
  payload,
  options,
  handleChange,
  styles
) => {
  return (
    <div className={styles.inputGroup}>
      <label className={styles.text}>{label}</label>
      <div className={styles.inputWrap}>
        <Form.Select onChange={handleChange} id={payload} aria-label="">
          <option hidden value="">
            Pilih {label}
          </option>
          {options.map((item, idx) => {
            return (
              <option key={idx} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Form.Select>
      </div>
    </div>
  );
};

// import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";

export default function InputCommon(props) {
  const {
    icon,
    placeholder,
    onChange,
    id,
    type,
    data,
    onClick,
    value,
    label,
    name,
    disabled,
    required,
    feedbackInvalid,
    defaultValue,
  } = props;

  const numericInput = (
    <Form.Control
      type="number"
      className="mb-3"
      placeholder={placeholder}
      autoComplete={id}
      onChange={onChange}
      id={id}
      value={value && value}
      disabled={disabled ? true : false}
    />
  );

  const commonInput = (
    <Form.Control
      className="mb-3"
      placeholder={placeholder}
      autoComplete={id}
      onChange={onChange}
      id={id}
      value={value && value}
      disabled={disabled ? true : false}
    />
  );

  const dateInput = (
    <Form.Control
      type="date"
      placeholder={placeholder}
      onChange={onChange}
      id={id}
      value={value && value}
      disabled={disabled ? true : false}
      className="mb-3"
    />
  );

  // const timeInput = (
  //   <>
  //     <FormLabel>{label}</FormLabel>
  //     <br />
  //     <TimePicker
  //       onChange={onChange}
  //       value={value && value}
  //       locale="id-ID"
  //       id={id}
  //       className="mb-3"
  //       clockIcon={null}
  //       disableClock
  //       disabled={disabled ? true : false}
  //     />
  //   </>
  // );

  const textareaInput = (
    <Form.Control
      as="textarea"
      id={id}
      placeholder={placeholder}
      rows={5}
      onChange={onChange}
      className="mb-3"
      disabled={disabled ? true : false}
      value={value && value}
    ></Form.Control>
  );

  const radioInput = (
    <Form.Check
      inline
      type="radio"
      name={name}
      id={id}
      value={value && value}
      label={label}
      disabled={disabled ? true : false}
      onChange={onChange}
      className="mb-3"
    />
  );

  const fileInput = (
    <Form.Control
      type="file"
      id={id}
      accept="image/*"
      disabled={disabled ? true : false}
      onChange={onChange}
      className="mb-3"
    />
  );

  const selectInput = (
    <Form.Select
      onClick={onClick}
      onChange={onChange}
      id={id}
      disabled={disabled ? true : false}
      aria-label="Select Input"
      className="mb-3"
    >
      <option hidden>{placeholder}</option>
      {data ? (
        data?.length > 0 ? (
          data.map((item) => {
            return (
              <option
                value={item.id}
                disabled={
                  id === "room_mate"
                    ? item.remain === 0
                      ? true
                      : false
                    : id === "bus"
                    ? item.pilgrim_allocated > item.pilgrim_capacity
                      ? true
                      : false
                    : false
                }
                selected={value == item.id}
              >
                {item.name}
              </option>
            );
          })
        ) : (
          <option disabled>Tidak Ada Data</option>
        )
      ) : (
        <option value="">Loading...</option>
      )}
    </Form.Select>
  );

  const typeInput = (type) => {
    switch (type) {
      case "common":
        return commonInput;
      case "date":
        return dateInput;
      case "select":
        return selectInput;
      case "textarea":
        return textareaInput;
      case "radio":
        return radioInput;
      case "file":
        return fileInput;
      // case "time":
      //   return timeInput;
      case "number":
        return numericInput;
      default:
        return commonInput;
    }
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      {typeInput(type)}
      {feedbackInvalid && (
        <Form.Control.Feedback type="invalid">
          {feedbackInvalid}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
