import Skeleton from "react-loading-skeleton";
import { TitleMenu } from "./TitleMenu";
import { useEffect } from "react";

export const ListKloterMobile = (props) => {
  const { data, styles, setUmrohBatchId, setDetailForm, getPilgrimData } =
    props;
  return (
    <>
      <TitleMenu title="List Kloter / Kode Booking" styles={styles} />
      {data ? (
        data?.length > 0 ? (
          <>
            {data.map((item, index) => {
              return (
                <div className={styles.listKloter}>
                  <div className={styles.listKloterItem}>
                    <div className={styles.listKloterItemTitle}>
                      {item.umroh_batch_id.name}
                    </div>
                    <div className={styles.listKloterItemDesc}>
                      <div className={styles.listKloterItemInfo}>
                        Kode Booking
                      </div>
                      <div className={styles.listKloterItemCode}>
                        {item.booking_code == "null" ||
                        item.booking_code == null
                          ? "-"
                          : item.booking_code}
                      </div>
                    </div>
                    <div className={styles.listKloterCta}>
                      <button
                        onClick={() => {
                          getPilgrimData(
                            "detail",
                            `?user_id=${data.id}&umroh_batch_id=${item.umroh_batch_id?.id}`
                          );
                          setUmrohBatchId(item.umroh_batch_id?.id);
                          setDetailForm(true);
                        }}
                        className={styles.listKloterCtaBtn}
                      >
                        Detail Kloter
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="py-2 text-center" style={{ fontSize: "12px" }}>
            Tidak Ada Kloter
          </div>
        )
      ) : (
        <Skeleton count={3} style={{ marginBottom: "12px" }} height={135} />
      )}
    </>
  );
};
