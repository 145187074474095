import { Image } from "react-bootstrap";
import styles from "./../../../styles/Profile.module.scss";

export default function FormInput(props) {
  const { label, icon, id, handleChange, type, isTextArea, value, disable } =
    props;
  return (
    <>
      <label className="mb-2">{label}</label>
      <div className="position-relative">
        {/* <Image src={icon} alt="icon" className={styles.imgIcon} /> */}
        {isTextArea ? (
          <textarea
            id={id}
            onChange={handleChange}
            rows={5}
            className="w-100"
            value={value}
          />
        ) : (
          <input
            className="w-100"
            value={value}
            id={id}
            onChange={handleChange}
            type={type}
            disabled={disable}
          />
        )}
      </div>
    </>
  );
}
