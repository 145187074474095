import Skeleton from "react-loading-skeleton";
import CardProject from "../Card";
import styles from "./../../styles/Home.module.scss";
import { Col } from "react-bootstrap";

export default function SlideCardDesktop(props) {
  const { data, type, isLoading } = props;
  return (
    <>
      {data?.length === 0 &&
        isLoading &&
        new Array(4).fill(0).map((item, index) => {
          return (
            <Col lg={3} md={6} sm={12} key={index}>
              <Skeleton height={230} borderRadius={10} className="mb-2" />
              <Skeleton height={22} className="mb-1" />
              <Skeleton height={22} className="mb-1" />
              <Skeleton height={22} className="mb-3" />
            </Col>
          );
        })}
      {data?.length > 0 &&
        !isLoading &&
        data.map((item, index) => {
          return (
            <Col lg={3} md={6} sm={12} key={index} className={styles.recCard}>
              <CardProject item={item} type={type} />
            </Col>
          );
        })}
    </>
  );
}
