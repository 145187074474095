import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Col, Row } from "react-bootstrap";
import InputCommon from "../../../common/utils";

export default function EquipmentInformation(props) {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 6 ? null : 6)}
      >
        <b>Perlengkapan</b>
        {formWizardStep == 6 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 6 && (
        <div className={styles.collapsibleContent}>
          <Row className="my-4">
            <Col lg={6}>
              <InputCommon
                type="select"
                label="Koper Jamaah"
                placeholder="Koper Jamaah"
                id="briefcase"
                onChange={(e) => handleChangeFamilyData(e)}
                onClick={() => null}
                value={payloadDataCreateUser?.briefcase}
                data={[
                  { name: 0, id: "0" },
                  { name: 1, id: "1" },
                ]}
                // value={type === "edit" ? editData?.briefcase : undefined}
                // data={commonOptionTwo}
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="select"
                label="Perlengkapan Umroh"
                placeholder="Masukkan Perlangkapaan Umroh"
                id="equipment"
                onChange={(e) => handleChangeFamilyData(e)}
                onClick={() => null}
                value={payloadDataCreateUser?.equipment}
                // value={type === "edit" ? editData?.equipment : undefined}
                data={[
                  { name: 0, id: "0" },
                  { name: 1, id: "1" },
                ]}
              />
            </Col>
            <Col lg={6}>
              {/* {type === "edit" ? ( */}
              <>
                {/* {editData && ( */}
                <>
                  {(payloadDataCreateUser.briefcase !== undefined ||
                    payloadDataCreateUser.equipment !== undefined) && (
                    <InputCommon
                      type="select"
                      label="Shipment"
                      placeholder="Pilih Shipment"
                      id="shipment"
                      onChange={(e) => handleChangeFamilyData(e)}
                      // value={type === "edit" ? editData?.shipment : undefined}
                      onClick={() => null}
                      value={payloadDataCreateUser?.shipment}
                      data={[
                        { id: 0, name: "Pickup" },
                        { id: 1, name: "Dikirim" },
                      ]}
                    />
                  )}
                  <InputCommon
                    type="common"
                    label={
                      payloadDataCreateUser?.shipment == 0
                        ? "Notes"
                        : "Alamat Shipment"
                    }
                    placeholder="Masukkan Alamat Shipment"
                    id="shipment_remarks"
                    onChange={(e) => handleChangeFamilyData(e)}
                    value={payloadDataCreateUser?.shipment_remarks}
                    // value={
                    //   type === "edit" ? editData?.shipment_remarks : undefined
                    // }
                  />
                </>
                {/* )} */}
              </>
              {/* ) : ( */}
              <>
                {/* {data && ( */}
                <>
                  {/* {(data.briefcase === 1 || data.equipment === 1) && ( */}
                  {/* <InputCommon
                      type="select"
                      label="Shipment"
                      placeholder="Pilih Shipment"
                      id="shipment"
                      onChange={(e) => handleChangeFamilyData(e)}
                      // value={type === "edit" ? editData?.shipment : undefined}
                      onClick={() => null}
                      data={[
                        { id: 0, name: "Pickup" },
                        { id: 1, name: "Dikirim" },
                      ]}
                    /> */}
                  {/* )} */}
                  {payloadDataCreateUser.shipment == 1 && (
                    <InputCommon
                      type="textarea"
                      label="Alamat Shipment"
                      placeholder="Masukkan Alamat Shipment"
                      id="shipment_address"
                      onChange={(e) => handleChangeFamilyData(e)}
                      value={payloadDataCreateUser?.shipment_address}
                      // value={
                      //   type === "edit" ? editData?.shipment_address : undefined
                      // }
                    />
                  )}
                </>
                {/* )} */}
              </>
              {/* )} */}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
