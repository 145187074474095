import { Image } from "react-bootstrap";
import { iconArrive, iconBus, iconDeparture } from "../../constant/data";
import moment from "moment";

export default function BusInformation(props) {
  const { total_rent, start_date, end_date, departure, arrival } = props;
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>Jumlah Bus</div>
        <div>{total_rent}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>Start Date</div>
        <div>{start_date}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>End Date</div>
        <div>{end_date}</div>
      </div>
      <div className="mt-4">
        <b>Informasi Tujuan</b>
      </div>
      <div className="mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Image className="me-2" src={iconBus} width={24} height={24} />
          <span>
            {moment(departure).format("YYYY-MM-DD")} -{" "}
            {moment(arrival).format("YYYY-MM-DD")}
          </span>
        </div>
      </div>
    </>
  );
}
