import { Image } from "react-bootstrap";
import styles from "./../../styles/DetailProduct.module.scss";
import location from "./../../assets/location.svg";
export default function Itinerary(props) {
  const { detail } = props;
  return (
    <div className={styles.contentBox}>
      <div className={styles.heading}>Itinerary</div>
      <div className={styles.hotel}>
        {detail.itinerary?.length > 0 &&
          detail.itinerary?.map((item, idx) => (
            <>
              <div className={styles.iconBox}>
                <div className={styles.titleIconBox}>
                  <Image src={location} alt="" />
                  <div>
                    <div>
                      {item.title}-{item.desc}
                    </div>
                  </div>
                </div>
                <div className={styles.detailContent}>{item.content}</div>
              </div>
            </>
          ))}
      </div>
      {/* <div className={styles.hotel}>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={location} alt="" />
                        Mekkah
                      </div>
                      <div className={styles.detailIconBox}>Hotel 1 Mekkah</div>
                    </div>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={receiptBlack} alt="" />
                        Fasilitas Umum
                      </div>
                      <div className={styles.detailIconBox}>
                        <ul>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={receiptBlack} alt="" />
                        Fasilitas Lainnya
                      </div>
                      <div className={styles.detailIconBox}>
                        Tidak Ada Fasilitas Lainnya
                      </div>
                    </div>
                  </div> */}
      {/* <div className={styles.hotel}>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={location} alt="" />
                        Mekkah
                      </div>
                      <div className={styles.detailIconBox}>Hotel 1 Mekkah</div>
                    </div>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={receiptBlack} alt="" />
                        Fasilitas Umum
                      </div>
                      <div className={styles.detailIconBox}>
                        <ul>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                          <li>Hotel 1 Mekkah</li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.iconBox}>
                      <div className={styles.titleIconBox}>
                        <Image src={receiptBlack} alt="" />
                        Fasilitas Lainnya
                      </div>
                      <div className={styles.detailIconBox}>
                        Tidak Ada Fasilitas Lainnya
                      </div>
                    </div>
                  </div> */}
    </div>
  );
}
