import { Button, Col, Image } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import trash from "./../../assets/Profile/trash.svg";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";

export default function BankAccountList(props) {
  const { isMobile, data } = props;
  return (
    <Col lg={8}>
      <div className={styles.menuShow}>
        <div className={styles.accountBankText}>
          <h1 className="mb-4 mb-md-0">Akun Bank</h1>
          {isMobile ? "" : <Button>Tambah Akun</Button>}
        </div>
        <div className={styles.bankList}>
          <div className={styles.bankItem}>
            <div className={styles.bankCircle}></div>
            <div className={styles.bankInfo}>
              <div className={styles.bankName}>Bank Mandiri</div>
              <div className={styles.bankNumber}>
                009098989811 - A.N. John Doe
              </div>
            </div>
            <div className={styles.bankAction}>
              <Image src={trash} alt="trash" />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
