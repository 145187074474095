import { Image, Form, Button } from "react-bootstrap";
import styles from "../../../styles/Search.module.scss";
import star from "../../../assets/Home/star.svg";
import vector from "../../../assets/Vector.svg";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function HotelSearch(props) {
  // You can access data from props like this
  const {
    locationList,
    filterData,
    priceFilter,
    setPriceParam,
    handleChange,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;
  const location = useLocation();

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    const starRate = queryParams.getAll("star_rate[]");
    starRate.forEach((catId) => {
      const checkbox = document.querySelector(
        `input[name="star_rate[]"][value="${catId}"]`
      );
      if (checkbox) checkbox.checked = true;
    });

    // Handle review_score[]
    const reviewScores = queryParams.getAll("review_score[]");
    reviewScores.forEach((score) => {
      const checkbox = document.querySelector(
        `input[name="review_score[]"][value="${score}"]`
      );
      if (checkbox) checkbox.checked = true;
    });

    // Handle attrs[1][]
    const attrs5 = queryParams.getAll("attrs[5][]");
    attrs5.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[5][]"][value="${attr}"]`
      );
      if (checkbox) checkbox.checked = true;
    });

    // Handle attrs[2][]
    const attrs6 = queryParams.getAll("attrs[6][]");
    attrs6.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[6][]"][value="${attr}"]`
      );
      if (checkbox) checkbox.checked = true;
    });

    const attrs7 = queryParams.getAll("attrs[7][]");
    attrs7.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[7][]"][value="${attr}"]`
      );
      if (checkbox) checkbox.checked = true;
    });

    const styleTravel = queryParams.getAll("style_travel");
    styleTravel.forEach((catId) => {
      const checkbox = document.querySelector(
        `input[name="style_travel"][value="${catId}"]`
      );
      if (checkbox) checkbox.checked = true;
    });
    

    // Handle start and end dates
    // const startDate = queryParams.get("start");
    // const endDate = queryParams.get("end");
    // if (startDate && endDate) {
    //   setStartDate(new Date(startDate));
    //   setEndDate(new Date(endDate));
    // }
  }, [location.search]);

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const handleFilterByPrice = () => {
    // let send = `&price_range=${
    //   priceFilter.min_price
    //     ? priceFilter.min_price
    //     : filterData.length > 0 && filterData[0].min_price
    // }%3B${
    //   priceFilter.max_price
    //     ? priceFilter.max_price
    //     : filterData.length > 0 && filterData[0].max_price
    // }`;
    // setPriceParam(send);
    console.log("tes");
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className={styles.filterBox}>
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Harga</Form.Label>
            <div className={styles.priceGroup}>
              <Form.Control
                onChange={(e) => handleChange(e)}
                id="min_price"
                name="min_price"
                type="text"
                // value={minPrice}
                placeholder="MIN"
              />
              -
              <Form.Control
                onChange={(e) => handleChange(e)}
                id="max_price"
                name="max_price"
                type="text"
                placeholder="MAX"
                // value={maxPrice}
              />
            </div>
            <Button
              onClick={handleFilterByPrice}
              className="btnYellow mt-3 mt-md-4 w-100"
            >
              <Image src={vector} />
              Apply Harga
            </Button>
          </Form.Group>
          {/* <Form.Group className="mb-4">
            <Form.Label>Check In & Check Out</Form.Label>
            <div className={styles.priceGroup}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
            </div>
          </Form.Group> */}
          <Form.Label className="mb-2">Hotel</Form.Label>
          {[...Array(5)].map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  type="checkbox"
                  id="star_rate[]"
                  name="star_rate[]"
                  value={5 - index}
                  onChange={(e) => handleChange(e)}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>
                  {[...Array(5 - index)].map(() => {
                    return <Image src={star}></Image>;
                  })}
                </div>
              </div>
            );
          })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Review Score</Form.Label>
          {[...Array(5)].map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  type="checkbox"
                  value={5 - index}
                  name="review_score[]"
                  id="review_score[]"
                  onChange={(e) => handleChange(e)}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>
                  {[...Array(5 - index)].map(() => {
                    return <Image src={star}></Image>;
                  })}
                </div>
              </div>
            );
          })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Tipe Property</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[0]?.terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e)}
                    value={item.id}
                    id="attrs[5][]"
                    name="attrs[5][]"
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Fasilitas</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[1]?.terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e)}
                    value={item.id}
                    name="attrs[6][]"
                    id="attrs[6][]"
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Servis Hotel</Form.Label>
          {filterData?.length > 0 &&
            filterData &&
            filterData[3].data &&
            filterData[3].data[2]?.terms?.map((item, index) => {
              return (
                <div className={styles.starGroup}>
                  <input
                    onChange={(e) => handleChange(e)}
                    s
                    value={item.id}
                    id="attrs[7][]"
                    name="attrs[7][]"
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  ></input>
                  <div className={styles.star}>{item.name}</div>
                </div>
              );
            })}
          <Form.Group className="mb-4"></Form.Group>
          {locationList?.length > 0 && (
            <>
              <Form.Label className="mb-2">Lokasi</Form.Label>
              {locationList &&
                locationList[3].data &&
                locationList[3].data[2]?.terms?.map((item, index) => {
                  return (
                    <div className={styles.starGroup}>
                      <input
                        onChange={(e) =>
                          handleChange(e, "style_travel", item.id)
                        }
                        value={item.id}
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                      ></input>
                      <div className={styles.star}>{item.name}</div>
                    </div>
                  );
                })}
            </>
          )}
          {/* <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Check In - Out</Form.Label>
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label className="mb-2">Guests</Form.Label> */}
        </Form>
      </div>
    </>
  );
}
