import { Col, Row } from "react-bootstrap";
import styles from "./../../styles/Home.module.scss";
import { isMobileCheck } from "../../utils";
import SlideCardMobile from "./SlideCardMobile";
import SlideCardDesktop from "./SlideCardDesktop";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function PlaceList(props) {
  const { title, data, type, isLoading } = props;
  // console.log(data, type)
  return (
    <div className={styles.recomendation}>
      <Row className={styles.recWarp}>
        {isMobileCheck() ? (
          <SlideCardMobile data={data} type={type} isLoading={isLoading} />
        ) : (
          <SlideCardDesktop data={data} type={type} isLoading={isLoading} />
        )}
      </Row>
      <Link to={`/search?type=${type.toLowerCase()}`} className={`${styles.seeAll} w-100`}>
        Lihat Semua
      </Link>
    </div>
  );
}
