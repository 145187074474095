import { Button, Image, Modal } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import eye from "./../assets/Auth/eye.svg";
import password from "./../assets/Auth/password.svg";
import username from "./../assets/Auth/personalcard.svg";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect, useRef, useState } from "react";
import { AuthServices } from "../http/AuthHttp";
import logo from "./../logo.png";

export default function Register() {
  const navigate = useNavigate();
  const passwordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [textResponse, setTextResponse] = useState({
    heading: "",
    text: "",
    type: "",
  });
  const [data, setData] = useState({
    email: "",
    password: "",
    // username: "",
    first_name: "",
    last_name: "",
    term: "1",
  });

  useEffect(() => {
    if (showPassword) {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  }, [showPassword]);

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      navigate("/");
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const newResponse = { ...textResponse };
    try {
      const response = await AuthServices.register(data);
      if (response) {
        // console.log(response);
        setIsLoading(false);
        if (response.status === 0) {
          navigate(
            `/login?type=email&email=${data.email}&password=${data.password}`
          );
        } else {
          // let cookie = {
          //   token: response.token,
          //   id: response.user.id,
          //   name: response.user.first_name,
          // };
          // localStorage.setItem("riyuz-auth", JSON.stringify(cookie));
          newResponse.heading = "Berhasil";
          newResponse.text = "Silakan login sekarang juga!";
          newResponse.type = "success";
          setTextResponse(newResponse);
          setShowModalResponse(true);
        }
      }
    } catch (err) {
      console.log(err);
      newResponse.heading = "Gagal";
      newResponse.text = "Mohon ulangi registrasi anda";
      newResponse.type = "danger";
      setTextResponse(newResponse);
      setShowModalResponse(true);
      setIsLoading(false);
    }
  };

  const actionBtn = () => {
    setShowModalResponse(false);
    if (textResponse.type === "success") {
      navigate(
        `/login?type=email&email=${data.email}&password=${data.password}`
      );
    }
  };

  return (
    <>
      <AuthLayout isLoading={isLoading}>
        <div className={styles.circle}>
          <Link to="/">
            <Image src={logo} width={100} height={100} alt="" />
          </Link>
        </div>
        <div className={styles.form}>
          <div className={styles.title}>Register</div>
          <div className={styles.text}>
            Registrasikan akun anda untuk memberli paket
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.text}>No. KTP</label>
              <div className={styles.inputWrap}>
                <Image src={username} alt="username" />
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  type="text"
                  placeholder="Masukkan Nomor KTP"
                  id="no_ktp"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className={styles.inputGroup}>
                  <label className={styles.text}>First Name</label>
                  <div className={styles.inputWrap}>
                    <Image src={username} alt="username" />
                    <input
                      onChange={handleChange}
                      className={styles.inputBox}
                      type="text"
                      placeholder="First Name"
                      id="first_name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.inputGroup}>
                  <label className={styles.text}>Last Name</label>
                  <div className={styles.inputWrap}>
                    <Image src={username} alt="username" />
                    <input
                      onChange={handleChange}
                      className={styles.inputBox}
                      type="text"
                      placeholder="Last Name"
                      id="last_name"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.text}>No. HP</label>
              <div className={styles.inputWrap}>
                <Image src={username} alt="username" />
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  type="text"
                  placeholder="Masukkan Nomor HP"
                  id="phone"
                  required
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.text}>Email</label>
              <div className={styles.inputWrap}>
                <Image src={email} alt="email" />
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  type="email"
                  placeholder="user@email.com"
                  id="email"
                  required
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.text}>Password</label>
              <div className={styles.inputWrap}>
                <Image src={password} alt="password" />
                <input
                  onChange={handleChange}
                  className={`${styles.inputBox} ${styles.inputBox__password}`}
                  type="password"
                  id="password"
                  ref={passwordRef}
                  placeholder="Password Kamu"
                  required
                />
                <Image
                  className={styles.password}
                  onClick={handleShowPassword}
                  src={eye}
                  alt="eye"
                />
              </div>

              <Button variant="primary" type="submit">
                Daftar
              </Button>
            </div>
          </form>
          <div className={`${styles.sign} mb-4`}>
            Sudah punya akun?{" "}
            <Link to="/login">
              <b>Sign In</b>
            </Link>
          </div>
          <div className={styles.sign}>
            <Link to="/">
              <b>Kembali ke beranda</b>
            </Link>
          </div>
        </div>
      </AuthLayout>
      <Modal
        centered
        show={showModalResponse}
        onHide={() => setShowModalResponse(false)}
      >
        <Modal.Body className="p-5">
          <div className="w-100 text-center">
            <h3 className="mb-2">{textResponse.heading} Registrasi!</h3>
            <div className="mb-5">{textResponse.text}</div>

            <div>
              <Button variant="primary" onClick={actionBtn}>
                {textResponse.type === "success"
                  ? "Login"
                  : "Ulangi Registrasi"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
