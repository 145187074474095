import { useState } from "react";
import { Col, Image } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import user from "./../../assets/Profile/users.svg";
import personalCard from "./../../assets/Profile/personalcard.svg";
import receipt from "./../../assets/Profile/receipt.svg";
import card from "./../../assets/Profile/card.svg";
import personalCardActive from "./../../assets/Profile/personalcardActive.svg";
import receiptActive from "./../../assets/Profile/receiptActive.svg";
import cardActive from "./../../assets/Profile/cardActive.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthServices } from "../../http/AuthHttp";
import ModalCenter from "../Layout/Modal";
export default function NavigationProfile(props) {
  const { data, handleNavigateMenu, currMenu } = props;
  const router = useLocation();
  const navigate = useNavigate();
  const currUrl = router.pathname.split("/");
  const [showModal, setShowModal] = useState(false);

  const navigationMenu = (url, curr, param, active, unActive, text) => {
    return (
      <div
        onClick={() => handleNavigateMenu(url)}
        className={`${styles.navItem} ${
          currMenu === url && styles.navItem__active
        }`}
      >
        <Image src={currMenu === url ? active : unActive} alt="personal" />
        {text}
      </div>
    );
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthServices.logout();
      if (response) {
        localStorage.removeItem("riyuz-auth");
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Col lg={4} className="mb-5">
      <div className={styles.navigationLeft}>
        <div className={styles.profileWrap}>
          <div className={styles.profile}>
            <div className={styles.profileCircle}>
              <Image src={user} alt="person" />
            </div>
          </div>
          <div className={styles.profileInformation}>
            <div className={styles.name}>{data?.name}</div>
            <div className={styles.subName}>{data.bio}</div>
          </div>
        </div>
        <div className={styles.navList}>
          {navigationMenu(
            "personal",
            currUrl[2],
            "information",
            personalCardActive,
            personalCard,
            "Informasi Pribadi"
          )}
          {navigationMenu(
            "wishlist",
            currUrl[2],
            "wishlist",
            personalCardActive,
            personalCard,
            "Wishlist Anda"
          )}
          {navigationMenu(
            "my-trip",
            currUrl[2],
            "my-trip",
            personalCardActive,
            personalCard,
            "My trip"
          )}
          {/* {navigationMenu(
            "my-packages",
            currUrl[2],
            "my-packages",
            personalCardActive,
            personalCard,
            "My Packages"
          )}
          {navigationMenu(
            "my-equipment",
            currUrl[2],
            "my-equipment",
            personalCardActive,
            personalCard,
            "My Equipment"
          )} */}
          {navigationMenu(
            "my-retail",
            currUrl[2],
            "my-retail",
            personalCardActive,
            personalCard,
            "My Retail"
          )}
          {navigationMenu(
            "history",
            currUrl[2],
            "history",
            receiptActive,
            receipt,
            "Riwayat Transaksi"
          )}
          {navigationMenu(
            "bank",
            currUrl[2],
            "bank-account",
            cardActive,
            card,
            "Akun Bank"
          )}
          <div
            onClick={() => setShowModal(true)}
            className={`${styles.navItem__logout} ${styles.navItem}`}
          >
            Logout
          </div>
        </div>
      </div>
      <ModalCenter
        type="confirmation"
        callback={handleLogout}
        setShow={showModal}
        setHide={() => setShowModal(false)}
        setTitle="Logout"
        setBody="Anda akan loguot dari akun anda, apakah yakin?"
      />
    </Col>
  );
}
