export const API_URL = "https://api.riyuz.com/api/";
export const API_URL_V2 = "https://backendriyuz.devgabut.com/api/";
export const IMAGE_URL = "https://api.riyuz.com/uploads/";
export const typeService = [
  { title: "Tour", icon: "https://iconscout.com/icons/tour" },
  { title: "Hotel", icon: "https://iconscout.com/icons/hotel" },
  {
    title: "Tiket Pesawat",
    icon: "https://iconscout.com/icons/airplane-ticket",
  },
  { title: "Haji", icon: "https://iconscout.com/icons/hajj" },
  { title: "Umroh", icon: "https://iconscout.com/icons/umrah" },
  { title: "Tiket Kereta", icon: "https://iconscout.com/icons/train-ticket" },
  { title: "Tiket Bus", icon: "https://iconscout.com/icons/bus-ticket" },
  { title: "Visa", icon: "https://iconscout.com/icons/visa" },
  { title: "Catering", icon: "https://iconscout.com/icons/catering" },
];

export const groupedTableStructure = [
  {
    id: 1,
    group: "Primary Information",
    fields: [
      {
        id: 2,
        name: "group_id",
        title: "Group",
        type_field: "select",
      },
      {
        id: 3,
        name: "umroh_batch_id",
        title: "Kloter",
        type_field: "select",
      },
      {
        id: 5,
        name: "booking_id",
        title: "Booking ID",
        type_field: "text",
      },
      {
        id: 6,
        name: "corporate_id",
        title: "Company",
        type_field: "select",
      },
      {
        id: 7,
        name: "leader_id",
        title: "Leader",
        type_field: "select",
      },
      {
        id: 8,
        name: "member_claim_id",
        title: "Member Claim",
        type_field: "select",
      },
    ],
  },
  {
    id: 2,
    group: "Personal Information",
    fields: [
      {
        id: 9,
        name: "member_relation",
        title: "Hubungan Member",
        type_field: "text",
      },
      {
        id: 10,
        name: "passport_berlaku",
        title: "Passport Berlaku",
        type_field: "date",
      },
      {
        id: 11,
        name: "pergi_umroh",
        title: "Pergi Umroh",
        type_field: "boolean",
      },
      {
        id: 12,
        name: "plakat",
        title: "Plakat",
        type_field: "text",
      },
      {
        id: 13,
        name: "ukuran_baju",
        title: "Ukuran Baju",
        type_field: "text",
      },
      { id: 14, name: "pic_id", title: "PIC", type_field: "text" },
      {
        id: 15,
        name: "booking_code",
        title: "Booking Code",
        type_field: "text",
      },
      {
        id: 16,
        name: "room_type",
        title: "Room Type",
        type_field: "text",
      },
      {
        id: 17,
        name: "room_mate",
        title: "Room Mate",
        type_field: "text",
      },
      {
        id: 18,
        name: "no_ktp",
        title: "No KTP",
        type_field: "text",
      },

      {
        id: 19,
        name: "no_passport",
        title: "No Passport",
        type_field: "text",
      },
      {
        id: 20,
        name: "nama_lengkap",
        title: "Nama Lengkap",
        type_field: "text",
      },
      {
        id: 21,
        name: "nama_ayah_kandung",
        title: "Nama Ayah Kandung",
        type_field: "text",
      },
      {
        id: 22,
        name: "tempat_lahir",
        title: "Tempat Lahir",
        type_field: "text",
      },
      {
        id: 23,
        name: "tanggal_lahir",
        title: "Tanggal Lahir",
        type_field: "date",
      },
      {
        id: 24,
        name: "jenis_kelamin",
        title: "Jenis Kelamin",
        type_field: "text",
      },
      {
        id: 25,
        name: "alamat",
        title: "Alamat",
        type_field: "textarea",
      },
      {
        id: 26,
        name: "telp",
        title: "No HP",
        type_field: "number",
      },
      {
        id: 27,
        name: "no_unit",
        title: "No Unit",
        type_field: "text",
      },
      {
        id: 28,
        name: "rt",
        title: "RT",
        type_field: "text",
      },
      {
        id: 29,
        name: "rw",
        title: "RW",
        type_field: "text",
      },
      {
        id: 30,
        name: "alamat_surat_menyurat",
        title: "Alamat Surat Menyurat",
        type_field: "textarea",
      },
      {
        id: 31,
        name: "desa_kelurahan",
        title: "Desa Kelurahan",
        type_field: "text",
      },
      {
        id: 32,
        name: "kecamatan",
        title: "Kecamatan",
        type_field: "text",
      },
      {
        id: 33,
        name: "kabupaten_kodya",
        title: "Kabupaten Kodya",
        type_field: "text",
      },
      {
        id: 34,
        name: "provinsi",
        title: "Provinsi",
        type_field: "text",
      },
      {
        id: 35,
        name: "kode_pos",
        title: "Kode Pos",
        type_field: "number",
      },
      {
        id: 36,
        name: "pendidikan",
        title: "Pendidikan",
        type_field: "text",
      },
      {
        id: 37,
        name: "pekerjaan",
        title: "Pekerjaan",
        type_field: "text",
      },
      {
        id: 38,
        name: "status_perkawinan",
        title: "Status Perkawinan",
        type_field: "select",
      },
      {
        id: 39,
        name: "pergi_haji",
        title: "Pergi Haji",
        type_field: "boolean",
      },
      {
        id: 40,
        name: "nama_mahram",
        title: "Nama Mahram",
        type_field: "text",
      },
      {
        id: 41,
        name: "hubungan_mahram",
        title: "Hubungan Mahram",
        type_field: "text",
      },
      {
        id: 42,
        name: "golongan_darah",
        title: "Golongan Darah",
        type_field: "text",
      },
      {
        id: 43,
        name: "status_bph",
        title: "Status BPH",
        type_field: "text",
      },
      {
        id: 44,
        name: "program_pilihan",
        title: "Program Pilihan",
        type_field: "text",
      },
      {
        id: 45,
        name: "tanggal_keberangkatan",
        title: "Tanggal Keberangkatan",
        type_field: "date",
      },
    ],
  },
  {
    id: 3,
    group: "Physical Attributes",
    fields: [
      {
        id: 46,
        name: "rambut",
        title: "Rambut",
        type_field: "text",
      },
      {
        id: 47,
        name: "alis",
        title: "Alis",
        type_field: "text",
      },
      {
        id: 48,
        name: "hidung",
        title: "Hidung",
        type_field: "text",
      },
      {
        id: 49,
        name: "tinggi",
        title: "Tinggi",
        type_field: "number",
      },
      {
        id: 50,
        name: "berat",
        title: "Berat",
        type_field: "number",
      },
      {
        id: 51,
        name: "muka",
        title: "Muka",
        type_field: "text",
      },
      {
        id: 52,
        name: "ukuran_baju_koko",
        title: "Ukuran Baju Koko",
        type_field: "text",
      },
    ],
  },
  {
    id: 4,
    group: "Documents",
    fields: [
      {
        id: 53,
        name: "foto_ktp",
        title: "Foto KTP",
        type_field: "file",
      },
      {
        id: 54,
        name: "pasfoto",
        title: "Pasfoto",
        type_field: "file",
      },
      {
        id: 55,
        name: "is_mahram",
        title: "Is Mahram",
        type_field: "boolean",
      },
      {
        id: 56,
        name: "roommate_relation",
        title: "Hubungan Roommate",
        type_field: "text",
      },
      {
        id: 57,
        name: "buku_nikah",
        title: "Buku Nikah",
        type_field: "file",
      },
      {
        id: 58,
        name: "kartu_keluarga_1",
        title: "Kartu Keluarga 1",
        type_field: "file",
      },
      {
        id: 59,
        name: "kartu_keluarga_2",
        title: "Kartu Keluarga 2",
        type_field: "file",
      },
      {
        id: 60,
        name: "ttd_digital",
        title: "TTD Digital",
        type_field: "file",
      },
    ],
  },
  {
    id: 5,
    group: "Administrative",
    fields: [
      {
        id: 61,
        name: "parent_id",
        title: "Parent",
        type_field: "text",
      },
      {
        id: 62,
        name: "create_user",
        title: "Create User",
        type_field: "text",
      },
      {
        id: 63,
        name: "update_user",
        title: "Update User",
        type_field: "text",
      },
      {
        id: 64,
        name: "status",
        title: "Status",
        type_field: "text",
      },
    ],
  },
  {
    id: 6,
    group: "Equipment",
    fields: [
      {
        id: 65,
        name: "briefcase",
        title: "Koper",
        type_field: "text",
      },
      {
        id: 66,
        name: "equipment",
        title: "Perlengkapan",
        type_field: "text",
      },
      {
        id: 67,
        name: "equipment_remark",
        title: "Catatan Perlengkapan",
        type_field: "text",
      },
      {
        id: 68,
        name: "shipment",
        title: "Pengiriman",
        type_field: "text",
      },
      {
        id: 69,
        name: "shipment_remark",
        title: "Catatan Pengiriman",
        type_field: "text",
      },
      {
        id: 70,
        name: "shipment_receipt",
        title: "Bukti Pengiriman",
        type_field: "file",
      },
      {
        id: 71,
        name: "shipment_delivery_receipt",
        title: "Bukti Penerimaan",
        type_field: "file",
      },
      {
        id: 72,
        name: "add_notes",
        title: "Catatan Tambahan",
        type_field: "text",
      },
      {
        id: 73,
        name: "food_request",
        title: "Permintaan Makanan",
        type_field: "text",
      },
    ],
  },
  {
    id: 7,
    group: "Travel",
    fields: [
      { id: 74, name: "bus", type_field: "text" },
      {
        id: 75,
        name: "ket_promo",
        title: "Keterangan Promo",
        type_field: "text",
      },
      {
        id: 76,
        name: "nama_sesuai_passport",
        title: "Nama Sesuai Passport",
        type_field: "text",
      },
      {
        id: 77,
        name: "dokumen_passport",
        title: "Dokumen Passport",
        type_field: "file",
      },
      {
        id: 78,
        name: "is_fisik_passport",
        title: "Fisik Passport",
        type_field: "boolean",
      },
      {
        id: 79,
        name: "baggage_number",
        title: "Nomor Bagasi",
        type_field: "text",
      },
      {
        id: 80,
        name: "flight_id",
        title: "Nomor Flight",
        type_field: "text",
      },
      {
        id: 81,
        name: "visa_id",
        title: "Nomor Visa",
        type_field: "text",
      },
    ],
  },
  {
    id: 8,
    group: "Financial",
    fields: [
      {
        id: 82,
        name: "additional_discount",
        title: "Diskon Tambahan",
        type_field: "number",
      },
      {
        id: 83,
        name: "additional_discount_remark",
        title: "Catatan Diskon Tambahan",
        type_field: "text",
      },
      {
        id: 84,
        name: "additional_equipment_remark",
        title: "Catatan Perlengkapan Tambahan",
        type_field: "text",
      },
      {
        id: 85,
        name: "additional_equipment_price",
        title: "Harga Perlengkapan Tambahan",
        type_field: "number",
      },
      {
        id: 86,
        name: "additional_addon_remark",
        title: "Catatan Tambahan",
        type_field: "text",
      },
      {
        id: 87,
        name: "additional_addon_price",
        title: "Harga Tambahan",
        type_field: "number",
      },
      {
        id: 88,
        name: "package_price",
        title: "Harga Paket",
        type_field: "number",
      },
      {
        id: 89,
        name: "total_addon_price",
        title: "Total Tambahan",
        type_field: "number",
      },
      {
        id: 90,
        name: "cashback",
        title: "Cashback",
        type_field: "number",
      },
      {
        id: 91,
        name: "total_price",
        title: "Total",
        type_field: "number",
      },
      {
        id: 92,
        name: "total_corporate_bill",
        title: "Total Corporate Bill",
        type_field: "number",
      },
      {
        id: 93,
        name: "total_bill",
        title: "Total Bill",
        type_field: "number",
      },
      {
        id: 94,
        name: "total_paid",
        title: "Total Bayar",
        type_field: "number",
      },
      {
        id: 95,
        name: "remaining_payment",
        title: "Sisa Pembayaran",
        type_field: "number",
      },
      {
        id: 96,
        name: "refunded",
        title: "Refund",
        type_field: "number",
      },
      {
        id: 97,
        name: "refund_remark",
        title: "Catatan Refund",
        type_field: "text",
      },
    ],
  },
];

export const occupationList = [
  {
    id: 1,
    name: "PNS",
    value: "PNS",
  },
  {
    id: 2,
    name: "TNI",
    value: "TNI",
  },
  {
    id: 3,
    name: "POLRI",
    value: "POLRI",
  },
  {
    id: 4,
    name: "Wiraswasta",
    value: "Wiraswasta",
  },
];

export const educationLevel = [
  {
    id: 1,
    name: "SD",
    value: "SD",
  },
  {
    id: 2,
    name: "SMP",
    value: "SMP / Sederajat",
  },
  {
    id: 3,
    name: "SMA",
    value: "SMA / Sederajat",
  },
  {
    id: 4,
    name: "S1",
    value: "S1",
  },
  {
    id: 5,
    name: "S2",
    value: "S2",
  },
  {
    id: 6,
    name: "S3",
    value: "S3",
  },
];

export const statusList = [
  {
    id: 1,
    name: "Menikah",
    value: "Menikah",
  },
  {
    id: 2,
    name: "Belum Menikah",
    value: "Belum Menikah",
  },
];

export const isAlreadyHajj = [
  {
    id: 1,
    name: "Sudah",
    value: "1",
  },
  {
    id: 2,
    name: "Belum",
    value: "0",
  },
];

export const hubunganMahram = [
  {
    id: 1,
    name: "Anak",
    value: "Anak",
  },
  {
    id: 2,
    name: "Suami / Istri",
    value: "Suami / Istri",
  },
  {
    id: 3,
    name: "Saudara",
    value: "Saudara",
  },
];

export const golonganDarah = [
  {
    id: 1,
    name: "A+",
    value: "A+",
  },
  {
    id: 2,
    name: "AB",
    value: "AB",
  },
  {
    id: 3,
    name: "O",
    value: "O",
  },
];

export const statusBPH = [
  {
    id: 1,
    name: "One",
    value: "1",
  },
  {
    id: 2,
    name: "Two",
    value: "2",
  },
  {
    id: 3,
    name: "Three",
    value: "3",
  },
];

export const sizeBajuKoko = [
  {
    id: 1,
    name: "XS",
    value: "XS",
  },
  {
    id: 2,
    name: "S",
    value: "S",
  },
  {
    id: 3,
    name: "M",
    value: "M",
  },
  {
    id: 4,
    name: "L",
    value: "L",
  },
  {
    id: 5,
    name: "XL",
    value: "XL",
  },
  {
    id: 6,
    name: "XXL",
    value: "XXL",
  },
];

export const jenisKelamin = [
  {
    id: 1,
    name: "Laki-Laki",
    value: "L",
  },
  {
    id: 2,
    name: "Perempuan",
    value: "P",
  },
];
