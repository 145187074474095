import { riyuzAPI } from "../constant/api";

export const UmrohClaimServices = {
  postUmrohClaim(payload) {
    return riyuzAPI.post(`umroh-claim-ids`, payload);
  },
  getUmrohClaim(id, param) {
    return id
      ? riyuzAPI.get(`umroh-claim-ids/${id}`)
      : riyuzAPI.get(`umroh-claim-ids${param ? param : ""}`);
  },
  putUmrohClaim(payload) {
    return riyuzAPI.put(`umroh-claim-ids/${payload.id}`, payload.body);
  },
  deleteUmrohClaim(id) {
    return riyuzAPI.delete(`umroh-claim-ids/${id}`);
  },
};
