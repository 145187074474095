import { Link, useNavigate } from "react-router-dom";
import styles from "./Layout.module.scss";
import cartBlack from "./../../assets/Home/cartBlack.svg";
import cart from "./../../assets/Home/cart.svg";
import search from "./../../assets/Home/search2.svg";
import { Image } from "react-bootstrap";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import arrowLeftWhite from "./../../assets/Profile/arrow-left-white.svg";
import { useEffect, useState } from "react";
import logo from "./../../assets/logo.png";

export default function MobileHeader(props) {
  const { type, color } = props;
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);
  const handlePrevious = () => {
    navigate("/");
  };
  return type === "hide" || type === "hide-header" ? (
    <></>
  ) : (
    <div className={styles.headerMobile}>
      <>
        {(type === "cart" || type === "checkout" || type === "search") && (
          <Image
            className="me-3"
            onClick={handlePrevious}
            src={color === "dark" ? arrowLeft : arrowLeftWhite}
          />
        )}

        {/* <div className={styles.headerSearch}>
          <Image src={search} alt="search" width={14} height={14} />
          <input className="ps-5 pe-2" placeholder="Mau cari apa?" />
        </div>
        <div className={styles.headerCart}>
          <Link to={`${isLogin ? "/cart" : "/login"}`}>
            <Image src={color === "dark" ? cartBlack : cart} alt="cart" />
          </Link>
        </div>  */}
        <Image src={logo} alt="logo" width={36} height={36} />
      </>
    </div>
  );
}
