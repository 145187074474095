import { Col, Image, Row } from "react-bootstrap";
import styles from "./Layout.module.scss";
import userMobile from "./../../assets/Home/userMobile.svg";
import historyMobile from "./../../assets/Home/historyMobile.svg";
import homeMobile from "./../../assets/Home/homeMobile.svg";
import userMobileActive from "./../../assets/Home/userMobileActive.svg";
import wishlistMobileActive from "./../../assets/Profile/wishlist.svg";
import wishlistMobile from "./../../assets/Profile/wishlist-outline.svg";
import myTripMobileActive from "./../../assets/Profile/my-trip.svg";
import myTripMobile from "./../../assets/Profile/my-trip-2.svg";
import homeMobileActive from "./../../assets/Home/homeMobileActive.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export default function MobileNav(props) {
  const { type } = props;
  const location = useLocation();
  const currLocation = location.pathname.split("/")[1];
  const [display, setDisplay] = useState();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    let localStorageData = localStorage.getItem("riyuz-auth");
    let parsed = JSON.parse(localStorageData);
    if (parsed?.id) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  const checkout = (
    <div className={`${styles.stickyNav} ${styles.stickyNav__checkout}`}>
      <div className={`${styles.button__addCart} ${styles.button}`}>
        + Keranjang
      </div>
      <div className={`${styles.button__buyPackages} ${styles.button}`}>
        Beli Paket
      </div>
    </div>
  );

  // const selectDate = ();

  const normal = (
    <div className={styles.stickyNav}>
      <Row className="justify-content-between">
        <Col xs={3} className="text-center">
          <Link to="/" className={styles.menuMobile}>
            <Image
              src={currLocation === "" ? homeMobileActive : homeMobile}
              alt="home"
            />
            Home
          </Link>
        </Col>
        <Col xs={3} className="text-center">
          <Link
            to={isLogin ? "/my-trip" : "/login"}
            className={styles.menuMobile}
          >
            <Image
              src={
                currLocation === "my-trip" ? myTripMobileActive : myTripMobile
              }
              // src={historyMobile}
              alt="history"
            />
            My Trip
          </Link>
        </Col>
        <Col xs={3} className="text-center">
          <Link
            to={isLogin ? "/my-wishlist" : "/login"}
            className={styles.menuMobile}
          >
            <Image
              src={
                currLocation === "my-wishlist"
                  ? wishlistMobileActive
                  : wishlistMobile
              }
              alt="history"
            />
            Wishlist
          </Link>
        </Col>
        <Col xs={3} className="text-center">
          {isLogin ? (
            <Link to={`${"/profile"}`} className={styles.menuMobile}>
              <Image
                src={currLocation === "profile" ? userMobileActive : userMobile}
                alt="user"
              />
              Profil
            </Link>
          ) : (
            <Link to={`${"/login"}`} className={styles.menuMobile}>
              <Image
                src={currLocation === "profile" ? userMobileActive : userMobile}
                alt="user"
              />
              Masuk
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );

  const cart = (
    <div className={styles.stickyNav}>
      <div className={styles.totalSection}>
        <div className={styles.text}>Total Harga</div>
        <div className={styles.price}>Rp 7.000.000</div>
      </div>
      <div className={styles.checkoutBtn}>Checkout</div>
    </div>
  );

  // useEffect(() => {
  //   switch (type) {
  //     case "hide":
  //       setDisplay();
  //       break;
  //     case "checkout":
  //       setDisplay(checkout);
  //       break;
  //     case "cart":
  //       setDisplay(cart);
  //       break;
  //     default:
  //       setDisplay(normal);
  //   }
  // }, [type]);

  return type === "hide" ? (
    ""
  ) : type === "checkout" ? (
    <>{checkout}</>
  ) : type === "cart" ? (
    <>{cart}</>
  ) : (
    <>{normal}</>
  );
}
