import styles from "./Pagination.module.scss";
export default function Pagination(props) {
  const { totalPages, handlePagination, currentPage } = props;
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={styles.paginationWrap}>
      <div
        onClick={() => handlePagination(currentPage - 1)}
        className={`${styles.prev} ${
          currentPage === 1 ? styles.prev_disabled : ""
        }`}
      >
        {"<"}
      </div>
      <div className={styles.paginationList}>
        {pageNumbers.map((item) => {
          return (
            <div
              onClick={() => handlePagination(item)}
              className={`${styles.paginationItem} ${
                currentPage === item ? styles.paginationItem_active : ""
              }`}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div
        onClick={() => handlePagination(currentPage + 1)}
        className={`${styles.next} ${
          currentPage === totalPages ? styles.next_disabled : ""
        }`}
      >
        {">"}
      </div>
    </div>
  );
}
