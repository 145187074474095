import { Button } from "react-bootstrap";
import styles from "./../styles/Thankyou.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { BookingServices } from "../http/BookingHttp";
export default function Thankyou() {
  const navigate = useNavigate();
  const { booking_code } = useParams();
  console.log(booking_code);
  useEffect(() => {
    getThankyou(booking_code);
  }, []);
  const getThankyou = async (id) => {
    try {
      const response = await BookingServices.getThankyou(id);
      if (response) {
        console.log("thank you!");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={styles.thankyou_wrap}>
      <div className={styles.thankyou_title}>Pesanan Berhasil Dibuat!</div>
      <div className={styles.thankyou_subtitle}>
        Periksa email anda untuk melihat detail pesanan
      </div>
      <Button className={styles.thankyou_cta} onClick={() => navigate("/")}>
        Kembali ke halaman utama
      </Button>
    </div>
  );
}
