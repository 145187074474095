import { Col } from "react-bootstrap";
import styles from "./../../styles/Cart.module.scss";

export default function SummaryPrice() {
  return (
    <Col lg={4} className={styles.summaryPrice}>
      <div className={styles.summaryBox}>
        <div className={styles.title}>Ringkasan Harga</div>
        <div className={styles.textWrap}>
          <div>Total Harga</div>
          <div>Rp 1.999.000</div>
        </div>
        <div className={styles.textWrap}>
          <div>Total Diskon</div>
          <div>-Rp 999.000</div>
        </div>
        <div className={styles.totalWrap}>
          <div className={styles.heading}>Total Harga</div>
          <div className={styles.total}>-Rp 999.000</div>
        </div>
        <div className={`btnYellow ${styles.btn}`}>Checkout</div>
      </div>
    </Col>
  );
}
