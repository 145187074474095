import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./../../../styles/ProfileMobile.module.scss";

export default function Menu(props) {
  const { isLast, link, text, img, isAvailable } = props;
  return (
    <div className={`${styles.menuMobile} ${isLast ? styles.lastMenu : ""}`}>
      <div
        className={`${isLast ? styles.logoutMobile : ""}`}
        onClick={!isAvailable ? () => {} : link}
      >
        {img && (
          <span className="me-2">
            <Image src={img} alt="img" width={26} height={26} />
          </span>
        )}
        {text}
      </div>
      {!isAvailable && <div className={styles.available}>Coming Soon</div>}
    </div>
  );
}
