import Footer from "./Footer";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import MobileNav from "./MobileNav";
import { isMobileCheck } from "../../utils";
import Loading from "../common/Loading";
import { useEffect, useState } from "react";
import { AuthServices } from "../../http/AuthHttp";
import { useNavigate } from "react-router-dom";

export default function WebLayout({
  children,
  type,
  color,
  isLoading,
  showNav = true,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && <Loading />}
      <MobileHeader type={type} color={color} />
      <Header color={color} isLoading={isLoading} />
      {children}
      {isMobileCheck() ? "" : <Footer />}
      {showNav && <MobileNav type={type} />}
    </>
  );
}
