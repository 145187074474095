export const API_URL = "";

export const iconArrive =
  "https://cdn-icons-png.flaticon.com/512/716/716570.png";
export const iconDeparture =
  "https://cdn-icons-png.flaticon.com/512/8213/8213438.png";

export const iconBus = "https://cdn-icons-png.flaticon.com/512/635/635705.png";

export const iconTrain =
  "https://cdn-icons-png.flaticon.com/512/565/565410.png";
