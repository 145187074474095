import { Button, Image, Modal } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthServices } from "../http/AuthHttp";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [isReset, setIsReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [data, setData] = useState({
    value: "",
    type_login: "email",
  });
  const [isSentConfirmation, setSentConfirmation] = useState(false);
  const [textResponse, setTextResponse] = useState();
  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      navigate("/");
    }
  }, []);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const contentModal = (
    <div className="w-100 text-center">
      <h3 className="mb-2">
        {textResponse === "reset_token_sent"
          ? "Kode OTP Sukses Dikirimkan"
          : "Kode OTP Gagal Dikirimkan"}
      </h3>
      <div className="mb-5">
        {textResponse === "reset_token_sent"
          ? "Cek email anda untuk melihat Kode OTP"
          : "Silahkan coba lagi"}
      </div>

      <div>
        {textResponse === "reset_token_sent" ? (
          <Button variant="primary" onClick={() => navigate(`/reset-password?email=${data.value}`)}>
            Reset Password
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setModalResponse(false)}>
            Coba Lagi
          </Button>
        )}
      </div>
    </div>
  );

  const handleForgotPassword = async (e) => {
    // console.log(data);
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await AuthServices.forgotPassword(data);
      if (response) {
        setIsLoading(false);
        setModalResponse(true);

        setTextResponse(response.code);
        // setIsReset(true);
      }
    } catch (err) {
      console.log(err);
      setTextResponse("fail");
      setModalResponse(true);
      setIsLoading(false);
      setIsReset(false);
    }
    // setSentConfirmation(true);
    // console.log(data);
  };
  return (
    <AuthLayout isLoading={isLoading}>
      <div className={styles.circle}></div>
      <div className={styles.form}>
        <div className={styles.title}>Lupa Password</div>
        <div className={styles.text}>
          Masukan email kamu dan kami akan kirimkan link untuk mengganti
          password.
        </div>
        {isSentConfirmation ? (
          <form onSubmit={handleForgotPassword}>
            <div className={styles.inputGroup}>
              <label className={styles.text}>OTP</label>

              <div className={styles.inputWrap}>
                <Image src={email} alt="email" />
                <input
                  onChange={handleChange}
                  id="value"
                  name="value"
                  className={styles.inputBox}
                />
              </div>
              <div
                onClick={() => setSentConfirmation(false)}
                className={styles.hook}
              >
                Kirim Ulang OTP
              </div>
              <Button variant="primary" type="submit">
                Verifikasi
              </Button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleForgotPassword}>
            <div className={styles.inputGroup}>
              <label className={styles.text}>Email</label>

              <div className={styles.inputWrap}>
                <Image src={email} alt="email" />
                <input
                  onChange={handleChange}
                  className={styles.inputBox}
                  id="value"
                  name="value"
                  type="email"
                  placeholder="user@email.com"
                />
              </div>
              <a className={styles.hook} href="/login">
                Kembali ke Login
              </a>
              {isReset ? (
                <a href="/reset-password" className="btn btn-primary">
                  Reset Password
                </a>
              ) : (
                <Button variant="primary" type="submit">
                  Kirim
                </Button>
              )}
            </div>
          </form>
        )}
      </div>
      <Modal
        centered
        show={modalResponse}
        onHide={() => setModalResponse(false)}
      >
        <Modal.Body className="p-5">{contentModal}</Modal.Body>
      </Modal>
    </AuthLayout>
  );
}
