import { Image } from "react-bootstrap";
import styles from "./../../styles/DetailProduct.module.scss";
import location from "./../../assets/location.svg";

export default function DepartureLocation(props) {
  const { detail } = props;
  return (
    <div className={styles.contentBox}>
      <div className={styles.heading}>Lokasi Keberangkatan</div>
      <div className={styles.location}>
        <Image src={location} />
        {detail.location ? detail.location?.name : "-"}
      </div>
    </div>
  );
}
