import { Button, Image, Modal } from "react-bootstrap";
import styles from "./../styles/Login.module.scss";
import email from "./../assets/Auth/email.svg";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthServices } from "../http/AuthHttp";

export default function ResetPassword() {
  const navigate = useNavigate();
  const history = useLocation();
  const currEmail = history.search.split("=");
  console.log(currEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    value: "",
    type_login: "email",
    new_password: "",
    reset_token: "",
  });
  const [resendOTP, setResendOTP] = useState(false);
  const [textResponse, setTextResponse] = useState();
  const [resetPassword, setResetPassword] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      navigate("/");
    }
  }, []);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await AuthServices.resetPassword(data);
      if (response) {
        setIsLoading(false);
        setResetPassword(true);
        setTextResponse(response.code);
        console.log(response);
      }
    } catch (err) {
      setIsLoading(false);
      setResetPassword(true);
      setTextResponse("fail");
      console.log(err);
    }
  };

  const contentModalReset = (
    <div className="w-100 text-center">
      <h3 className="mb-2">
        {textResponse !== "invalid_reset_token"
          ? "Berhasil Reset Password"
          : "Gagal Reset Password"}
      </h3>
      <div className="mb-5">
        {textResponse !== "invalid_reset_token"
          ? "Silahkan login kembali"
          : "Mohon coba lagi"}
      </div>

      <div>
        {textResponse !== "invalid_reset_token" ? (
          <Button variant="primary" onClick={() => navigate("/login")}>
            Login
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setResetPassword(false)}>
            Coba Lagi
          </Button>
        )}
      </div>
    </div>
  );

  const contentModal = (
    <div className="w-100 text-center">
      <h3 className="mb-2">
        {textResponse === "reset_token_sent"
          ? "Kode OTP Sukses Dikirimkan"
          : "Kode OTP Gagal Dikirimkan"}
      </h3>
      <div className="mb-5">
        {textResponse === "reset_token_sent"
          ? "Cek email anda untuk melihat Kode OTP"
          : "Silahkan coba lagi"}
      </div>

      <div>
        {textResponse === "reset_token_sent" ? (
          <Button variant="primary" onClick={() => setResendOTP(false)}>
            Tutup
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setResendOTP(false)}>
            Coba Lagi
          </Button>
        )}
      </div>
    </div>
  );

  const handleForgotPassword = async (e) => {
    // console.log(data);
    setIsLoading(true);
    let payload = {
      value: currEmail[1],
      type_login: "email",
    };
    e.preventDefault();
    try {
      const response = await AuthServices.forgotPassword(payload);
      if (response) {
        setIsLoading(false);
        setResendOTP(true);
        setTextResponse(response.code);
        // setTextResponse(response.code);
        // setIsReset(true);
      }
    } catch (err) {
      setTextResponse("fail");
      setResendOTP(true);
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout isLoading={isLoading}>
      <div className={styles.circle}></div>
      <div className={styles.form}>
        <div className={styles.title}>Reset Password</div>
        <div className={styles.text}>
          Masukkan data data dibawah untuk mereset password anda
        </div>
        <form onSubmit={handleResetPassword}>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Email</label>
            <div className={styles.inputWrap}>
              <Image src={email} alt="email" />
              <input
                onChange={handleChange}
                className={styles.inputBox}
                id="value"
                name="value"
                placeholder="Masukan Email"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Password Baru</label>
            <div className={styles.inputWrap}>
              <Image src={email} alt="email" />
              <input
                onChange={handleChange}
                className={styles.inputBox}
                id="new_password"
                name="new_password"
                placeholder="Masukan Password Baru"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>OTP</label>

            <div className={styles.inputWrap}>
              <Image src={email} alt="email" />
              <input
                onChange={handleChange}
                id="reset_token"
                name="reset_token"
                placeholder="Masukan OTP"
                className={styles.inputBox}
              />
            </div>
            <div onClick={handleForgotPassword} className={styles.hook}>
              Kirim Ulang OTP
            </div>
            <Button variant="primary" type="submit">
              Reset Password
            </Button>
            <div className="text-center mt-3">
            <a href="/">Kembali ke halaman depan</a>
            </div>
          </div>
        </form>
      </div>
      <Modal centered show={resendOTP} onHide={() => setResendOTP(false)}>
        <Modal.Body className="p-5">{contentModal}</Modal.Body>
      </Modal>
      <Modal
        centered
        show={resetPassword}
        onHide={() => setResetPassword(false)}
      >
        <Modal.Body className="p-5">{contentModalReset}</Modal.Body>
      </Modal>
    </AuthLayout>
  );
}
