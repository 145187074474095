import WebLayout from "../components/Layout/WebLayout";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { UserServices } from "../http/UserHttp";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import Wishlist from "../components/Profile/Wishlist";
export default function MyWishlist() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const getWishlistData = async () => {
    setIsLoading(true);
    try {
      const response = await UserServices.getWishlist();
      if (response) {
        setIsLoading(false);
        setDataProfile(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getWishlistData();
  }, []);

  return (
    <WebLayout>
      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="mb-4 mb-md-0">Wishlist Saya</h1>

            {dataProfile?.length > 0 ? (
              <Wishlist isMobile={true} data={dataProfile} />
            ) : (
              <>
                {Array.from({ length: 2 }).map((_, idx) => (
                  <div key={idx}>
                    <Skeleton height={120} style={{ marginBottom: 16 }} />
                    <Skeleton height={35} style={{ marginBottom: 24 }} />
                  </div>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </WebLayout>
  );
}
