import { Modal, Form, Card, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Search.module.scss";
import { Link } from "react-router-dom";

export default function LiveSearch(props) {
  const { show, setHide } = props;
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (show) {
      getLatestUmroh();
    }
  }, [show]);

  useEffect(() => {
    if (value) {
      try {
        setLoading(true);
        setResult([]);
        const getData = setTimeout(() => {
          axios
            .get(`https://api.riyuz.com/api/tours?search=${value}&limit=5`)
            .then((response) => {
              setResult(response.data.data.data);
            });
          setLoading(false);
        }, 2000);
        return () => clearTimeout(getData);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }, [value]);

  const getLatestUmroh = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.riyuz.com/api/tours?limit=5"
      );
      if (response) {
        setLoading(false);
        setResult(response.data.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={setHide}
      centered
      className={styles.modalWrap}
    >
      <Modal.Body className="p-4">
        <Form.Control
          value={value}
          // onChange={onChangeHandler}
          onChange={(event) => setValue(event.target.value)}
          placeholder="Cari paket tour...."
          className="mb-4"
        />
        <div className={styles.modalContent}>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : result?.length > 0 ? (
            result.map((item) => {
              return (
                <Link to={`/detail/${item.slug}/${item.id}`}>
                  <Card className={`${styles.result} mb-2`}>
                    <Card.Body>{item.title}</Card.Body>
                  </Card>
                </Link>
              );
            })
          ) : (
            <div>Belum Ada Data</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
