import styles from "./../../styles/DetailProduct.module.scss";

export default function Description(props) {
  const { detail } = props;
  return (
    <div className={styles.contentBox}>
      <div className={styles.heading}>Deskripsi Paket</div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: detail.content }}
      />
    </div>
  );
}
